import { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { getMediaType } from 'utils/helper';
import call from 'utils/request';
import Swal from 'sweetalert2';
import DropFile from 'jsx/components/CustomComponents/DropFile';

function ImageUpload() {
  const history = useHistory();
  const location = useLocation();
  const pathName = location?.pathname?.split('/');
  const [selectedFile, setSelectedFile] = useState([]);
  const [propertyMedia, setPropertyMedia] = useState([]);
  const [roomMedia, setRoomMedia] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const propertyId = location?.state ? location?.state?.id : pathName[2];
  const roomId = location?.state ? location?.state?.roomId : pathName[4];

  const imageSpanStyle = {
    position: 'relative',
    left: '120px',
    top: '10px',
    // height: '10px',
    // width: '10px',
    cursor: 'pointer',
  };
  const {
    handleSubmit,
    // setError,
    reset,
    formState: { isSubmitting },
  } = useForm();

  const getPropertyData = useCallback(async () => {
    await call({
      method: 'get',
      url: `/api/properties/${propertyId}`,
    }).then((res) => {
      setPropertyMedia(res?.data?.media);
    });
  }, [propertyId]);

  const getRoomData = useCallback(async () => {
    await call({
      method: 'get',
      url: `/api/rooms/${roomId}`,
    }).then((res) => {
      const data = res?.data?.media.map((item) => {
        getMediaType(item);
        return item;
      });
      setRoomMedia(data);
    });
  }, [roomId]);

  useEffect(() => {
    if (propertyId && pathName[3] !== 'rooms') {
      getPropertyData();
    }
    if (roomId) {
      getRoomData();
    }
  }, [propertyId, roomId, getPropertyData, getRoomData]);

  const onSubmit = async () => {
    const mediaLength = Object.keys(selectedFile)?.length;
    const formData = new FormData();
    let i;
    for (i = 0; i < mediaLength; i++) {
      formData.append(`media[${i}]`, selectedFile[i]);
    }
    setSubmitting(true);
    if (!roomId) {
      await call({
        method: 'post',
        url: `/api/properties/media/${propertyId}`,
        // url: `/api/properties/media/64bbdffcf445f8002d08b720`,
        data: formData,
      }).then((res) => {
        if (res.status === 201) {
          Swal.fire({
            title: 'Uploaded!',
            text: res?.data?.message || 'Images has been uploaded',
            icon: 'success',
            showConfirmButton: false,
            timer: 500,
          });
          reset();
          setSubmitting(false);
          history.push(`/properties/${propertyId}/rooms`, { id: propertyId });
        }
      });
    } else {
      await call({
        method: 'post',
        url: `/api/rooms/media/${propertyId}/${roomId}`,
        // url: `/api/properties/media/64bbdffcf445f8002d08b720`,
        data: formData,
      }).then((res) => {
        if (res.status === 201) {
          Swal.fire({
            title: 'Uploaded!',
            text: res?.data?.message || 'Images has been uploaded',
            icon: 'success',
            showConfirmButton: false,
            timer: 500,
          });
          reset();
          setSubmitting(false);
          history.push(`/properties/${propertyId}/rooms`, { id: propertyId });
        }
      });
    }
  };

  const onDeleteMedia = async (index) => {
    await Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete the media?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#F8857D',
      dangerMode: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (propertyMedia?.length) {
          await call({
            method: 'delete',
            url: `/api/properties/media/${propertyId}/${index}`,
          }).then((res) => {
            if (res?.status === 204) {
              Swal.fire('Deleted', 'Media has been deleted!', 'success');
              setTimeout(() => getPropertyData(), 500);
            }
          });
        } else if (roomMedia?.length) {
          await call({
            method: 'delete',
            url: `/api/rooms/media/${roomId}/${index}`,
          }).then((res) => {
            if (res?.status === 204) {
              Swal.fire('Deleted', 'Media has been deleted!', 'success');
              setTimeout(() => getRoomData(), 500);
            }
          });
        }
      }
    });
  };

  return (
    <div className="row">
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-body">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <h3 className="mb-3">Add photos to enrich user experience!</h3>
              <div className="mb-4">
                <DropFile
                  setSelectedFile={setSelectedFile}
                  isSubmitting={submitting}
                />
              </div>
              <div className="mb-4">
                <p>Preview</p>
                {propertyMedia?.length ? (
                  <div className="d-grid img-preview">
                    {propertyMedia?.map((item, index) => {
                      return (
                        <div
                          key={`property-item-${index}`}
                          className="me-3"
                          style={{ height: '150px', width: '150px' }}
                        >
                          <span
                            style={imageSpanStyle}
                            onClick={() => onDeleteMedia(index)}
                          >
                            <i className="fas fa-circle-xmark fa-2x"></i>
                          </span>
                          {getMediaType(item) === 'mp4' ? (
                            <video
                              width="130px"
                              height="130px"
                              style={{
                                objectFit: 'cover',
                                borderRadius: '10px',
                              }}
                              controls
                              alt="room-video"
                            >
                              <source src={item} type="video/mp4" />
                            </video>
                          ) : (
                            <img
                              src={item}
                              height="130px"
                              width="130px"
                              alt="property-img"
                              style={{ borderRadius: '10px' }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ''
                )}
                {roomMedia?.length ? (
                  <div className="d-grid img-preview">
                    {roomMedia?.map((item, index) => {
                      return (
                        <div
                          key={`property-item-${index}`}
                          className="me-3"
                          style={{ height: '150px', width: '150px' }}
                        >
                          <span
                            style={imageSpanStyle}
                            onClick={() => onDeleteMedia(index)}
                          >
                            <i className="fas fa-circle-xmark fa-2x"></i>
                          </span>
                          {getMediaType(item) === 'mp4' ? (
                            <video
                              width="130px"
                              height="130px"
                              style={{
                                objectFit: 'cover',
                                borderRadius: '10px',
                              }}
                              controls
                              alt="room-video"
                            >
                              <source src={item} type="video/mp4" />
                            </video>
                          ) : (
                            <img
                              src={item}
                              height="130px"
                              width="130px"
                              alt="room-img"
                              style={{ borderRadius: '10px' }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="column d-flex justify-content-between">
                <Button
                  type="button"
                  className="btn px-4"
                  variant="light"
                  onClick={() =>
                    roomId
                      ? history.push(
                          `/properties/${propertyId}/rooms/${roomId}/edit`,
                          { id: propertyId, roomId: roomId }
                        )
                      : history.push(`/properties/${propertyId}/edit`, {
                          id: propertyId,
                        })
                  }
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary px-4"
                  disabled={isSubmitting}
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageUpload;
