import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import call from "utils/request";
import { Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";

const RoleAdd = () => {
  const history = useHistory();
  const location = useLocation();
  const state = location?.state;
  const userInfo = JSON.parse(sessionStorage.getItem("userDetails"));
  const permissions = userInfo?.permissions;
  const modules = useRef([
    { moduleName: "PROPERTY" },
    { moduleName: "ROOM" },
    { moduleName: "TENANT" },
    { moduleName: "COUPON" },
    { moduleName: "PAYMENT" },
    { moduleName: "REPORT" },
    { moduleName: "ROLE" },
    { moduleName: "USER" },
  ]);
  const [accesses, setAccesses] = useState([
    {
      moduleName: "",
      create: false,
      read: true,
      update: false,
      delete: false,
    },
  ]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(() => {
    if (state) {
      setValue("role", state?.role);
      setValue("access", state?.access);
      setAccesses(state?.access);
    }
  }, [state, setValue, setAccesses]);

  const onSubmit = async (data) => {
    if (state) {
      await call(
        {
          method: "patch",
          url: `/api/roles/${state?._id}`,
          data: data,
        },
        {
          Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
        }
      )
        .then((res) => {
          if (res?.status === 204) {
            Swal.fire({
              title: "Updated!",
              text: res?.data?.message || "Role has been updated successfully",
              icon: "success",
              showConfirmButton: false,
              timer: 500,
            });
            history.push(`/roles`);
          }
        })
        .catch((err) => {
          const errMsg = err?.response?.data?.message;
          console.log({ errMsg });
        });
    } else {
      await call(
        {
          method: "post",
          url: "/api/roles",
          data: data,
        },
        {
          Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
        }
      )
        .then((res) => {
          if (res?.status === 201) {
            Swal.fire({
              title: "Created!",
              text: res?.data?.message || "Role has been created successfully",
              icon: "success",
              showConfirmButton: false,
              timer: 500,
            });
            history.push(`/roles`);
          }
        })
        .catch((err) => {
          const errMsg = err?.response?.data?.message;
          console.log({ errMsg });
        });
    }
  };

  const handleModuleNameChange = (e, index) => {
    const { value } = e.target;
    const updatedAccesses = [...accesses];
    updatedAccesses[index].moduleName = value;
    setAccesses(updatedAccesses);
  };

  const handleDeleteModule = (index) => {
    const updatedAccesses = [...accesses];
    updatedAccesses.splice(index, 1);
    setAccesses(updatedAccesses);
  };

  return (
    <>
      <div>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label>
                        Role Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("role", {
                          required: "Role Name is required",
                        })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <h4>Module and Permissions</h4>
                    {accesses.map((access, index) => {
                      return (
                        <>
                          {state?.view ? (
                            <>
                              <div className="form-group mb-3 col-md-6">
                                <div className="d-flex justify-content-between">
                                  <label>
                                    Module{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={access.moduleName}
                                  disabled
                                />
                                <div className="d-flex flex-row mt-3 justify-content-around">
                                  <div className="d-flex flex-row mb-3">
                                    <label className="m-0 p-0">Create:</label>
                                    <input
                                      type="checkbox"
                                      {...register(`access[${index}].create`)}
                                      className="form-check-input m-0 p-0 ms-2"
                                      defaultChecked={access.create}
                                      disabled
                                    />
                                  </div>{" "}
                                  <div className="d-flex flex-row mb-3">
                                    <label className="m-0 p-0">Read:</label>
                                    <input
                                      type="checkbox"
                                      {...register(`access[${index}].read`)}
                                      className="form-check-input m-0 p-0 ms-2"
                                      defaultChecked={access.read}
                                      disabled
                                    />
                                  </div>{" "}
                                  <div className="d-flex flex-row mb-3">
                                    <label className="m-0 p-0">Update:</label>
                                    <input
                                      type="checkbox"
                                      {...register(`access[${index}].update`)}
                                      className="form-check-input m-0 p-0 ms-2"
                                      defaultChecked={access.update}
                                      disabled
                                    />
                                  </div>{" "}
                                  <div className="d-flex flex-row mb-3">
                                    <label className="m-0 p-0">Delete:</label>
                                    <input
                                      type="checkbox"
                                      {...register(`access[${index}].delete`)}
                                      className="form-check-input m-0 p-0 ms-2"
                                      defaultChecked={access.delete}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                key={index}
                                className="form-group mb-3 col-md-6"
                              >
                                <div className="d-flex justify-content-between">
                                  <label>
                                    Module{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  {permissions?.ROLE?.update && (
                                    <label
                                      type="button"
                                      className="btn text-danger fw-bolder m-0 p-0 text-end"
                                      onClick={() => handleDeleteModule(index)}
                                    >
                                      × Delete Module
                                    </label>
                                  )}
                                </div>
                                <select
                                  id={`access[${index}].moduleName`}
                                  className="form-control"
                                  {...register(`access[${index}].moduleName`, {
                                    required: "Module is required",
                                  })}
                                  value={access.moduleName}
                                  onChange={(e) =>
                                    handleModuleNameChange(e, index)
                                  }
                                >
                                  <option value="" disabled>
                                    Select Module
                                  </option>
                                  {modules.current?.map((item, i) => (
                                    <option key={`role-${i}`} value={item._id}>
                                      {item?.moduleName}
                                    </option>
                                  ))}
                                </select>
                                {errors?.accesses &&
                                  errors.accesses[index]?.moduleName && (
                                    <Form.Text className="text-danger">
                                      {
                                        errors.accesses[index].moduleName
                                          .message
                                      }
                                    </Form.Text>
                                  )}
                                <div className="d-flex flex-row mt-3 justify-content-around">
                                  <div className="d-flex flex-row mb-3">
                                    <label className="m-0 p-0">Create:</label>
                                    <input
                                      type="checkbox"
                                      {...register(`access[${index}].create`)}
                                      className="form-check-input m-0 p-0 ms-2"
                                      defaultChecked={access.create}
                                    />
                                  </div>{" "}
                                  <div className="d-flex flex-row mb-3">
                                    <label className="m-0 p-0">Read:</label>
                                    <input
                                      type="checkbox"
                                      {...register(`access[${index}].read`)}
                                      className="form-check-input m-0 p-0 ms-2"
                                      defaultChecked={access.read}
                                    />
                                  </div>{" "}
                                  <div className="d-flex flex-row mb-3">
                                    <label className="m-0 p-0">Update:</label>
                                    <input
                                      type="checkbox"
                                      {...register(`access[${index}].update`)}
                                      className="form-check-input m-0 p-0 ms-2"
                                      defaultChecked={access.update}
                                    />
                                  </div>{" "}
                                  <div className="d-flex flex-row mb-3">
                                    <label className="m-0 p-0">Delete:</label>
                                    <input
                                      type="checkbox"
                                      {...register(`access[${index}].delete`)}
                                      className="form-check-input m-0 p-0 ms-2"
                                      defaultChecked={access.delete}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      );
                    })}
                    <div className="row mx-0">
                      <div className="form-group mb-3 col-md-12 d-flex justify-content-end mx-0 px-0">
                        {!state?.view &&
                          permissions?.ROLE?.update &&
                          accesses.length !== modules.current.length && (
                            <label
                              type="button"
                              className="btn text-primary fw-bolder m-0 p-0 text-end"
                              onClick={() => {
                                setAccesses([
                                  ...accesses,
                                  {
                                    create: false,
                                    read: true,
                                    update: false,
                                    delete: false,
                                    moduleName: "",
                                  },
                                ]);
                              }}
                            >
                              + Add Module
                            </label>
                          )}
                      </div>
                    </div>
                    <div className="column d-flex justify-content-between">
                      <Button
                        type="button"
                        className="btn px-4"
                        variant="light"
                        onClick={() => history.goBack()}
                      >
                        Cancel
                      </Button>
                      {permissions?.ROLE?.update && (
                        <Button
                          type="submit"
                          className="btn btn-primary px-4"
                          disabled={state?.view || isSubmitting}
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                    {state?.view && (
                      <span className="text-end mt-2">
                        Submit Operation is disabled for Admin Role
                      </span>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleAdd;
