import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import {Tab, Nav, Dropdown} from 'react-bootstrap';

//Images
import imag1 from './../../../images/review-img/pic-1.jpg';
import imag2 from './../../../images/review-img/pic-2.jpg';
import imag3 from './../../../images/review-img/pic-3.jpg';
import imag4 from './../../../images/review-img/pic-4.jpg';

const ReviewData = [
	{image:imag1, title:'Zain Torff', detail:'I love that room service', rating:'4.1'},
	{image:imag2, title:'Adison Kenter', detail:'Bad Room Ever', rating:'2.5' },
	{image:imag3, title:'Emery Dias', detail:'Best Hotel In London! Recomended', rating:'3.4'},
	{image:imag4, title:'Livia Korsgaard', detail:'You Get Great View at the Top of Buildings', rating:'4.9'},
];
const ReviewData2 = [
	{image:imag3, title:'Emery Dias', detail:'Best Hotel In London! Recomended', rating:'3.4'},
	{image:imag4, title:'Livia Korsgaard', detail:'You Get Great View at the Top of Buildings', rating:'4.9'},
];
const ReviewData3 = [
	{image:imag2, title:'Adison Kenter', detail:'Bad Room Ever', rating:'2.5' },
	{image:imag1, title:'Zain Torff', detail:'I love that room service', rating:'4.1'},
];

const Reviews = () =>{
	const [selectBtn2, setSelectBtn2] = useState('All Status');
	return(
		<>
			<div className="row">
				<Tab.Container defaultActiveKey="All">
					<div className="mt-3 mt-sm-0 d-inline-block d-sm-flex align-items-center justify-content-between mb-5">
						<div className="coin-tabs">
							<Nav as="ul" className="nav nav-tabs">
								<Nav.Item as="li" className="nav-item" role="presentation">
									<Nav.Link as="button" eventKey="All">All Status</Nav.Link>
								</Nav.Item>
								<Nav.Item as="li">
									<Nav.Link as="button" eventKey="Publish">Published</Nav.Link>
								</Nav.Item>
								<Nav.Item as="li">
									<Nav.Link as="button" eventKey="Archived">Archived</Nav.Link>
								</Nav.Item>
							</Nav>
						</div>
						<div className="form-head d-flex align-items-center mt-2 mt-sm-0">
							<Dropdown className="default-dropdown">
							 <Dropdown.Toggle as="div" className="btn i-false default-Select-btn me-3 ms-0">{selectBtn2}<i className="fa-solid fa-angle-down"></i></Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={()=>setSelectBtn2('All Status')} eventKey="All">All Status</Dropdown.Item>
									<Dropdown.Item onClick={()=>setSelectBtn2('Published')} eventKey="Publish">Published</Dropdown.Item>
									<Dropdown.Item onClick={()=>setSelectBtn2('Archived')} eventKey="Archived">Archived</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
					<Tab.Content>
						<Tab.Pane eventKey="All">
							{ReviewData.map((data, ind)=>(
								<div className="card" key={ind}>
									<div className="card-body">
										<div className="row">
											<div className="col-xl-3 col-lg-3">
												<div className="review-bx d-flex align-items-center">
													<div className="review-img">
														<img src={data.image} alt="123" />
													</div>
													<div className="review-content">
														<h2 className="font-w500">{data.title}</h2>
														<p className="font-w400 m-0">#EMP-00025</p>
													</div>
												</div>
												<p className="review-date font-w400">Tuesday, January 24th 2021 at 2:56 AM</p>
											</div>
											<div className="col-xl-9 col-lg-9">
												<div className="description-bx d-inline-block d-sm-flex align-items-center">
													<div className="description-content">
														<h3 className="font-w500">{data.detail}</h3>
														<p className=" sub-title font-w400 lh-2">
															We were totally refreshed and rejuvenated for the whole of next year and it was due to the relaxing stay at the hotel. The hotel is absolutely marvelous! We liked absolutely everything, starting from the breakfast through to the perfect room service including the cleanliness and extra services such as dry cleaning and laundry. In general all the staff at the hotel were professional, friendly and provided excellent service. We will return for sure
														</p>
														<div className="d-inline-block d-xl-flex align-items-center justify-content-between">
															<div className="badge-bx">
																<span className="btn btn-dark light border-0 btn-sm me-1">Great Service</span>
																<span className="btn btn-dark light border-0 btn-sm me-1">Recomended</span>
																<span className="btn btn-dark light border-0 btn-sm me-1">Best Price</span>
															</div>
															{ind%2 === 0 ? 
																<div className="button-bx mt-2 mt-xl-0">
																	<button className="btn btn-success">Accept
																		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M10 0C4.50742 0 0 4.50742 0 10C0 15.4926 4.50742 20 10 20C15.4926 20 20 15.4926 20 10C20 4.50742 15.4926 0 10 0ZM8.7898 14.5484L4.4107 10.1694L6.06781 8.51227L8.86648 11.3109L14.485 6.20344L16.062 7.93723L8.7898 14.5484Z" fill="white"/>
																		</svg>
																	</button>
																	<button className="btn btn-primary ms-sm-3 ms-1">Reject
																		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M10 0C4.50742 0 0 4.50742 0 10C0 15.4926 4.50742 20 10 20C15.4926 20 20 15.4926 20 10C20 4.50742 15.4926 0 10 0ZM14.9719 13.3148L13.3148 14.9719L10 11.6571L6.68523 14.9719L5.02812 13.3148L8.34289 10L5.02812 6.68523L6.68523 5.02812L10 8.34289L13.3148 5.02812L14.9719 6.68523L11.6571 10L14.9719 13.3148Z" fill="white"/>
																		</svg>
																	</button>
																</div>
															:	
																<div className="button-bx mt-2 mt-xl-0">
																	<button className="btn btn-success light">Published
																		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M10 0C4.50742 0 0 4.50742 0 10C0 15.4926 4.50742 20 10 20C15.4926 20 20 15.4926 20 10C20 4.50742 15.4926 0 10 0ZM8.7898 14.5484L4.4107 10.1694L6.06781 8.51227L8.86648 11.3109L14.485 6.20344L16.062 7.93723L8.7898 14.5484Z" fill="#16B455"/>
																		</svg>
																	</button>
																	<button className="btn btn-outline-light ms-sm-3 ms-1">Archive</button>
																</div>
															}
														</div>
													</div>
													<div className="rating-bx">
														<div className="rating-content d-flex d-sm-block mt-2 mt-sm-0 d-flex d-sm-block mt-2 mt-sm-0">
															<h2 className="font-w600">{data.rating}</h2>
															<ul className="d-flex">
																{ReviewData.map((data,ind)=>(
																	<li key={ind}>
																		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M10.4423 1.09747L13.1198 6.52747C13.1549 6.59864 13.2066 6.66032 13.2706 6.70731C13.3345 6.75431 13.4089 6.78523 13.4873 6.79747L19.4873 7.66747C19.5753 7.68403 19.6573 7.72418 19.7243 7.78364C19.7913 7.8431 19.841 7.91964 19.8679 8.00509C19.8949 8.09055 19.8981 8.18172 19.8773 8.26887C19.8565 8.35603 19.8124 8.4359 19.7498 8.49997L15.4148 12.7225C15.3578 12.7782 15.315 12.8468 15.2902 12.9225C15.2654 12.9982 15.2593 13.0789 15.2723 13.1575L16.2923 19.1575C16.3118 19.2526 16.3029 19.3514 16.2667 19.4416C16.2304 19.5317 16.1685 19.6092 16.0885 19.6644C16.0085 19.7195 15.9141 19.75 15.817 19.7519C15.7199 19.7537 15.6243 19.727 15.5423 19.675L10.1873 16.855C10.1167 16.8182 10.0382 16.799 9.95854 16.799C9.87889 16.799 9.80042 16.8182 9.72979 16.855L4.41229 19.6525C4.33025 19.7045 4.23471 19.7312 4.13758 19.7294C4.04045 19.7275 3.94603 19.697 3.86607 19.6419C3.78611 19.5867 3.72415 19.5092 3.68791 19.4191C3.65168 19.3289 3.64276 19.2301 3.66229 19.135L4.68229 13.135C4.6953 13.0564 4.68915 12.9757 4.66434 12.9C4.63953 12.8243 4.5968 12.7557 4.53979 12.7L0.249786 8.49997C0.184967 8.43563 0.139215 8.35461 0.11759 8.26587C0.0959658 8.17714 0.0993131 8.08415 0.127262 7.9972C0.15521 7.91025 0.20667 7.83273 0.275947 7.77321C0.345224 7.71369 0.429616 7.6745 0.519786 7.65998L6.51978 6.78998C6.5982 6.77773 6.67252 6.74681 6.73648 6.69982C6.80044 6.65283 6.85216 6.59114 6.88729 6.51997L9.56479 1.08997C9.60734 1.01017 9.67094 0.943542 9.74869 0.897337C9.82644 0.851131 9.91537 0.827111 10.0058 0.827884C10.0962 0.828657 10.1847 0.854194 10.2617 0.901721C10.3386 0.949249 10.4011 1.01695 10.4423 1.09747Z" fill="#F66F4D"/>
																		</svg>
																	</li>
																))}
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
						</Tab.Pane>
						<Tab.Pane eventKey="Publish">
							{ReviewData2.map((data, ind)=>(
								<div className="card" key={ind}>
									<div className="card-body">
										<div className="row">
											<div className="col-xl-3 col-lg-3">
												<div className="review-bx d-flex align-items-center">
													<div className="review-img">
														<img src={data.image} alt="123" />
													</div>
													<div className="review-content">
														<h2 className="font-w500">{data.title}</h2>
														<p className="font-w400 m-0">#EMP-00025</p>
													</div>
												</div>
												<p className="review-date font-w400">Tuesday, January 24th 2021 at 2:56 AM</p>
											</div>
											<div className="col-xl-9 col-lg-9">
												<div className="description-bx d-inline-block d-sm-flex align-items-center">
													<div className="description-content">
														<h3 className="font-w500">{data.detail}</h3>
														<p className=" sub-title font-w400 lh-2">
															We were totally refreshed and rejuvenated for the whole of next year and it was due to the relaxing stay at the hotel. The hotel is absolutely marvelous! We liked absolutely everything, starting from the breakfast through to the perfect room service including the cleanliness and extra services such as dry cleaning and laundry. In general all the staff at the hotel were professional, friendly and provided excellent service. We will return for sure
														</p>
														<div className="d-inline-block d-xl-flex align-items-center justify-content-between">
															<div className="badge-bx">
																<span className="btn btn-dark light border-0 btn-sm me-1">Great Service</span>
																<span className="btn btn-dark light border-0 btn-sm me-1">Recomended</span>
																<span className="btn btn-dark light border-0 btn-sm me-1">Best Price</span>
															</div>
															<div className="button-bx mt-2 mt-xl-0">
																<button className="btn btn-success light">Published
																	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M10 0C4.50742 0 0 4.50742 0 10C0 15.4926 4.50742 20 10 20C15.4926 20 20 15.4926 20 10C20 4.50742 15.4926 0 10 0ZM8.7898 14.5484L4.4107 10.1694L6.06781 8.51227L8.86648 11.3109L14.485 6.20344L16.062 7.93723L8.7898 14.5484Z" fill="#16B455"/>
																	</svg>
																</button>
																<button className="btn btn-outline-light ms-sm-3 ms-1">Archive</button>
															</div>
														</div>
													</div>
													<div className="rating-bx">
														<div className="rating-content d-flex d-sm-block mt-2 mt-sm-0 d-flex d-sm-block mt-2 mt-sm-0">
															<h2 className="font-w600">{data.rating}</h2>
															<ul className="d-flex">
																{ReviewData.map((data,ind)=>(
																	<li key={ind}>
																		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M10.4423 1.09747L13.1198 6.52747C13.1549 6.59864 13.2066 6.66032 13.2706 6.70731C13.3345 6.75431 13.4089 6.78523 13.4873 6.79747L19.4873 7.66747C19.5753 7.68403 19.6573 7.72418 19.7243 7.78364C19.7913 7.8431 19.841 7.91964 19.8679 8.00509C19.8949 8.09055 19.8981 8.18172 19.8773 8.26887C19.8565 8.35603 19.8124 8.4359 19.7498 8.49997L15.4148 12.7225C15.3578 12.7782 15.315 12.8468 15.2902 12.9225C15.2654 12.9982 15.2593 13.0789 15.2723 13.1575L16.2923 19.1575C16.3118 19.2526 16.3029 19.3514 16.2667 19.4416C16.2304 19.5317 16.1685 19.6092 16.0885 19.6644C16.0085 19.7195 15.9141 19.75 15.817 19.7519C15.7199 19.7537 15.6243 19.727 15.5423 19.675L10.1873 16.855C10.1167 16.8182 10.0382 16.799 9.95854 16.799C9.87889 16.799 9.80042 16.8182 9.72979 16.855L4.41229 19.6525C4.33025 19.7045 4.23471 19.7312 4.13758 19.7294C4.04045 19.7275 3.94603 19.697 3.86607 19.6419C3.78611 19.5867 3.72415 19.5092 3.68791 19.4191C3.65168 19.3289 3.64276 19.2301 3.66229 19.135L4.68229 13.135C4.6953 13.0564 4.68915 12.9757 4.66434 12.9C4.63953 12.8243 4.5968 12.7557 4.53979 12.7L0.249786 8.49997C0.184967 8.43563 0.139215 8.35461 0.11759 8.26587C0.0959658 8.17714 0.0993131 8.08415 0.127262 7.9972C0.15521 7.91025 0.20667 7.83273 0.275947 7.77321C0.345224 7.71369 0.429616 7.6745 0.519786 7.65998L6.51978 6.78998C6.5982 6.77773 6.67252 6.74681 6.73648 6.69982C6.80044 6.65283 6.85216 6.59114 6.88729 6.51997L9.56479 1.08997C9.60734 1.01017 9.67094 0.943542 9.74869 0.897337C9.82644 0.851131 9.91537 0.827111 10.0058 0.827884C10.0962 0.828657 10.1847 0.854194 10.2617 0.901721C10.3386 0.949249 10.4011 1.01695 10.4423 1.09747Z" fill="#F66F4D"/>
																		</svg>
																	</li>
																))}
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
						</Tab.Pane>
						<Tab.Pane eventKey="Archived">
							{ReviewData3.map((data, ind)=>(
								<div className="card" key={ind}>
									<div className="card-body">
										<div className="row">
											<div className="col-xl-3 col-lg-3">
												<div className="review-bx d-flex align-items-center">
													<div className="review-img">
														<img src={data.image} alt="123" />
													</div>
													<div className="review-content">
														<h2 className="font-w500">{data.title}</h2>
														<p className="font-w400 m-0">#EMP-00025</p>
													</div>
												</div>
												<p className="review-date font-w400">Tuesday, January 24th 2021 at 2:56 AM</p>
											</div>
											<div className="col-xl-9 col-lg-9">
												<div className="description-bx d-inline-block d-sm-flex align-items-center">
													<div className="description-content">
														<h3 className="font-w500">{data.detail}</h3>
														<p className=" sub-title font-w400 lh-2">
															We were totally refreshed and rejuvenated for the whole of next year and it was due to the relaxing stay at the hotel. The hotel is absolutely marvelous! We liked absolutely everything, starting from the breakfast through to the perfect room service including the cleanliness and extra services such as dry cleaning and laundry. In general all the staff at the hotel were professional, friendly and provided excellent service. We will return for sure
														</p>
														<div className="d-inline-block d-xl-flex align-items-center justify-content-between">
															<div className="badge-bx">
																<span className="btn btn-dark light border-0 btn-sm me-1">Great Service</span>
																<span className="btn btn-dark light border-0 btn-sm me-1">Recomended</span>
																<span className="btn btn-dark light border-0 btn-sm me-1">Best Price</span>
															</div>
															<div className="button-bx mt-2 mt-xl-0">
																<button className="btn btn-success">Accept
																	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M10 0C4.50742 0 0 4.50742 0 10C0 15.4926 4.50742 20 10 20C15.4926 20 20 15.4926 20 10C20 4.50742 15.4926 0 10 0ZM8.7898 14.5484L4.4107 10.1694L6.06781 8.51227L8.86648 11.3109L14.485 6.20344L16.062 7.93723L8.7898 14.5484Z" fill="white"/>
																	</svg>
																</button>
																<button className="btn btn-primary ms-sm-3 ms-1">Reject
																	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M10 0C4.50742 0 0 4.50742 0 10C0 15.4926 4.50742 20 10 20C15.4926 20 20 15.4926 20 10C20 4.50742 15.4926 0 10 0ZM14.9719 13.3148L13.3148 14.9719L10 11.6571L6.68523 14.9719L5.02812 13.3148L8.34289 10L5.02812 6.68523L6.68523 5.02812L10 8.34289L13.3148 5.02812L14.9719 6.68523L11.6571 10L14.9719 13.3148Z" fill="white"/>
																	</svg>
																</button>
															</div>
														</div>
													</div>
													<div className="rating-bx">
														<div className="rating-content d-flex d-sm-block mt-2 mt-sm-0 d-flex d-sm-block mt-2 mt-sm-0">
															<h2 className="font-w600">{data.rating}</h2>
															<ul className="d-flex">
																{ReviewData.map((data,ind)=>(
																	<li key={ind}>
																		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M10.4423 1.09747L13.1198 6.52747C13.1549 6.59864 13.2066 6.66032 13.2706 6.70731C13.3345 6.75431 13.4089 6.78523 13.4873 6.79747L19.4873 7.66747C19.5753 7.68403 19.6573 7.72418 19.7243 7.78364C19.7913 7.8431 19.841 7.91964 19.8679 8.00509C19.8949 8.09055 19.8981 8.18172 19.8773 8.26887C19.8565 8.35603 19.8124 8.4359 19.7498 8.49997L15.4148 12.7225C15.3578 12.7782 15.315 12.8468 15.2902 12.9225C15.2654 12.9982 15.2593 13.0789 15.2723 13.1575L16.2923 19.1575C16.3118 19.2526 16.3029 19.3514 16.2667 19.4416C16.2304 19.5317 16.1685 19.6092 16.0885 19.6644C16.0085 19.7195 15.9141 19.75 15.817 19.7519C15.7199 19.7537 15.6243 19.727 15.5423 19.675L10.1873 16.855C10.1167 16.8182 10.0382 16.799 9.95854 16.799C9.87889 16.799 9.80042 16.8182 9.72979 16.855L4.41229 19.6525C4.33025 19.7045 4.23471 19.7312 4.13758 19.7294C4.04045 19.7275 3.94603 19.697 3.86607 19.6419C3.78611 19.5867 3.72415 19.5092 3.68791 19.4191C3.65168 19.3289 3.64276 19.2301 3.66229 19.135L4.68229 13.135C4.6953 13.0564 4.68915 12.9757 4.66434 12.9C4.63953 12.8243 4.5968 12.7557 4.53979 12.7L0.249786 8.49997C0.184967 8.43563 0.139215 8.35461 0.11759 8.26587C0.0959658 8.17714 0.0993131 8.08415 0.127262 7.9972C0.15521 7.91025 0.20667 7.83273 0.275947 7.77321C0.345224 7.71369 0.429616 7.6745 0.519786 7.65998L6.51978 6.78998C6.5982 6.77773 6.67252 6.74681 6.73648 6.69982C6.80044 6.65283 6.85216 6.59114 6.88729 6.51997L9.56479 1.08997C9.60734 1.01017 9.67094 0.943542 9.74869 0.897337C9.82644 0.851131 9.91537 0.827111 10.0058 0.827884C10.0962 0.828657 10.1847 0.854194 10.2617 0.901721C10.3386 0.949249 10.4011 1.01695 10.4423 1.09747Z" fill="#F66F4D"/>
																		</svg>
																	</li>
																))}
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
						</Tab.Pane>
					</Tab.Content>	
					<div className="d-flex align-items-center justify-content-between flex-wrap">
						<div className="mb-sm-0 mb-3">
							<h5 className="mb-0">Showing 5 of 10 Data</h5>
						</div>
						<nav>
							<ul className="pagination pagination-gutter pagination-primary style-1">
								<li className="page-item page-indicator job-search-page">
									<Link to={"#"} className="page-link">Prev</Link>
								</li>
								<li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>
								<li className="page-item"><Link to={"#"} className="page-link">2</Link></li>								
								<li className="page-item page-indicator job-search-page">
									<Link to={"#"} className="page-link">Next</Link>
								</li>
							</ul>
						</nav>
					</div>
				</Tab.Container>
			</div>		
		</>
	)
}
export default Reviews;