import React,{useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { Dropdown } from "react-bootstrap";

//Images
import pic1 from './../../../images/list/pic-1.jpg';
import pic2 from './../../../images/list/pic-2.jpg';
import pic3 from './../../../images/list/pic-3.jpg';
import pic4 from './../../../images/list/pic-4.jpg';
import pic5 from './../../../images/list/pic-5.jpg';


const guestData = [
	{image: pic1, status:'Pending', classChange:'badge-warning',	name:'Kierra Geidt',}, 
	{image: pic2, status:'Booked',	classChange: 'badge-success',	name:'Cahyadi Purnomo',}, 
	{image: pic3, status:'Refund',	classChange:'badge-primary',	name:'Alena Westervelt',}, 
	{image: pic4, status:'Canceled',classChange:'badge-danger',		name:'Tiana Workman',}, 
	{image: pic5, status:'Pending', classChange:'badge-warning',	name:'Makenna Rosser',}, 
	{image: pic1, status:'Booked', 	classChange: 'badge-success',	name:'Skylar Septimus',}, 
	{image: pic2, status:'Canceled',classChange:'badge-danger',		name:'Cahyadi Purnomo',}, 
	{image: pic3, status:'Refund', 	classChange:'badge-primary',	name:'Alena Westervelt',}, 
	{image: pic4, status:'Pending',	classChange:'badge-warning',	name:'Tiana Workman',}, 
	{image: pic5, status:'Booked', 	classChange: 'badge-success',	name:'Makenna Rosser',},
	{image: pic1, status:'Canceled',classChange:'badge-danger',		name:'Kierra Geidt',}, 
	{image: pic2, status:'Refund', 	classChange:'badge-primary',	name:'Skylar Septimus',}, 
	{image: pic3, status:'Booked',	classChange: 'badge-success',	name:'Alena Westervelt',}
];

const DropdownBlog = () =>{
	return(
		<>
			<Dropdown className="dropdown">
				<Dropdown.Toggle as="div" className="btn-link i-false" data-bs-toggle="dropdown" aria-expanded="false">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropdown-menu">
					<Dropdown.Item className="dropdown-item">Edit</Dropdown.Item>
					<Dropdown.Item className="dropdown-item">Delete</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</>
	)
}

const GuestList = () =>{
	const [data, setData] = useState(
		document.querySelectorAll("#example2_wrapper tbody tr")
	);
	const sort = 8;
	const activePag = useRef(0);
	//const [test, settest] = useState(0);

	// Active data
	const chageData = (frist, sec) => {
		for (var i = 0; i < data.length; ++i) {
			if (i >= frist && i < sec) {
				data[i].classList.remove("d-none");
			} else {
				data[i].classList.add("d-none");
			}
		}
	};
   // use effect
   useEffect(() => {
      setData(document.querySelectorAll("#example2_wrapper tbody tr"));
      //chackboxFun();
	}, []);

  
   // Active pagginarion
   activePag.current === 0 && chageData(0, sort);
   // paggination
   let paggination = Array(Math.ceil(data.length / sort))
      .fill()
      .map((_, i) => i + 1);

   // Active paggination & chage data
	const onClick = (i) => {
		activePag.current = i;
		chageData(activePag.current * sort, (activePag.current + 1) * sort);
		//settest(i);
	};

   
	const chackbox = document.querySelectorAll(".sorting_1 input");
	const motherChackBox = document.querySelector(".sorting_asc input");
   // console.log(document.querySelectorAll(".sorting_1 input")[0].checked);
	const chackboxFun = (type) => {
      for (let i = 0; i < chackbox.length; i++) {
         const element = chackbox[i];
         if (type === "all") {
            if (motherChackBox.checked) {
               element.checked = true;
            } else {
               element.checked = false;
            }
         } else {
            if (!element.checked) {
               motherChackBox.checked = false;
               break;
            } else {
               motherChackBox.checked = true;
            }
         }
      }
    };
	return(
		<>
			<div className="row">
				<div className="col-xl-12">
					<div className="card">
						<div className="card-body p-0">
							<div className="table-responsive">
								<div id="example2_wrapper" className="dataTables_wrapper no-footer">
									<table
										id="example2"
										className="table table-list i-table style-1 mb-4 border-0 dataTablesCard dataTable no-footer"
									>
										<thead>
											<tr role="row">
												<th className="sorting_asc bg-none" >
													<div className="form-check  style-3">
														<input type="checkbox" onClick={() => chackboxFun("all")} className="form-check-input" id="checkAll" required=""/>
													</div>
												</th>
												<th>Guest</th>
												<th>Date Order</th>
												<th>Check In</th>
												<th>Check Out</th>
												<th>Request</th>
												<th>Room Type</th>
												<th>Status</th>
												<th className="bg-none"></th>
											</tr>
										</thead>
										<tbody>
											{guestData.map((item, ind)=>(
												<tr key={ind}>
													<td className="sorting_1">
														<div className="form-check  style-3">
															<input type="checkbox" onClick={() => chackboxFun()} 
																className="form-check-input" id={"customCheckBox2"+ ind} required=""
															/>
														</div>
													</td>
													<td>
														<div className="media-bx d-flex py-3 ">
															<img className="me-3 rounded-circle" src={item.image} alt="" />
															<div>
																<span className="text-primary fs-16 font-w400 text-nowrap">#GS-2234</span>
																<h4 className="mb-0 mt-1 fs-15 font-w500 text-nowrap text-nowrap">
																	<Link  className="text-black" to={"/guest-details"}>{item.name}</Link>
																</h4>
															</div>
														</div>
													</td>
													<td>
														<div>
															<h5 className="fs-15 font-w500 text-nowrap">Sunday, Oct 24th, 2020</h5>
															<span className="fs-14 font-w400 text-nowrap">08:29 AM</span>
														</div>
													</td>
													<td>
														<div>
															<h5 className="fs-15 font-w500 text-nowrap">Oct 29th, 2020</h5>
															<span className="fs-14 font-w400 text-nowrap">08:29 AM</span>
														</div>
													</td>
													<td>
														<div>
															<h5 className="fs-15 font-w500 text-nowrap">Oct 31th, 2020</h5>
															<span className="fs-14 font-w400 text-nowrap">08:29 AM</span>
														</div>
													</td>
													{ind % 2 === 1  ? 
														<td><Link to={"#"} className="btn-link text-nowrap">View Notes</Link></td>
														: 
														<td><Link to={"#"} className="btn-link-light text-nowrap">No Request</Link></td>
													}
													<td>
														<div>
															<h5 className="fs-15 font-w500 text-nowrap">Queen A-2345</h5>
															<span className="fs-14 font-w400 text-nowrap">Room No. 0024</span>
														</div>
													</td>
													<td><span className={`badge badge-xl light badge-sm ${item.classChange}`}>{item.status}</span></td>
													<td><DropdownBlog /></td>
												</tr>											
											))}											
										</tbody>
									</table>
									<div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
										<div className="dataTables_info">
											Showing {activePag.current * sort + 1} to{" "}
											{data.length > (activePag.current + 1) * sort
												? (activePag.current + 1) * sort
												: data.length}{" "}
											of {data.length} entries
										</div>
										<div
											className="dataTables_paginate paging_simple_numbers mb-0"
											id="example2_paginate"
										>
											<Link
												className="paginate_button previous disabled"
												to="/guest-list"
												onClick={() =>
												   activePag.current > 0 &&
												   onClick(activePag.current - 1)
												}
											 >
												{/* <i className="fa fa-angle-double-left" aria-hidden="true"></i> */}
												Previous
											</Link>
											<span>
												{paggination.map((number, i) => (
												   <Link
													  key={i}
													  to="/guest-list"
													  className={`paginate_button  ${
														 activePag.current === i ? "current" : ""
													  } `}
													  onClick={() => onClick(i)}
												   >
													  {number}
												   </Link>
												))}
											</span>

											<Link
												className="paginate_button next"
												to="/guest-list"
												onClick={() =>
												   activePag.current + 1 < paggination.length &&
												   onClick(activePag.current + 1)
												}
											>
												{/* <i className="fa fa-angle-double-right" aria-hidden="true"></i> */}
												Next
											</Link>
										</div>
									</div>
								</div>
							</div>
							
							
						</div>
					</div>
				</div>
			</div>				
							
		</>
	)
}

export default GuestList;