import React, { useContext, useState, useEffect } from "react";

/// React router dom
import { Switch, Route, useHistory } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";
import DashboardDark from "./components/Dashboard/DashboardDark";
import GuestList from "./components/Dashboard/GuestList";
import GuestDetails from "./components/Dashboard/GuestDetails";
import Concierge from "./components/Dashboard/Concierge";
import Room from "./components/Dashboard/Room";
import Reviews from "./components/Dashboard/Reviews";

import Task from "./components/Dashboard/Task";

/////Demo
import Theme1 from "./components/Dashboard/theme/Theme1";
import Theme2 from "./components/Dashboard/theme/Theme2";
import Theme3 from "./components/Dashboard/theme/Theme3";
import Theme4 from "./components/Dashboard/theme/Theme4";
import Theme5 from "./components/Dashboard/theme/Theme5";
import Theme6 from "./components/Dashboard/theme/Theme6";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import Chartist from "./components/charts/chartist";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
//import Nestable from "./components/PluginsMenu/Nestable/Nestable";
import MainNouiSlider from "./components/PluginsMenu/NouiSlider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

//Redux
import Todo from "./pages/Todo";
//import ReduxForm from "./components/Forms/ReduxForm/ReduxForm";
//import WizardForm from "./components/Forms/ReduxWizard/Index";

/// Widget
import Widget from "./pages/Widget";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import CKEditor from "./components/Forms/Summernote/SummerNote";
import Pickers from "./components/Forms/Pickers/Pickers";
import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";

/// Pages
import Registration from "./pages/Registration";
// import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";
import PropertiesList from "./pages/PropertiesList";
import PropertyAdd from "./pages/PropertyAdd";
import ImageUpload from "./pages/ImageUpload";
import RoomCreate from "./pages/RoomCreate";
import RoomsList from "./pages/RoomsList";
import CancellationPolicy from "./pages/CancellationPolicy";
import TenantDetails from "./pages/TenantDetails";
import TenantEdit from "./pages/TenantEdit";
import BannerImageUpload from "./pages/BannerImageUpload";
import CouponsList from "./pages/CouponsList";
import CoupounAdd from "./pages/CouponAdd";
import Payments from "./pages/Payments";
import PaymentStatus from "./pages/PaymentStatus";
import { getUserInfo } from "utils/helper";
import call from "utils/request";
import Alert from "./components/Alert";
import Reports from "./pages/Reports";
import RoomCleaning from "./pages/RoomCleaningForm";
import UsersList from "./pages/UsersList";
import UserInvite from "./pages/UserInvite";
import UserEdit from "./pages/UserEdit";
import RolesList from "./pages/RolesList";
import RoleAdd from "./pages/RoleAdd";
import RoomOOO from "./pages/RoomOOO";

import EventsList from "./pages/sanskriti-utsav/Events/EventsList";
import EventDetails from "./pages/sanskriti-utsav/Events/EventDetails";
import EventForm from "./pages/sanskriti-utsav/Events/EventForm";

import Registrations from "./pages/sanskriti-utsav/Registrations";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const history = useHistory();
  const userInfo = getUserInfo();
  const [disabled, setDisabled] = useState(false);
  const [tenantDetails, setTenantDetails] = useState(false);

  // useEffect(() => {
  //   const getTenantDetail = async () => {
  //     await call({
  //       method: 'get',
  //       url: `/api/tenant/${userInfo?.user?.tenantId}`,
  //     }).then((res) => {
  //       setTenantDetails(res?.data);
  //       res?.data?.noOfDaysLeft === 0 ? setDisabled(true) : setDisabled(false);
  //     });
  //   };
  //   getTenantDetail();
  // }, [userInfo?.user?.tenantId]);

  useEffect(() => {
    if (disabled) history.push("/payments");
  }, [disabled]);

  const paymentRoutes = [
    { url: "payments", component: Payments },
    { url: "payments/status", component: PaymentStatus },
  ];

  const routes = [
    // Sanskriti Utsav
    { url: "events", component: EventsList },
    { url: "events/:id/view", component: EventDetails },
    { url: "events/create", component: EventForm },
    { url: "events/:id/edit", component: EventForm },
    { url: "registrations", component: Registrations },
    /// Dashboard
    { url: "dashboard", component: Home },
    { url: "dashboard-dark", component: DashboardDark },
    { url: "guest-list", component: GuestList },
    { url: "guest-details", component: GuestDetails },
    { url: "concierge", component: Concierge },
    { url: "users", component: UsersList },
    { url: "users/invite", component: UserInvite },
    { url: "users/:id/edit", component: UserEdit },
    { url: "roles", component: RolesList },
    { url: "roles/create", component: RoleAdd },
    { url: "roles/:id/edit", component: RoleAdd },
    { url: "room", component: Room },
    { url: "reviews", component: Reviews },
    { url: "task", component: Task },
    { url: "tenant", component: TenantDetails },
    { url: "tenant/:id/edit", component: TenantEdit },
    { url: "tenant/:id/image", component: BannerImageUpload },
    { url: "properties", component: PropertiesList },
    { url: "properties/create", component: PropertyAdd },
    { url: "properties/:id/edit", component: PropertyAdd },
    { url: "properties/:id/dashboard", component: Home },
    { url: "properties/:id/image", component: ImageUpload },
    { url: "properties/:id/rooms", component: RoomsList },
    {
      url: "properties/:id/cancellation-policy",
      component: CancellationPolicy,
    },
    { url: "properties/:id/rooms/create", component: RoomCreate },
    { url: "properties/:id/rooms/:id/edit", component: RoomCreate },
    { url: "properties/:id/rooms/:id/image", component: ImageUpload },
    { url: "properties/:id/rooms/:id/cleaning", component: RoomCleaning },
    { url: "properties/:id/rooms/:id/room-out-of-order", component: RoomOOO },
    { url: "coupons", component: CouponsList },
    { url: "coupons/create", component: CoupounAdd },
    { url: "coupons/:id/edit", component: CoupounAdd },
    { url: "payments", component: Payments },
    { url: "payments/status", component: PaymentStatus },
    { url: "reports", component: Reports },

    ///Demo
    { url: "horizontal-sidebar", component: Theme1 },
    { url: "dark-sidebar", component: Theme2 },
    { url: "sidebar-primary", component: Theme3 },
    { url: "header-primary", component: Theme4 },
    { url: "mini-sidebar", component: Theme5 },
    { url: "compact-header", component: Theme6 },

    /// Apps
    { url: "app-profile", component: AppProfile },
    { url: "email-compose", component: Compose },
    { url: "email-inbox", component: Inbox },
    { url: "email-read", component: Read },
    { url: "app-calender", component: Calendar },
    { url: "post-details", component: PostDetails },

    /// Chart
    { url: "chart-sparkline", component: SparklineChart },
    { url: "chart-chartjs", component: ChartJs },
    { url: "chart-chartist", component: Chartist },
    { url: "chart-apexchart", component: ApexChart },
    { url: "chart-rechart", component: RechartJs },

    /// Bootstrap
    { url: "ui-alert", component: UiAlert },
    { url: "ui-badge", component: UiBadge },
    { url: "ui-button", component: UiButton },
    { url: "ui-modal", component: UiModal },
    { url: "ui-button-group", component: UiButtonGroup },
    { url: "ui-accordion", component: UiAccordion },
    { url: "ui-list-group", component: UiListGroup },
    { url: "ui-card", component: UiCards },
    { url: "ui-carousel", component: UiCarousel },
    { url: "ui-dropdown", component: UiDropDown },
    { url: "ui-popover", component: UiPopOver },
    { url: "ui-progressbar", component: UiProgressBar },
    { url: "ui-tab", component: UiTab },
    { url: "ui-pagination", component: UiPagination },
    { url: "ui-typography", component: UiTypography },
    { url: "ui-grid", component: UiGrid },

    /// Plugin
    { url: "uc-select2", component: Select2 },
    //{ url: "uc-nestable", component: Nestable },
    { url: "uc-noui-slider", component: MainNouiSlider },
    { url: "uc-sweetalert", component: MainSweetAlert },
    { url: "uc-toastr", component: Toastr },
    { url: "map-jqvmap", component: JqvMap },
    { url: "uc-lightgallery", component: Lightgallery },

    ///Redux
    { url: "todo", component: Todo },
    //{ url: "redux-form", component: ReduxForm },
    //{ url: "redux-wizard", component: WizardForm },

    /// Widget
    { url: "widget-basic", component: Widget },

    /// Shop
    { url: "ecom-product-grid", component: ProductGrid },
    { url: "ecom-product-list", component: ProductList },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-product-order", component: ProductOrder },
    { url: "ecom-checkout", component: Checkout },
    { url: "ecom-invoice", component: Invoice },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-customers", component: Customers },

    /// Form
    { url: "form-element", component: Element },
    { url: "form-wizard", component: Wizard },
    { url: "form-ckeditor", component: CKEditor },
    { url: "form-pickers", component: Pickers },
    { url: "form-validation-jquery", component: jQueryValidation },

    /// table
    { url: "table-filtering", component: FilteringTable },
    { url: "table-sorting", component: SortingTable },
    { url: "table-datatable-basic", component: DataTable },
    { url: "table-bootstrap-basic", component: BootstrapTable },

    /// pages
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    // { url: "page-login", component: Login },
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },

    { url: "", component: Home },
    { url: "*", component: Error404 },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav disabled={disabled} />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          {tenantDetails?.noOfDaysLeft <= 3 && (
            <Alert
              message={`Your subscription is going to end in ${tenantDetails?.noOfDaysLeft} day(s)!`}
              type="error"
            />
          )}
          <div
            className={`${
              !pagePath
                ? tenantDetails?.noOfDaysLeft <= 3
                  ? "container-fluid mt-3"
                  : "container-fluid"
                : ""
            }`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {!disabled ? (
                routes.map((data, i) => (
                  <Route
                    key={i}
                    exact
                    path={`/${data.url}`}
                    component={data.component}
                  />
                ))
              ) : (
                <>
                  {paymentRoutes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))}
                </>
              )}
            </Switch>
          </div>
        </div>
        {/* {!pagePath && <Footer />} */}
      </div>
      {/* <Setting /> */}
      <ScrollToTop />
    </>
  );
};

export default Markup;
