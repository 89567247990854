import { useState, useEffect } from "react";
import call from "utils/request";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import moment from "moment";
import EventCalendar from "jsx/components/AppsMenu/Calendar/EventCalendar";

function Reports() {
  const [stats, setStats] = useState({});
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const userInfo = JSON.parse(sessionStorage.getItem("userDetails"));
  const permissions = userInfo?.permissions;

  useEffect(() => {
    const overViewStats = async () => {
      await call(
        {
          method: "get",
          url: "/api/statistics/overview",
        },
        {
          Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
        }
      ).then((res) => {
        setStats(res?.data);
      });
    };

    overViewStats();

    const getReservations = async () => {
      setLoading(true);
      await call(
        {
          method: "get",
          url: `/api/reservations/paginate?tenantId=${userInfo?.user?.tenantId}`,
        },
        {
          Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
        }
      ).then((res) => {
        setReservations(
          res?.data?.docs.map((reservation) => {
            return {
              ...reservation,
              fromDate: moment(reservation.fromDate).format("DD MMM YYYY"),
              toDate: moment(reservation.toDate).format("DD MMM YYYY"),
            };
          })
        );
        setLoading(false);
      });
    };
    getReservations();
  }, []);

  const getReservationData = async () => {
    await call(
      {
        method: "get",
        url: "/api/reservations/all",
        params: {
          startDate,
          endDate,
        },
      },
      {
        Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
      }
    ).then((res) => {
      if (res?.data.length > 0) exportToCSV(res?.data);
      else
        Swal.fire({
          title: "No Data!",
          text: "There are no available reservations within this range",
          icon: "warning",
          showConfirmButton: false,
          timer: 1500,
        });
    });
  };

  const exportToCSV = (data) => {
    const header = `Name,Email,Phone,Start Date,End Date,No of Days,Room(s) Count,Base Price,Extra Adult Price,Extra Children Price,Price,Coupon Applied,Coupon Type,Coupon Value,Discount,Discount Price,Tax Price,Final Price,Currency,Razor Payment Id,Status\n`;
    const csvData = data
      ? data
          .map(
            (row) =>
              `${row?.customerInfo?.name},${row?.customerInfo?.email},${
                row?.customerInfo?.phone
              },${moment(row?.fromDate).format("DD-MM-YYYY")},${moment(
                row?.toDate
              ).format("DD-MM-YYYY")},${row?.noOfDays},${row?.rooms.length},${
                row?.basePrice
              },${row?.extraAdultPrice},${row?.extraChildrenPrice},${
                row?.price
              },${row.couponDetails.couponDiscountType ? "Yes" : "No"},${
                row.couponDetails.couponDiscountType
                  ? row?.couponDetails.couponDiscountType
                  : "NA"
              },${
                row.couponDetails.couponDiscountType
                  ? row?.couponDetails.couponDiscountValue
                  : "NA"
              },${
                row.couponDetails.couponDiscountType ? row?.discount : "NA"
              },${
                row.couponDetails.couponDiscountType
                  ? row?.discountedPrice
                  : "NA"
              },${row?.taxPrice},${row?.finalPrice},${row?.currency},${
                row?.razorpayPaymentId
              },${row?.status}`
          )
          .join("\n")
      : ["No Data"];
    const csvBlob = new Blob([header, csvData], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvBlob);
    const link = document.createElement("a");
    link.href = csvUrl;
    link.download = `Reservation_${
      startDate === endDate
        ? moment(startDate).format("DD-MM-YYYY")
        : `${moment(startDate).format("DD-MM-YYYY")}-${moment(endDate).format(
            "DD-MM-YYYY"
          )}`
    }.csv`;
    link.click();
    Swal.fire({
      title: "Exported!",
      text: "Report has been exported",
      icon: "success",
      showConfirmButton: false,
      timer: 1000,
    });
  };

  if (loading) {
    return <div className="loader vh-100"></div>;
  }

  return (
    !loading && (
      <>
        <div className="row">
          <div className="d-flex align-items-end">
            {permissions?.REPORT?.create && (
              <>
                <div className="form-group col-md-3 mx-2">
                  <label>
                    From Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) =>
                      setStartDate(moment(e.target.value).valueOf())
                    }
                  />
                </div>
                <div className="form-group col-md-3 mx-2">
                  <label>
                    To Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) =>
                      setEndDate(moment(e.target.value).valueOf())
                    }
                  />
                </div>
                <div>
                  <Button
                    className="bg-primary py-1 py-3 mx-2"
                    onClick={() => getReservationData()}
                  >
                    Export
                  </Button>
                </div>
              </>
            )}
          </div>
          <div className="mx-2 mt-4">
            <div className="row">
              <div className="col-4 mb-1">
                <div className="card" style={{ height: "11rem" }}>
                  <div className="card-body px-3 d-flex flex-column">
                    <div className="row d-flex flex-column">
                      <div
                        className="col fw-bolder text-primary mb-2"
                        style={{
                          fontSize: "1.5rem",
                        }}
                      >
                        Room Revenue
                      </div>
                      <div
                        className="col fw-bolder text-success"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        ₹ {stats.roomRevenue}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 mb-1">
                <div className="card" style={{ height: "11rem" }}>
                  <div className="card-body px-3 d-flex flex-column">
                    <div className="row d-flex flex-column">
                      <div
                        className="col fw-bolder text-primary mb-2"
                        style={{
                          fontSize: "1.5rem",
                        }}
                      >
                        Occupancy Rate
                      </div>
                      <div
                        className="col fw-bolder text-success"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        {stats.occupancyRate === "NaN%"
                          ? "0%"
                          : stats.occupancyRate}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 mb-1">
                <div className="card" style={{ height: "11rem" }}>
                  <div className="card-body px-3 d-flex flex-column">
                    <div className="row d-flex flex-column">
                      <div
                        className="col fw-bolder text-primary mb-2"
                        style={{
                          fontSize: "1.5rem",
                        }}
                      >
                        Room Revenue Per Available Room
                      </div>
                      <div
                        className="col fw-bolder text-success"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        ₹ {stats.roomRevenuePerAvailableRoom || 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 mb-1">
                <div className="card" style={{ height: "11rem" }}>
                  <div className="card-body px-3 d-flex flex-column">
                    <div className="row d-flex flex-column">
                      <div
                        className="col fw-bolder text-primary mb-2"
                        style={{
                          fontSize: "1.5rem",
                        }}
                      >
                        Average Daily Rate
                      </div>
                      <div
                        className="col fw-bolder text-success"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        ₹ {stats.averageDailyRate}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 mb-1">
                <div className="card" style={{ height: "11rem" }}>
                  <div className="card-body px-3 d-flex flex-column">
                    <div className="row d-flex flex-column">
                      <div
                        className="col fw-bolder text-primary mb-2"
                        style={{
                          fontSize: "1.5rem",
                        }}
                      >
                        Average Length of Stay
                      </div>
                      <div
                        className="col fw-bolder text-success"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        {stats.averageLenOfStay} Day(s)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-2">
            <EventCalendar data={reservations} report={true} />
          </div>
        </div>
      </>
    )
  );
}

export default Reports;
