import React, { useState, useEffect } from 'react';
import styles from './Alert.module.css';

const Alert = ({ message, type, onClose, closebtn = false }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Show the alert with a delay
    setIsVisible(true);

    // Hide the alert after a certain time (e.g., 3 seconds)
    // const timeout = setTimeout(() => {
    //   closeAlert();
    // }, 3000);

    // Clean up the timeout if the component unmounts or if onClose is called manually
    // return () => clearTimeout(timeout);
  }, [onClose]);

  const closeAlert = () => {
    // Start the fade-out animation by removing the "show" class
    setIsVisible(false);

    // Delay the actual removal of the component to match the animation duration
    setTimeout(() => {
      onClose(); // Call the onClose callback when the alert is hidden
    }, 300);
  };

  return (
    <div
      className={`${styles.alert} ${styles[type]} ${
        isVisible ? styles.show : ''
      }`}
    >
      {closebtn && (
        <span className={styles.close} onClick={closeAlert}>
          &times;
        </span>
      )}
      {message}
    </div>
  );
};

export default Alert;
