import React from "react";
import { Pagination } from "react-bootstrap";

function PaginationDetails({ paginationDetails, onClick }) {
  return (
    <Pagination
      className="text-info  justify-content-end"
      aria-label="Page navigation example"
      size="sm"
    >
      <Pagination.First className="bg-dark" onClick={() => onClick(1)} />
      <Pagination.Prev
        onClick={() => onClick(paginationDetails.prevPage)}
        {...(paginationDetails.hasPrevPage
          ? { className: "bg-dark" }
          : { disabled: true, className: "bg-light" })}
      />
      {paginationDetails.pageNumbers &&
        paginationDetails.pageNumbers.map((pageNumber) => (
          <Pagination.Item
            active={paginationDetails.page === pageNumber}
            onClick={() => onClick(pageNumber)}
            key={pageNumber}
          >
            {pageNumber}
          </Pagination.Item>
        ))}
      <Pagination.Next
        onClick={() => onClick(paginationDetails.nextPage)}
        {...(paginationDetails.hasNextPage
          ? { className: "bg-dark" }
          : { disabled: true, className: "bg-light" })}
      />
      <Pagination.Last
        className="bg-dark"
        onClick={() => onClick(paginationDetails.totalPages)}
      />
    </Pagination>
  );
}

export default PaginationDetails;
