import React, { useRef } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Alert from 'sweetalert2';
import moment from 'moment';
import { DB_TIMESTAMP } from 'utils/constants';
import './style.module.css';
import call from 'utils/request';

function EventCalendar({ data, report = false }) {
  const calendarRef = useRef(null);

  const renderContent = data?.map((item) => {
    item.id = item?._id;
    item.title = item?.rooms?.map((data) => data?.room?.type);
    item.start = moment(item?.fromDate).format(DB_TIMESTAMP);
    item.end = moment(item?.toDate).format(DB_TIMESTAMP);
    return item;
  });

  /**
   * when we click on event we are displaying event details
   */
  const eventClick = (eventClick) => {
    Alert.fire({
      title: eventClick.event.title,
      html:
        `<div className="table-responsive">
      <table className="table">
      <tbody>
      <tr className="d-flex flex-nowrap">
      <td>Room type</td>
      <td><strong>` +
        eventClick.event.title +
        `</strong></td>
      </tr>
      <tr >
      <td>Check-in date</td>
      <td><strong>
      ` +
        eventClick.event.start +
        `
      </strong></td>
      </tr>
      </tbody>
      </table>
      </div>`,

      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Remove Event',
      cancelButtonText: 'Close',
    }).then(async (result) => {
      const reservationId = eventClick?.event?.extendedProps?._id;
      if (result.value) {
        await call({
          method: 'delete',
          url: `/api/reservations/${reservationId}`,
        }).then(() => {
          eventClick.event.remove(); // It will remove event from the calendar
          Alert.fire('Deleted!', 'Your Event has been deleted.', 'success');
        });
      }
    });
  };

  const ReportEvent = (eventClick) => {
    const { extendedProps } = eventClick.event;
    Alert.fire({
      title: 'Booking Details',
      html:
        `<div className="table-responsive">
      <table className="table">
      <tbody>
      <tr className="d-flex flex-nowrap">
            <td style="text-align: start;">Room type</td>
            <td style="text-align: start; padding-left: 20px;"><strong>${eventClick.event.title}</strong></td>
          </tr>
          <tr>
            <td style="text-align: start;">Customer Name</td>
            <td style="text-align: start; padding-left: 20px;"><strong>${extendedProps.customerInfo.name}</strong></td>
          </tr>
          <tr>
            <td style="text-align: start;">Email</td>
            <td style="text-align: start; padding-left: 20px;"><strong>${extendedProps.customerInfo.email}</strong></td>
          </tr>
      <tr >
      <td style="text-align: start;">Phone</td>
      <td style="text-align: start; padding-left: 20px;"><strong>
      ` +
        extendedProps.customerInfo.phone +
        `
      </strong></td>
      </tr>
      <tr >
      <td style="text-align: start;">Start Date</td>
      <td style="text-align: start; padding-left: 20px;"><strong>
      ` +
        extendedProps.fromDate +
        `
      </strong></td>
      </tr>
      <tr >
      <td style="text-align: start;">End Date</td>
      <td style="text-align: start; padding-left: 20px;"><strong>
      ` +
        extendedProps.toDate +
        `
      </strong></td>
      </tr>
      <tr >
      <td style="text-align: start;">No. of Days</td>
      <td style="text-align: start; padding-left: 20px;"><strong>
      ` +
        extendedProps.noOfDays +
        `
      </strong></td>
      </tr>
      <tr >
      <td style="text-align: start;">Room(s) Count</td>
      <td style="text-align: start; padding-left: 20px;"><strong>
      ` +
        extendedProps.rooms.length +
        `
      </strong></td>
      </tr>
      <tr >
      <td style="text-align: start;">Base Price</td>
      <td style="text-align: start; padding-left: 20px;"><strong>₹
      ` +
        extendedProps.basePrice +
        `
      </strong></td>
      </tr>
      <tr >
      <td style="text-align: start;">Extra Adult Price</td>
      <td style="text-align: start; padding-left: 20px;"><strong>₹
      ` +
        extendedProps.extraAdultPrice +
        `
      </strong></td>
      </tr>
      <tr >
      <td style="text-align: start;">Extra Children Price</td>
      <td style="text-align: start; padding-left: 20px;"><strong>₹
      ` +
        extendedProps.extraChildrenPrice +
        `
      </strong></td>
      </tr>
      <tr >
      <td style="text-align: start;">Price</td>
      <td style="text-align: start; padding-left: 20px;"><strong>₹
      ` +
        extendedProps.price +
        `
      </strong></td>
      </tr>
       <tr >
      <td style="text-align: start;">Coupon Applied</td>
      <td style="text-align: start; padding-left: 20px;"><strong>
      
        ${extendedProps.couponDetails.couponDiscountType ? 'Yes' : 'No'}
           
      </strong></td>
      </tr>
      <tr >
      <td style="text-align: start;">Discount Type</td>
      <td style="text-align: start; padding-left: 20px;"><strong>
       
        ${
          extendedProps.couponDetails.couponDiscountType
            ? extendedProps.couponDetails.couponDiscountType
            : 'NA'
        }
            
      </strong></td>
      </tr>
      <tr >
      <td style="text-align: start;">Discount Value</td>
      <td style="text-align: start; padding-left: 20px;"><strong>
      
            ${
              extendedProps.couponDetails.couponDiscountType
                ? extendedProps.couponDetails.couponDiscountValue
                  ? '₹' + extendedProps.couponDetails.couponDiscountValue
                  : extendedProps.couponDetails.couponDiscountValue + '%'
                : 'NA'
            }
            
      </strong></td>
      </tr>
      <tr >
      <td style="text-align: start;">Discount</td>
      <td style="text-align: start; padding-left: 20px;"><strong>₹
    
       ${
         extendedProps.couponDetails.couponDiscountType
           ? extendedProps.discount
           : 'NA'
       }
      </strong></td>
      </tr>
      <tr >
      <td style="text-align: start;">Discounted Price</td>
      <td style="text-align: start; padding-left: 20px;"><strong>₹
      
      ${
        extendedProps.couponDetails.couponDiscountType
          ? extendedProps.discountedPrice
          : 'NA'
      }
      </strong></td>
      </tr>
      <tr >
      <td style="text-align: start;">Tax Price</td>
      <td style="text-align: start; padding-left: 20px;"><strong>₹
      ` +
        extendedProps.taxPrice +
        `
      </strong></td>
      </tr>
      <tr >
      <td style="text-align: start;">Final Price</td>
      <td style="text-align: start; padding-left: 20px;"><strong>₹
      ` +
        extendedProps.finalPrice +
        `
      </strong></td>
      </tr>
      </tbody>
      </table>
      </div>`,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      cancelButtonText: 'Close',
    });
  };

  return (
    <div className="animated fadeIn demo-app">
      <Row>
        <Col lg={3}>
          <Card>
            <div className="card-header border-0 pb-0">
              <h4 className="text-black fs-20 mb-0">Bookings</h4>
            </div>
            <Card.Body>
              <div id="external-events">
                {data?.length ? (
                  <>
                    {data?.map((event, i) => (
                      <div
                        className="fc-event mt-0 ms-0 mb-2 btn btn-block btn-primary"
                        title={event.title}
                        data={event.id}
                        key={`booking-list-${i}`}
                      >
                        {event?.rooms?.map((item, ind) => (
                          <div key={`booking-card-${ind}`}>
                            <h5>{item?.room?.type}</h5>
                            {event?.fromDate} - {event?.toDate}
                          </div>
                        ))}
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="text-center mt-5">
                    <h4 className="fw-bold">No Bookings</h4>
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={9}>
          <Card>
            <Card.Body>
              <div className="demo-app-calendar" id="mycalendartest">
                <FullCalendar
                  initialView="dayGridMonth"
                  rerenderDelay={10}
                  eventDurationEditable={false}
                  editable={true}
                  droppable={true}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  ref={calendarRef}
                  eventDisplay="block"
                  weekends
                  events={renderContent}
                  eventClick={report ? ReportEvent : eventClick}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default EventCalendar;
