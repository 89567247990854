import React from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Drop from 'react-dropzone-uploader';
import { MAX_MEDIA_UPLOAD_SIZE } from 'utils/constants';

const DropFile = ({ setSelectedFile, isSubmitting }) => {
  // specify upload params and url for your files
  // const getUploadParams = ({ meta }) => {
  //    return { url: "https://httpbin.org/post" };
  // };
  // let tempFiles = [];

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status, allFiles) => {
    console.log(status, meta, file, allFiles);
    // tempFiles.push(file);
    setSelectedFile(allFiles.map((f) => f.file));
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files) => {
    //  console.log(files.map((f) => f.meta));
    setSelectedFile(files.map((f) => f.file));
  };

  return (
    <Drop
      // getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      inputContent="Click or Drop here"
      accept="image/*,video/*"
      submitButtonDisabled={isSubmitting}
      maxSizeBytes={MAX_MEDIA_UPLOAD_SIZE}
      styles={{
        dropzone: {
          minHeight: 200,
          maxHeight: 300,
          width: '100%',
          backgroundColor: '#f2f4fa',
          border: '1px dashed #DDDFE1',
          overflow: 'auto',
        },
        inputLabel: {
          color: '#7e7e7e',
          fontSize: '18px',
          fontWeight: 'normal',
          backgroundColor: '#f2f4fa',
        },
      }}
      // initialFiles={media}
    />
  );
};

export default DropFile;
