import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import call from "utils/request";
import { Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";
import { MANDAL, JANPATH, TEHSIL } from "utils/constants";
import { capitalizeFirstChar } from "utils/helper";

const UserEdit = () => {
  const history = useHistory();
  const location = useLocation();
  const state = location.state;
  const userInfo = JSON.parse(sessionStorage.getItem("userDetails"));
  const permissions = userInfo?.permissions;
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(state?.roleId || "");
  const [mandals, setMandals] = useState([]);
  const [janpaths, setJanpaths] = useState([]);
  const [tehsils, setTehsils] = useState([]);
  const [typeValueOptions, setTypeValueOptions] = useState([]);

  useEffect(() => {
    const getConfig = async () => {
      await call(
        {
          method: "get",
          url: "/api/config/all",
        },
        {
          Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
        }
      ).then(({ data }) => {
        setMandals(data?.mandals);
        setJanpaths(data?.janpaths);
        setTehsils(data?.tehsils);
      });
    };
    getConfig();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm({
    defaultValues: {
      role: selectedRole,
    },
  });

  const onSubmit = async (data) => {
    let updatedData = {
      roleId: data?.role,
    };

    await call(
      {
        method: "patch",
        url: `/api/users/role/${state?._id}`,
        data: updatedData,
      },
      {
        Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
      }
    )
      .then((res) => {
        if (res?.status === 204) {
          Swal.fire({
            title: "Update!",
            text:
              res?.data?.message || "User Role has been updated successfully",
            icon: "success",
            showConfirmButton: false,
            timer: 500,
          });
          history.push(`/users`);
        }
      })
      .catch((err) => {
        const errMsg = err?.response?.data?.message;
        console.log({ errMsg });
      });
  };

  return (
    <>
      <div>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label>
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        {...register("name", {
                          required: "Name is required",
                        })}
                        className="form-control"
                        placeholder="Select Name"
                      />
                      {errors?.name && (
                        <Form.Text className="text-danger">
                          {errors?.name?.message}
                        </Form.Text>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>
                        Username <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        {...register("email", {
                          required: "Email is required",
                        })}
                        className="form-control"
                        placeholder="Enter Email"
                      />
                      {errors?.email && (
                        <Form.Text className="text-danger">
                          {errors?.email?.message}
                        </Form.Text>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>
                        Password <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                        })}
                        className="form-control"
                        placeholder="Enter Password"
                        type="Password"
                      />
                      {errors?.password && (
                        <Form.Text className="text-danger">
                          {errors?.password?.message}
                        </Form.Text>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>
                        User Type <span className="text-danger">*</span>
                      </label>
                      <select
                        id="userType"
                        className="form-control"
                        {...register("userType", {
                          required: "User Type is required",
                        })}
                      >
                        <option value="" disabled>
                          Select User Type
                        </option>
                        {[MANDAL, JANPATH, TEHSIL]?.map((item, i) => (
                          <option key={`userType-${i}`} value={item}>
                            {capitalizeFirstChar(item)}
                          </option>
                        ))}
                      </select>
                      {errors?.userType && (
                        <Form.Text className="text-danger">
                          {errors?.userType?.message}
                        </Form.Text>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>
                        {`Select ${capitalizeFirstChar(getValues("userType"))}`}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        id="typeValue"
                        className="form-control"
                        {...register("typeValue", {
                          required: "Type value is required",
                        })}
                      >
                        <option value="" disabled>
                          {`Select ${capitalizeFirstChar(
                            getValues("userType")
                          )}`}
                        </option>
                        {typeValueOptions?.map((item, i) => (
                          <option key={`userType-${i}`} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                      {errors?.userType && (
                        <Form.Text className="text-danger">
                          {errors?.userType?.message}
                        </Form.Text>
                      )}
                    </div>
                    <div className="column d-flex justify-content-between">
                      <Button
                        type="button"
                        className="btn px-4"
                        variant="light"
                        onClick={() => history.goBack()}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-primary px-4"
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserEdit;
