import React,{useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { Dropdown , Carousel} from "react-bootstrap";

//Images
import pic1 from './../../../../images/guest-img/pic-1.jpg';
import pic2 from './../../../../images/guest-img/pic-2.jpg';
import pic3 from './../../../../images/guest-img/pic-3.jpg';

const carousel1 = [pic1, pic2, pic3]

const DropdownBlog = () =>{
	return(
		<>
			<Dropdown className="dropdown">
				<Dropdown.Toggle as="div" className="btn-link i-false" data-bs-toggle="dropdown" aria-expanded="false">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropdown-menu">
					<Dropdown.Item className="dropdown-item">Edit</Dropdown.Item>
					<Dropdown.Item className="dropdown-item">Delete</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</>
	)
}

const PurchaseHistory = () =>{
	const [historyBtn, setHistoryBtn] = useState('Sort by Newest');
	
	const [data, setData] = useState(
		document.querySelectorAll("#example2_guest tbody tr")
	);
	const sort = 8;
	const activePag = useRef(0);
	//const [test, settest] = useState(0);

	// Active data
	const chageData = (frist, sec) => {
		for (var i = 0; i < data.length; ++i) {
			if (i >= frist && i < sec) {
				data[i].classList.remove("d-none");
			} else {
				data[i].classList.add("d-none");
			}
		}
	};
   // use effect
   useEffect(() => {
      setData(document.querySelectorAll("#example2_guest tbody tr"));
      //chackboxFun();
	}, []);

  
   // Active pagginarion
   activePag.current === 0 && chageData(0, sort);
   // paggination
   let paggination = Array(Math.ceil(data.length / sort))
      .fill()
      .map((_, i) => i + 1);

   // Active paggination & chage data
	const onClick = (i) => {
		activePag.current = i;
		chageData(activePag.current * sort, (activePag.current + 1) * sort);
		//settest(i);
	};

   
	const chackbox = document.querySelectorAll(".sorting_2 input");
	const motherChackBox = document.querySelector(".sorting_asc input");
   // console.log(document.querySelectorAll(".sorting_2 input")[0].checked);
	const chackboxFun = (type) => {
      for (let i = 0; i < chackbox.length; i++) {
         const element = chackbox[i];
         if (type === "all") {
            if (motherChackBox.checked) {
               element.checked = true;
            } else {
               element.checked = false;
            }
         } else {
            if (!element.checked) {
               motherChackBox.checked = false;
               break;
            } else {
               motherChackBox.checked = true;
            }
         }
      }
    };
	return(
		<>
			
			<div className="card mt-4 mt-xl-0">
				<div className="card-header d-block border-0">
					<div className="form-head d-flex align-items-center">
						<div className="me-auto">
							<h2 className="p-title font-w500 mb-0">Purchase History</h2>
						</div>
						<div className="input-group search-area1">
							<input type="text" className="form-control" placeholder="Search here" />
							<span className="input-group-text">
								<Link to={"#"}>
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M9.65925 19.3102C11.8044 19.3102 13.8882 18.5945 15.5806 17.2764L21.9653 23.6611C22.4423 24.1218 23.2023 24.1086 23.663 23.6316C24.1123 23.1663 24.1123 22.4287 23.663 21.9635L17.2782 15.5787C20.5491 11.3682 19.7874 5.3033 15.5769 2.0324C11.3663 -1.23851 5.30149 -0.476829 2.03058 3.73371C-1.24033 7.94425 -0.478646 14.0091 3.73189 17.2801C5.42702 18.5969 7.51269 19.3113 9.65925 19.3102ZM4.52915 4.52727C7.36245 1.69391 11.9561 1.69386 14.7895 4.52717C17.6229 7.36047 17.6229 11.9542 14.7896 14.7875C11.9563 17.6209 7.36261 17.6209 4.52925 14.7876C4.5292 14.7876 4.5292 14.7876 4.52915 14.7875C1.69584 11.9749 1.67915 7.39791 4.49181 4.56461C4.50424 4.55213 4.51667 4.5397 4.52915 4.52727Z" fill="#717579"/>
									</svg>
								</Link>	
							</span>
						</div>
						<Dropdown className="default-dropdown">
							<Dropdown.Toggle as="div" className="btn i-false default-Select-btn me-0 me-sm-3 w-auto ms-3">{historyBtn}<i className="fa-solid fa-angle-down"></i></Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={()=>setHistoryBtn('Sort by Newest')}>Sort by Newest</Dropdown.Item>
								<Dropdown.Item onClick={()=>setHistoryBtn('Newest')}>Newest</Dropdown.Item>
								<Dropdown.Item onClick={()=>setHistoryBtn('Oldest')}>Oldest</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>
				<div className="card-body py-0">
					<div className="table-responsive">
						<div id="example2_guest" className="dataTables_wrapper no-footer">
							<table
								id="example2"
								className="table card-table  display mb-4 booking-table dataTablesCard dataTable no-footer"
							>
								<thead>
									<tr role="row">
										<th className="sorting_asc bg-none" >
											<div className="form-check  style-1">
												<input type="checkbox" onClick={() => chackboxFun("all")} className="form-check-input" id="checkAll" required=""/>
											</div>
										</th>
										<th>Room Name</th>
										<th>Bed Type</th>
										<th>Room Floor</th>
										<th>Room Facility</th>
										<th>Book Date</th>
										<th className="bg-none"></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="sorting_2">
											<div className="form-check  style-1">
												<input type="checkbox" onClick={() => chackboxFun()} 
													className="form-check-input" id="customCheckBox30" required=""
												/>
											</div>
										</td>
										<td>
											<div className="guest-bx">
												<img className="me-sm-4 me-2" src={pic1} alt="" />
												<div>
													<span className="text-primary fs-16 font-w400 text-nowrap">#0005</span>
													<h4 className="mb-0 mt-1 fs-18 font-w500 text-nowrap"><Link to={"#"} className="text-black">Queen A-0001</Link></h4>
												</div>
											</div>
										</td>
										<td>
											<div>
												<span className="fs-15 font-w500 text-nowrap">Single Bed</span>
											</div>
										</td>
										<td>
											<div>
												<span className="fs-15 font-w500 text-nowrap">Floor G-05</span>
											</div>
										</td>
										<td>
											<div>
												<span className="fs-15 font-w500 text-nowrap">AC, Shower, Double Bed, Towel, Bathup,<br />Coffee Set, LED TV, Wifi</span>
											</div>
										</td>
										<td>
											<div>
												<h5 className="fs-15 font-w500 text-nowrap">Oct 29th, 2020</h5>
												<span className="fs-14 font-w500 text-nowrap">08:29 AM</span>
											</div>
										</td>
										<td><DropdownBlog /></td>
									</tr>
									<tr>
										<td className="sorting_2">
											<div className="form-check  style-1">
												<input type="checkbox" onClick={() => chackboxFun()} 
													className="form-check-input" id="customCheckBox32" required=""
												/>
											</div>
										</td>
										<td>
											<div className="guest-bx">
												<Carousel className="carousel slide me-sm-4 me-2"
													indicators = {false}
													prevIcon = <i className="fas fa-chevron-left text-black"></i>																
													nextIcon = <i className="fas fa-chevron-right text-black"></i>
												>
													{carousel1.map((carousel, i) => (
													  <Carousel.Item key={i}>
														<img
														  src={carousel}
														  className='d-block w-100'
														  alt={`Slide ${i + 1}`}
														/>
													  </Carousel.Item>
													))}
												</Carousel>
												
												<div>
													<span className="text-primary fs-16 font-w400 text-nowrap">#0002</span>
													<h4 className="mb-0 mt-1 fs-18 font-w500 text-nowrap"><Link to={"#"} className="text-black">Deluxe B-0004</Link></h4>
												</div>
											</div>
										</td>
										<td>
											<div>
												<span className="fs-15 font-w500 text-nowrap">Single Bed</span>
											</div>
										</td>
										<td>
											<div>
												<span className="fs-15 font-w500 text-nowrap">Floor G-05</span>
											</div>
										</td>
										<td>
											<div>
												<span className="fs-15 font-w500 text-nowrap">AC, Shower, Double Bed, Towel, Bathup,<br />Coffee Set, LED TV, Wifi</span>
											</div>
										</td>
										<td>
											<div>
												<h5 className="fs-15 font-w500 text-nowrap">Oct 29th, 2020</h5>
												<span className="fs-14 font-w500 text-nowrap">08:29 AM</span>
											</div>
										</td>
										<td><DropdownBlog /></td>
									</tr>
									<tr>
										<td className="sorting_2">
											<div className="form-check  style-1">
												<input type="checkbox" onClick={() => chackboxFun()} 
													className="form-check-input" id="customCheckBox33" required=""
												/>
											</div>
										</td>
										<td>
											<div className="guest-bx">
												<img className="me-sm-4 me-2" src={pic2} alt="" />
												<div>
													<span className="text-primary fs-16 font-w400 text-nowrap">#0005</span>
													<h4 className="mb-0 mt-1 fs-18 font-w500 text-nowrap"><Link to={"#"} className="text-black">Queen A-0001</Link></h4>
												</div>
											</div>
										</td>
										<td>
											<div>
												<span className="fs-15 font-w500 text-nowrap">Single Bed</span>
											</div>
										</td>
										<td>
											<div>
												<span className="fs-15 font-w500 text-nowrap">Floor G-05</span>
											</div>
										</td>
										<td>
											<div>
												<span className="fs-15 font-w500 text-nowrap">AC, Shower, Double Bed, Towel, Bathup,<br />Coffee Set, LED TV, Wifi</span>
											</div>
										</td>
										<td>
											<div>
												<h5 className="fs-15 font-w500 text-nowrap">Oct 29th, 2020</h5>
												<span className="fs-14 font-w500 text-nowrap">08:29 AM</span>
											</div>
										</td>
										<td><DropdownBlog /></td>
									</tr>											
								</tbody>
							</table>
							<div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
								<div className="dataTables_info">
									Showing {activePag.current * sort + 1} to{" "}
									{data.length > (activePag.current + 1) * sort
										? (activePag.current + 1) * sort
										: data.length}{" "}
									of {data.length} entries
								</div>
								<div
									className="dataTables_paginate paging_simple_numbers mb-0"
									id="example2_paginate"
								>
									<Link
										className="paginate_button previous disabled"
										to="/guest-details"
										onClick={() =>
										   activePag.current > 0 &&
										   onClick(activePag.current - 1)
										}
									 >
										{/* <i className="fa fa-angle-double-left" aria-hidden="true"></i> */}
										Previous
									</Link>
									<span>
										{paggination.map((number, i) => (
										   <Link
											  key={i}
											  to="/guest-details"
											  className={`paginate_button  ${
												 activePag.current === i ? "current" : ""
											  } `}
											  onClick={() => onClick(i)}
										   >
											  {number}
										   </Link>
										))}
									</span>

									<Link
										className="paginate_button next"
										to="/guest-details"
										onClick={() =>
										   activePag.current + 1 < paggination.length &&
										   onClick(activePag.current + 1)
										}
									>
										{/* <i className="fa fa-angle-double-right" aria-hidden="true"></i> */}
										Next
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					
				</div>
			</div>
							
							
		</>
	)
}

export default PurchaseHistory;