import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import call from "utils/request";
import { Row, Col, Badge, Table } from "react-bootstrap";
import PaginationDetails from "../PaginationDetails";
import {
  capitalizeFirstChar,
  getFromForPagination,
  getToForPagination,
} from "utils/helper";
import { useDebounce } from "react-use";
import { get } from "lodash";
import moment from "moment-timezone";

function EventsList() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [paginationDetails, setPaginationDetails] = useState({});
  const userInfo = JSON.parse(sessionStorage.getItem("userDetails"));
  const permissions = userInfo?.permissions;

  const getEventsList = async (page = 1) => {
    setLoading(true);
    await call({
      method: "get",
      url: `/api/events/paginate?page=${page}`,
    }).then(({ data: { docs, ...rest } }) => {
      setEvents(docs);
      setPaginationDetails({
        ...rest,
        pageNumbers: Array.from(
          { length: rest.totalPages },
          (_, index) => index + 1
        ),
      });
      setLoading(false);
    });
  };

  const getEventsWithSearch = async (page = 1) => {
    await call({
      method: "get",
      url: `/api/events/paginate?searchText=${searchText || ""}&page=${page}`,
    }).then(({ data: { docs, ...rest } }) => {
      setEvents(docs);
      setPaginationDetails({
        ...rest,
        pageNumbers: Array.from(
          { length: rest.totalPages },
          (_, index) => index + 1
        ),
      });
    });
  };

  useEffect(() => {
    getEventsList();
  }, []);

  const onDelete = async (id) => {
    await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete the event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#F8857D",
      dangerMode: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await call({
          method: "delete",
          url: `/api/events/${id}`,
        }).then((res) => {
          if (res?.status === 204) {
            Swal.fire("Deleted", "Event has been deleted!", "success");
            setTimeout(() => getEventsList(), 500);
          }
        });
      }
    });
  };

  useDebounce(
    () => {
      getEventsWithSearch();
    },
    1000,
    [searchText]
  );

  if (loading) {
    return <div className="loader vh-100"></div>;
  }

  const getTableBody = () => {
    return events.map(
      (
        {
          _id: eventId,
          eventName,
          eventDateTime,
          location,
          zoneType,
          zoneTypeValue,
        },
        index
      ) => (
        <tr key={index}>
          <td>{eventName}</td>
          <td>{location}</td>
          <td>{moment(eventDateTime).format("DD-MM-YYYY hh:mm A")}</td>
          <td>
            <Badge>{capitalizeFirstChar(zoneType) || "-"}</Badge>
          </td>
          <td>{capitalizeFirstChar(zoneTypeValue) || "-"}</td>
          <td>
            {permissions?.EVENT?.read && (
              <Link
                to={`/events/${eventId}/view`}
                className="me-1 btn btn-info btn-sm"
              >
                <i className="fas fa-eye"></i>
              </Link>
            )}
            {permissions?.EVENT?.update && (
              <Link
                to={`/events/${eventId}/edit`}
                className="me-1 btn btn-secondary btn-sm"
              >
                <i className="fas fa-pencil-alt"></i>
              </Link>
            )}
            {permissions?.EVENT?.delete && (
              <button
                type="button"
                className="me-1 btn btn-primary btn-sm"
                onClick={() => onDelete(eventId)}
              >
                <i className="fa fa-trash"></i>
              </button>
            )}
          </td>
        </tr>
      )
    );
  };

  return (
    !loading && (
      <>
        <div className="row">
          <div className="d-flex justify-content-between mb-2">
            <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value || "")}
              />{" "}
              <span className="mx-2 w-100">{`Showing ${getFromForPagination(
                paginationDetails
              )} - ${getToForPagination(paginationDetails)} of ${get(
                paginationDetails,
                "totalDocs",
                0
              )} Entries`}</span>
            </div>
            {permissions?.EVENT?.create && (
              <Link
                to="/events/create"
                className="btn btn-primary mb-1 me-1 px-5 py-2"
              >
                Add
              </Link>
            )}
          </div>
          {!events?.length ? (
            <div className="d-flex align-items-center justify-content-center vh-100">
              <h2>No data</h2>
            </div>
          ) : (
            <>
              <Table responsive className="header-border ">
                <thead>
                  <tr>
                    <th>Event Name</th>
                    <th>Location</th>
                    <th>Date & Time</th>
                    <th>Zone Type</th>
                    <th>Zone Value</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>{getTableBody()}</tbody>
              </Table>
              <Row className="mt-2">
                <Col className="text-end ms-auto">
                  <PaginationDetails
                    paginationDetails={paginationDetails}
                    onClick={(page) => {
                      getEventsWithSearch(page);
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </div>
      </>
    )
  );
}

export default EventsList;
