import axios from "axios";
import { getUserInfo } from "./helper";

axios.defaults.maxContentLength = Infinity;

// const userInfo = getUserInfo();

// const headerAuth = {
//   Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
// };
/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default async function call(config = {}, headers = {}) {
  const userInfo = getUserInfo();

  const headerAuth = {
    Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
  };

  return await axios.request({
    // baseURL: serverBaseUrl,
    headers: headerAuth,
    ...config,
  });
}
