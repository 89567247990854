import React, {useState, useReducer} from 'react';
import {Link} from 'react-router-dom';
import {Nav, Tab, Dropdown} from 'react-bootstrap';

import ListData,{ListData2,ListData3} from './Room/ListData';
import BoxedData,{RoomData2, RoomData3} from './Room/BoxedData';

/* const initialState = {text: 'Sort by All'};
const reducer = (state, action) =>{
	switch (action.type){
		case 'all':
			return {text: "All"};	
		case 'active':
			return {text: "Active"};
		case 'inactive':
			return {text: "Inactive"};			
		default:
			return state	
	}	
} */

const Room = () =>{
	//const [optionBtn, setOptionBtn] = useState('Sort by All');
	const [tabboxed, setTabboxed] = useState("all");
	const [tabList, setTabList] = useState("listall");
	const [onList,setOnList] = useState(false);
	
		
	return(
		<>
			<Tab.Container defaultActiveKey="Boxed"> 
				<div className=" d-block d-lg-flex align-items-center justify-content-between mb-5">
					<div className="coin-tabs">
						{onList===true ? 
							<ul className="nav nav-tabs" id="ListViewTabLink">
								<li className="nav-item" onClick={() => setTabList("listall")}>
									<Link as="button" to={"#"} 
										className={`nav-link ${ tabList === "listall" ? "active" : ""}`} 									
									>
										All Status
									</Link>
								</li>
								<li className="nav-item" onClick={() => setTabList("listavailable")}>
									<Link as="button"  to={"#"}
										className={`nav-link ${ tabList === "listavailable" ? "active" : ""}`} 
									>Available</Link>
								</li>
								<li className="nav-item" onClick={() => setTabList("listsold")}>
									<Link as="button"  to={"#"}
										className={`nav-link ${ tabList === "listsold" ? "active" : ""}`} 
									>Soldout</Link>
								</li>
							</ul>
						: 
							<ul as="ul" className="nav nav-tabs" id="BoxedViewTabLink">
								<li className="nav-item" onClick={() => setTabboxed("all")}>
									<Link as="button"  to={"#"}
										className={`nav-link ${ tabboxed === "all" ? "active" : ""}`} 									
									>
										All Status
									</Link>
								</li>
								<li className="nav-item" onClick={() => setTabboxed("available")}>
									<Link as="button"  to={"#"}
										className={`nav-link ${ tabboxed === "available" ? "active" : ""}`} 
									>Available</Link>
								</li>
								<li className="nav-item" onClick={() => setTabboxed("sold")}>
									<Link as="button" to={"#"}
										className={`nav-link ${ tabboxed === "sold" ? "active" : ""}`} 
									>Soldout</Link>
								</li>
							</ul>
						}	
					</div>
					<div className="form-head d-inline-block d-sm-flex align-items-center mt-2 mt-lg-0 ">
						<select className="form-control style-1 border w-auto">
							<option>Sort by Newest</option>
							<option>Oldest</option>
							<option>Newest</option>
						</select> 
						{ /* <Dropdown className="default-dropdown">
							<Dropdown.Toggle as="div" className="btn i-false default-Select-btn me-3 ms-0">{optionBtn}<i className="fa-solid fa-angle-down"></i></Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={()=>setOptionBtn('Sort by All')} >Sort by All</Dropdown.Item>
								<Dropdown.Item onClick={()=>setOptionBtn('Active')} >Active</Dropdown.Item>
								<Dropdown.Item onClick={()=>setOptionBtn('Inactive')} >Inactive</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>  */}
						{/* <Dropdown className="default-dropdown">
							<Dropdown.Toggle as="div" className="btn i-false default-Select-btn me-3 ms-0">{state.text}<i className="fa-solid fa-angle-down"></i></Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => dispatch({type:'all'})}>Sort by All</Dropdown.Item>
								<Dropdown.Item onClick={() => dispatch({type:'active'})}>Active</Dropdown.Item>
								<Dropdown.Item onClick={() => dispatch({type:'inactive'})}>Inactive</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown> */}
						<Nav as="ul" className="grid-tabs nav nav-tabs border-0 mt-sm-0 mt-2">
							<Nav.Item as="li" >
								<Nav.Link className="tab-class" as="button" id="ListViewTab" eventKey="List"
									onClick={()=>setOnList(true)}	
								>
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M21.7908 3.01252H8.23302C7.01521 3.01252 6.02441 4.00331 6.02441 5.22263C6.02441 6.44044 7.01521 7.43123 8.23302 7.43123H21.7908C23.0086 7.43123 23.9994 6.44044 23.9994 5.22263C23.9994 4.00327 23.0086 3.01252 21.7908 3.01252Z" fill="#C1C1C1"/>
										<path d="M2.20941 3.01252C0.991172 3.01252 0 4.00364 0 5.22188C0 6.44011 0.991172 7.43123 2.20941 7.43123C3.42764 7.43123 4.41877 6.44011 4.41877 5.22188C4.41877 4.00364 3.42764 3.01252 2.20941 3.01252Z" fill="#C1C1C1"/>
										<path d="M2.20941 9.79064C0.991172 9.79064 0 10.7818 0 12C0 13.2182 0.991172 14.2094 2.20941 14.2094C3.42764 14.2094 4.41877 13.2182 4.41877 12C4.41877 10.7818 3.42764 9.79064 2.20941 9.79064Z" fill="#C1C1C1"/>
										<path d="M2.20941 16.5688C0.991172 16.5688 0 17.5599 0 18.7781C0 19.9964 0.991172 20.9875 2.20941 20.9875C3.42764 20.9875 4.41877 19.9964 4.41877 18.7781C4.41877 17.5599 3.42764 16.5688 2.20941 16.5688Z" fill="#C1C1C1"/>
										<path d="M21.7908 9.79064H8.23302C7.01521 9.79064 6.02441 10.7814 6.02441 12.0007C6.02441 13.2186 7.01521 14.2094 8.23302 14.2094H21.7908C23.0086 14.2094 23.9994 13.2186 23.9994 12.0007C23.9994 10.7814 23.0086 9.79064 21.7908 9.79064Z" fill="#C1C1C1"/>
										<path d="M21.7908 16.5688H8.23302C7.01521 16.5688 6.02441 17.5596 6.02441 18.7789C6.02441 19.9967 7.01521 20.9875 8.23302 20.9875H21.7908C23.0086 20.9875 23.9994 19.9967 23.9994 18.7789C23.9994 17.5596 23.0086 16.5688 21.7908 16.5688Z" fill="#C1C1C1"/>
									</svg>
								</Nav.Link>
							</Nav.Item>
							<Nav.Item as="li" >
								<Nav.Link className="tab-class" as="button" eventKey="Boxed" id="BoxedTab"
									onClick={()=>setOnList(false)}
								>
									<svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M8 1H4C2.34315 1 1 2.34315 1 4V8C1 9.65685 2.34315 11 4 11H8C9.65685 11 11 9.65685 11 8V4C11 2.34315 9.65685 1 8 1Z" fill="#F66F4D"/>
										<path d="M20 1H16C14.3431 1 13 2.34315 13 4V8C13 9.65685 14.3431 11 16 11H20C21.6569 11 23 9.65685 23 8V4C23 2.34315 21.6569 1 20 1Z" fill="#F66F4D"/>
										<path d="M8 13H4C2.34315 13 1 14.3431 1 16V20C1 21.6569 2.34315 23 4 23H8C9.65685 23 11 21.6569 11 20V16C11 14.3431 9.65685 13 8 13Z" fill="#F66F4D"/>
										<path d="M20 13H16C14.3431 13 13 14.3431 13 16V20C13 21.6569 14.3431 23 16 23H20C21.6569 23 23 21.6569 23 20V16C23 14.3431 21.6569 13 20 13Z" fill="#F66F4D"/>
									</svg>
								</Nav.Link>
							</Nav.Item>
						</Nav>
						<Link to={"#"} className="btn btn-primary ms-sm-3 ms-0 mt-2 mt-sm-0">Add New Room</Link>
					</div>
				</div>
				<Tab.Content id="nav-tabContent">	
					<Tab.Pane eventKey="List"> 
						<div className="tab-content" id="ListViewTabLinkContent">
							<div className={`tab-pane fade ${ tabList === "listall" ? "active show" : "" }`} >	
								<ListData />
							</div>
							<div className={`tab-pane fade ${ tabList === "listavailable" ? "active show" : "" }`} >	
								<ListData2 />
							</div>
							<div className={`tab-pane fade ${ tabList === "listsold" ? "active show" : "" }`} >	
								<ListData3 />
							</div>
						</div>		
							
					</Tab.Pane>	
					<Tab.Pane eventKey="Boxed">	
						<div className="tab-content" id="BoxedViewTabLinkContent">
							<div  className={`tab-pane fade ${ tabboxed === "all" ? "active show" : "" }`} >
								<BoxedData />
							</div>
							<div  className={`tab-pane fade ${ tabboxed === "available" ? "active show" : "" }`} >
								<RoomData2 />
							</div>
							<div  className={`tab-pane fade ${ tabboxed === "sold" ? "active show" : "" }`} >
								<RoomData3 />
							</div>
						</div>							
					</Tab.Pane>	
				</Tab.Content>	
			</Tab.Container>
		</>
	)
}
export default Room;