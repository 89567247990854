import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const PaymentStatus = () => {
  const history = useHistory();
  const location = useLocation();
  const { status } = location.state;
  const [loading, setLoading] = useState(false);

  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      } else {
        history.push('/payments');
        clearInterval(timer);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [countdown, history]);

  if (loading) {
    return <div className="loader vh-100"></div>;
  }

  return (
    <>
      {!loading && (
        <>
          {status === 'Success' ? (
            <>
              <div className="row">
                <div className="col card mx-3">
                  <div className="card-body px-3">
                    <div className="d-flex flex-column my-5 align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="220.17"
                        height="215.858"
                        viewBox="0 0 220.17 215.858"
                      >
                        <g
                          id="Group_38069"
                          data-name="Group 38069"
                          transform="translate(-848.671 -303.142)"
                        >
                          <g
                            id="Group_37982"
                            data-name="Group 37982"
                            transform="translate(-3.363 -9)"
                          >
                            <circle
                              id="Ellipse_21417"
                              data-name="Ellipse 21417"
                              cx="56.5"
                              cy="56.5"
                              r="56.5"
                              transform="translate(907.363 366)"
                              fill="#16be01"
                            />
                            <path
                              id="Path_68853"
                              data-name="Path 68853"
                              d="M562.728,13845.56l16,16.381,27.8-28.159"
                              transform="translate(379.234 -13426.008)"
                              fill="none"
                              stroke="#fff"
                              stroke-width="9"
                            />
                          </g>
                          <circle
                            id="Ellipse_21418"
                            data-name="Ellipse 21418"
                            cx="5.715"
                            cy="5.715"
                            r="5.715"
                            transform="translate(1057.412 414.612)"
                            fill="#7849e5"
                          />
                          <circle
                            id="Ellipse_21419"
                            data-name="Ellipse 21419"
                            cx="6.852"
                            cy="6.852"
                            r="6.852"
                            transform="translate(934.436 303.142)"
                            fill="#1088ff"
                            opacity="0.413"
                          />
                          <circle
                            id="Ellipse_21420"
                            data-name="Ellipse 21420"
                            cx="3.077"
                            cy="3.077"
                            r="3.077"
                            transform="translate(957.209 321.627)"
                            fill="#16be00"
                          />
                          <circle
                            id="Ellipse_21421"
                            data-name="Ellipse 21421"
                            cx="6.594"
                            cy="6.594"
                            r="6.594"
                            transform="translate(882.049 366.333)"
                            fill="#f40354"
                          />
                          <circle
                            id="Ellipse_21422"
                            data-name="Ellipse 21422"
                            cx="3.767"
                            cy="3.767"
                            r="3.767"
                            transform="translate(906.363 475.542)"
                            fill="#7849e5"
                            opacity="0.413"
                          />
                          <g
                            id="Ellipse_21423"
                            data-name="Ellipse 21423"
                            transform="translate(943.602 503.175)"
                            fill="none"
                            stroke="#1088ff"
                            stroke-width="4"
                          >
                            <circle
                              cx="7.912"
                              cy="7.912"
                              r="7.912"
                              stroke="none"
                            />
                            <circle
                              cx="7.912"
                              cy="7.912"
                              r="5.912"
                              fill="none"
                            />
                          </g>
                          <circle
                            id="Ellipse_21424"
                            data-name="Ellipse 21424"
                            cx="3.517"
                            cy="3.517"
                            r="3.517"
                            transform="translate(961.042 496.142)"
                            fill="#b6d7f7"
                          />
                          <circle
                            id="Ellipse_21425"
                            data-name="Ellipse 21425"
                            cx="2.517"
                            cy="2.517"
                            r="2.517"
                            transform="translate(1050.881 400.669)"
                            fill="#1088ff"
                          />
                          <path
                            id="Path_68854"
                            data-name="Path 68854"
                            d="M525.292,13899.342s14.775,2.783,15.843,20.6"
                            transform="translate(487.306 -13429.342)"
                            fill="none"
                            stroke="#7849e5"
                            stroke-linecap="round"
                            stroke-width="4"
                          />
                          <path
                            id="Path_68855"
                            data-name="Path 68855"
                            d="M322,13864.766s15.275,4.559,19.86-9.443-6.779-14.164-8.488-9.486,1.733,11.629,12.956,9.486c6.823-1.445,10.155-7.625,10.155-7.625"
                            transform="translate(529.159 -13415.479)"
                            fill="none"
                            stroke="#1088ff"
                            stroke-linecap="round"
                            stroke-width="4"
                          />
                          <path
                            id="Path_68856"
                            data-name="Path 68856"
                            d="M537.559,13744.749s14.361,1.289,17.971-12.325,5.813-15.1,10.5-16.861"
                            transform="translate(490.882 -13387.781)"
                            fill="none"
                            stroke="#7849e5"
                            stroke-linecap="round"
                            stroke-width="4"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="d-flex flex-column my-3">
                      <div
                        className="col fw-bolder text-success text-center mb-2"
                        style={{
                          fontSize: '1.5rem',
                        }}
                      >
                        Payment Successful
                      </div>
                    </div>
                    <p className="text-center">Your Payment was Successful</p>
                    <p className="text-blue text-center">
                      Redirecting in few seconds...
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col card mx-3">
                  <div className="card-body px-3">
                    <div className="d-flex flex-column my-5 align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="121"
                        height="121"
                        viewBox="0 0 121 121"
                      >
                        <g
                          id="Group_38070"
                          data-name="Group 38070"
                          transform="translate(-900 -357)"
                        >
                          <g
                            id="Group_37983"
                            data-name="Group 37983"
                            transform="translate(-3.896 -6.896)"
                          >
                            <circle
                              id="Ellipse_21417"
                              data-name="Ellipse 21417"
                              cx="60.5"
                              cy="60.5"
                              r="60.5"
                              transform="translate(903.896 363.896)"
                              fill="#f40303"
                            />
                            <path
                              id="Path_68857"
                              data-name="Path 68857"
                              d="M2940.2,13830.083v33.4"
                              transform="translate(-1975.057 -13433.927)"
                              fill="none"
                              stroke="#fff"
                              stroke-width="10"
                            />
                            <path
                              id="Path_68858"
                              data-name="Path 68858"
                              d="M2940.2,13830.083v11.287"
                              transform="translate(-1975.057 -13391.649)"
                              fill="none"
                              stroke="#fff"
                              stroke-width="10"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="d-flex flex-column my-3">
                      <div
                        className="col fw-bolder text-danger text-center mb-2"
                        style={{
                          fontSize: '1.5rem',
                        }}
                      >
                        Payment Unsuccessful
                      </div>
                    </div>
                    <p className="text-center">Your Payment was Unsuccessful</p>
                    <p className="text-blue text-center">
                      Redirecting in {countdown} seconds...
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PaymentStatus;
