import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import logo from "../../images/logo-full.png";
import { registerUser } from "store/slices/AuthSlice";
import { useDispatch, useSelector } from "react-redux";

function Register(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const dispatch = useDispatch();
  const registerApiStatus = useSelector((state) => state.auth.register.status);
  const onSignUp = (data) => {
    dispatch(registerUser(data));
  };

  return (
    <div
      className={`authincation h-100 p-meddle ${
        registerApiStatus === "loading" && "loader"
      }`}
    >
      <Container className="h-100">
        <Row className="justify-content-center h-100 align-items-center">
          <Col md={6}>
            <div className="authincation-content">
              <Row>
                <Col xl={12}>
                  <div className="auth-form">
                    <div className="text-center mb-4">
                      <Link
                        to="/login"
                        className="d-flex justify-content-center align-items-center"
                      >
                        <Image
                          src={logo}
                          alt=""
                          width={50}
                          height={50}
                          className="mx-2"
                        />
                        <h2 className="mx-2 mb-0">Sanskriti Utsav</h2>
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Sign up your account</h4>
                    {props.errorMessage && (
                      <div className="">{props.errorMessage}</div>
                    )}
                    {props.successMessage && (
                      <div className="">{props.successMessage}</div>
                    )}
                    <Form onSubmit={handleSubmit(onSignUp)}>
                      {
                        <>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              <strong>Name</strong>
                            </Form.Label>
                            <Form.Control
                              {...register("name", {
                                required: "Name is required",
                                maxLength: 255,
                              })}
                              type="text"
                              placeholder="Name"
                            />
                            {errors.name && (
                              <Form.Text className="text-danger">
                                {errors.name.message}
                              </Form.Text>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              <strong>Email</strong>
                            </Form.Label>
                            <Form.Control
                              {...register("email", {
                                required: "Email is required",
                                pattern: {
                                  value: /^\S+@\S+$/i,
                                  message: "Invalid email address",
                                },
                              })}
                              placeholder="Email"
                            />
                            {errors.email && (
                              <Form.Text className="text-danger">
                                {errors.email.message}
                              </Form.Text>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              <strong>Password</strong>
                            </Form.Label>
                            <Form.Control
                              {...register("password", {
                                required: "Password is required",
                                minLength: {
                                  value: 8,
                                  message:
                                    "Password must be at least 8 characters long",
                                },
                              })}
                              placeholder="Password"
                              type="Password"
                            />
                            {errors.password && (
                              <Form.Text className="text-danger">
                                {errors.password.message}
                              </Form.Text>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              <strong>Confirm Password</strong>
                            </Form.Label>
                            <Form.Control
                              {...register("confirmPassword", {
                                required: "Confirm Password is required",
                                validate: (value) =>
                                  value === watch("password") ||
                                  "Passwords do not match",
                              })}
                              placeholder="Confirm password"
                              type="Password"
                            />
                            {errors.confirmPassword && (
                              <Form.Text className="text-danger">
                                {errors.confirmPassword.message}
                              </Form.Text>
                            )}
                          </Form.Group>
                          <div className="text-center mt-4">
                            <Button
                              type="submit"
                              className="btn btn-primary btn-block"
                            >
                              Sign me up
                            </Button>
                          </div>
                        </>
                      }
                    </Form>
                    <div className="new-account mt-3">
                      <p className="">
                        Already have an account?{" "}
                        <Link className="text-primary" to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Register;
