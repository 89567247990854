/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
import { Dropdown, Image } from "react-bootstrap";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import LogoutPage from "./Logout";
import profile from "../../../images/avatar/10.png";
import { getUserInfo } from "utils/helper";
import logo from "../../../images/sanskriti-utsav-logo.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = ({ disabled }) => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  //console.log(sidebarposition);
  //console.log(sidebarLayout);
  const userInfo = getUserInfo();
  const permissions = userInfo?.permissions;
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    // btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    // var handleheartBlast = document.querySelector('.heart');
    // function heartBlast() {
    //   return handleheartBlast.classList.toggle('heart-blast');
    // }
    // handleheartBlast.addEventListener('click', heartBlast);
  }, []);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  // let deshBoard = [
  //   '',
  //   'dashboard-dark',
  //   'guest-list',
  //   'guest-details',
  //   'concierge',
  //   'room',
  //   'task',
  // ];
  // app = [
  //   'app-profile',
  //   'post-details',
  //   'app-calender',
  //   'email-compose',
  //   'email-inbox',
  //   'email-read',
  //   'ecom-product-grid',
  //   'ecom-product-list',
  //   'ecom-product-order',
  //   'ecom-checkout',
  //   'ecom-invoice',
  //   'ecom-customers',
  //   'post-details',
  //   'ecom-product-detail',
  // ],
  // email = ['email-compose', 'email-inbox', 'email-read'],
  // shop = [
  //   'ecom-product-grid',
  //   'ecom-product-list',
  //   'ecom-product-list',
  //   'ecom-product-order',
  //   'ecom-checkout',
  //   'ecom-invoice',
  //   'ecom-customers',
  //   'ecom-product-detail',
  // ],
  // charts = [
  //   'chart-rechart',
  //   'chart-flot',
  //   'chart-chartjs',
  //   'chart-chartist',
  //   'chart-sparkline',
  //   'chart-apexchart',
  // ],
  // bootstrap = [
  //   'ui-accordion',
  //   'ui-badge',
  //   'ui-alert',
  //   'ui-button',
  //   'ui-modal',
  //   'ui-button-group',
  //   'ui-list-group',
  //   'ui-card',
  //   'ui-carousel',
  //   'ui-dropdown',
  //   'ui-popover',
  //   'ui-progressbar',
  //   'ui-tab',
  //   'ui-typography',
  //   'ui-pagination',
  //   'ui-grid',
  // ],
  // plugins = [
  //   'uc-select2',
  //   'uc-nestable',
  //   'uc-sweetalert',
  //   'uc-toastr',
  //   'uc-noui-slider',
  //   'map-jqvmap',
  //   'uc-lightgallery',
  // ],
  // redux = ['redux-form', 'redux-wizard', 'todo'],
  // widget = ['widget-basic'],
  // forms = [
  //   'form-element',
  //   'form-wizard',
  //   'form-ckeditor',
  //   'form-pickers',
  //   'form-validation-jquery',
  // ],
  // table = ['table-bootstrap-basic', 'table-datatable-basic'],
  // pages = [
  //   'page-register',
  //   'page-login',
  //   'page-lock-screen',
  //   'page-error-400',
  //   'page-error-403',
  //   'page-error-404',
  //   'page-error-500',
  //   'page-error-503',
  // ],
  // error = [
  //   'page-error-400',
  //   'page-error-403',
  //   'page-error-404',
  //   'page-error-500',
  //   'page-error-503',
  // ];

  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <Dropdown className="dropdown header-bx">
          <Dropdown.Toggle
            variant=""
            as="a"
            className="nav-link i-false c-pointer header-profile2 position-relative"
          >
            <div className="position-relative">
              {/*<img src={profile} width={20} alt="" />
              <svg
                className="header-circle"
                width="130"
                height="130"
                viewBox="0 0 130 130"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M130 65C130 100.899 100.899 130 65 130C29.1015 130 0 100.899 0 65C0 29.1015 29.1015 0 65 0C100.899 0 130 29.1015 130 65ZM4.99306 65C4.99306 98.1409 31.8591 125.007 65 125.007C98.1409 125.007 125.007 98.1409 125.007 65C125.007 31.8591 98.1409 4.99306 65 4.99306C31.8591 4.99306 4.99306 31.8591 4.99306 65Z"
                  fill="#FFD482"
                />
                <path
                  d="M65 2.49653C65 1.11774 66.1182 -0.00500592 67.496 0.0479365C76.3746 0.389105 85.0984 2.54751 93.1247 6.39966C101.902 10.6123 109.621 16.7428 115.711 24.3385C121.802 31.9341 126.108 40.8009 128.312 50.284C130.516 59.7671 130.562 69.6242 128.446 79.1274C126.33 88.6305 122.106 97.5369 116.087 105.189C110.067 112.841 102.406 119.043 93.6677 123.337C84.9299 127.631 75.3391 129.907 65.6037 129.997C56.7012 130.08 47.8858 128.333 39.7012 124.875C38.4312 124.338 37.895 122.847 38.48 121.598C39.065 120.35 40.5495 119.817 41.8213 120.35C49.3273 123.493 57.4027 125.08 65.5573 125.004C74.5449 124.921 83.399 122.819 91.4656 118.855C99.5322 114.891 106.605 109.166 112.162 102.102C117.72 95.0375 121.619 86.8153 123.572 78.0421C125.526 69.269 125.484 60.1691 123.449 51.4145C121.414 42.6598 117.438 34.4741 111.816 27.4619C106.193 20.4497 99.0674 14.7901 90.9643 10.9011C83.6123 7.3726 75.6263 5.38343 67.4958 5.04499C66.1182 4.98764 65 3.87533 65 2.49653Z"
                  fill="var(--primary)"
                />
              </svg> */}
              {/* <Image src={logo} width={130} height={130} /> */}
              <img src={logo} width={130} alt="" />
              {/* <div className="header-edit position-absolute">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.02526 12.5567L7.44727 16.9762L16.2481 8.17043L11.8261 3.75092L3.02526 12.5567Z"
                    fill="#fff"
                  />
                  <path
                    d="M19.6341 3.01762L16.9827 0.366211C16.7401 0.123594 16.4227 0.00160156 16.1051 0H16.0919C15.7743 0.00160156 15.4573 0.123594 15.2153 0.366211L13.4453 2.13383L17.8665 6.55262L19.6342 4.785C19.8768 4.54238 19.9988 4.22539 20.0004 3.90781V3.89461C19.9987 3.57719 19.8767 3.2602 19.6341 3.01762Z"
                    fill="#fff"
                  />
                  <path
                    d="M0 20L5.745 18.6738L1.32379 14.255L0 20Z"
                    fill="#fff"
                  />
                </svg>
              </div> */}
            </div>
          </Dropdown.Toggle>
          {/* <Dropdown.Menu
            align="right"
            className="dropdown-menu dropdown-menu-end"
          >
            <LogoutPage />
          </Dropdown.Menu> */}
          <div className="header-content ms-2">
            <h3 className="font-w500">{userInfo?.user?.name}</h3>
            <span className="font-w400 text-wrap">{userInfo?.user?.email}</span>
          </div>
        </Dropdown>
        <MM className="metismenu" id="menu">
          {/* <Link className="has-arrow" to="#">
              <i className="flaticon-025-dashboard"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
            <ul> */}
          {/* <li><Link className={`${path === "dashboard" ? "mm-active" : ""}`} to="/dashboard"> Dashboard Light</Link></li>
				  <li><Link className={`${path === "dashboard-dark" ? "mm-active" : ""}`} to="/dashboard-dark"> Dashboard Dark</Link></li>
				  <li><Link className={`${path === "guest-list" ? "mm-active" : ""}`} to="/guest-list">Guest List</Link></li>
				  <li><Link className={`${path === "guest-details" ? "mm-active" : ""}`} to="/guest-details">Guest Details</Link></li>
				  <li><Link className={`${path === "concierge" ? "mm-active" : ""}`} to="/concierge">Concierge</Link></li>
				  <li><Link className={`${path === "room" ? "mm-active" : ""}`} to="/room">Room</Link></li>
				  <li><Link className={`${path === "reviews" ? "mm-active" : ""}`} to="/reviews">Reviews</Link></li>
				  <li><Link className={`${path === "task" ? "mm-active" : ""}`} to="/task">Task</Link></li> */}
          {/* {!disabled && (
                <>
                  <li>
                    <Link
                      className={path === 'reports' ? 'mm-active' : ''}
                      to="/users"
                    >
                      Users
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={path === 'reports' ? 'mm-active' : ''}
                      to="/roles"
                    >
                      Role Management
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={path === 'properties' ? 'mm-active' : ''}
                      to="/properties"
                    >
                      Property List
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={path === 'tenant' ? 'mm-active' : ''}
                      to="/tenant"
                    >
                      Tenant Detail
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={path === 'coupons' ? 'mm-active' : ''}
                      to="/coupons"
                    >
                      Coupons
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={path === 'reports' ? 'mm-active' : ''}
                      to="/reports"
                    >
                      Reports
                    </Link>
                  </li>
                </>
              )}
              <li>
                <Link
                  className={path === 'payments' ? 'mm-active' : ''}
                  to="/payments"
                >
                  Payments
                </Link>
              </li>
            </ul> */}
          <li>
            <Link className={path === "" ? "text-primary" : ""} to="">
              <i
                className="fa-solid fa-home"
                style={{ width: "25px", height: "auto" }}
              ></i>{" "}
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          {permissions?.EVENT?.read && (
            <li>
              <Link
                className={path === "events" ? "text-primary" : ""}
                to="/events"
              >
                <i
                  className="fa-solid fa-calendar-alt"
                  style={{ width: "25px", height: "auto" }}
                ></i>{" "}
                <span className="nav-text">Events</span>
              </Link>
            </li>
          )}
          {permissions?.REGISTRATION?.read && (
            <li>
              <Link
                className={path === "registrations" ? "text-primary" : ""}
                to="/registrations"
              >
                <i
                  className="fa-solid fa-clipboard-check"
                  style={{ width: "25px", height: "auto" }}
                ></i>{" "}
                <span className="nav-text">Registrations</span>
              </Link>
            </li>
          )}
          {permissions?.USER?.read && (
            <li>
              <Link
                className={path === "users" ? "text-primary" : ""}
                to="/users"
              >
                <i
                  className="fa-solid fa-users"
                  style={{ width: "25px", height: "auto" }}
                ></i>{" "}
                <span className="nav-text">Users</span>
              </Link>
            </li>
          )}
          {!disabled && (
            <>
              {/* {permissions?.ROLE?.read && (
                <li>
                  <Link
                    className={path === "roles" ? "text-primary" : ""}
                    to="/roles"
                  >
                    <i
                      className="fa-solid fa-key"
                      style={{ width: "25px", height: "auto" }}
                    ></i>{" "}
                    <span className="nav-text">Role Management</span>
                  </Link>
                </li>
              )} */}
              {permissions?.PROPERTY?.read && (
                <li>
                  <Link
                    className={path === "properties" ? "text-primary" : ""}
                    to="/properties"
                  >
                    <i
                      className="fa-solid fa-building"
                      style={{ width: "25px", height: "auto" }}
                    ></i>{" "}
                    <span className="nav-text">Property List</span>
                  </Link>
                </li>
              )}
              {permissions?.TENANT?.read && (
                <li>
                  <Link
                    className={path === "tenant" ? "text-primary" : ""}
                    to="/tenant"
                  >
                    <i
                      className="fa-solid fa-user"
                      style={{ width: "25px", height: "auto" }}
                    ></i>{" "}
                    <span className="nav-text">Tenant Detail</span>
                  </Link>
                </li>
              )}
              {permissions?.COUPON?.read && (
                <li>
                  <Link
                    className={path === "coupons" ? "text-primary" : ""}
                    to="/coupons"
                  >
                    <i
                      className="fa-solid fa-tag"
                      style={{ width: "25px", height: "auto" }}
                    ></i>{" "}
                    <span className="nav-text">Coupons</span>
                  </Link>
                </li>
              )}
              {permissions?.REPORT?.read && (
                <li>
                  <Link
                    className={path === "reports" ? "text-primary" : ""}
                    to="/reports"
                  >
                    <i
                      className="fa-solid fa-file-invoice"
                      style={{ width: "25px", height: "auto" }}
                    ></i>{" "}
                    <span className="nav-text">Reports</span>
                  </Link>
                </li>
              )}
            </>
          )}
          {permissions?.PAYMENT?.read && (
            <li>
              <Link
                className={path === "payments" ? "text-primary" : ""}
                to="/payments"
              >
                <i
                  className="fa-solid fa-credit-card"
                  style={{ width: "25px", height: "auto" }}
                ></i>{" "}
                <span className="nav-text">Payments</span>
              </Link>
            </li>
          )}

          {/* <li className={`${app.includes(path) ? "mm-active" : ""}`}>
				<Link className="has-arrow ai-icon" to="#" >
				  <i className="flaticon-050-info"></i>
				  <span className="nav-text">Apps</span>
				</Link>
				<ul >
				  <li><Link className={`${path === "app-profile" ? "mm-active" : ""}`} to="/app-profile">Profile</Link></li>
				  <li><Link className={`${path === "post-details" ? "mm-active" : ""}`} to="/post-details">Post Details</Link></li>
				  <li className={`${email.includes(path) ? "mm-active" : ""}`}><Link className="has-arrow" to="#" >Email</Link>
					<ul  className={`${email.includes(path) ? "mm-show" : ""}`}>
					  <li><Link className={`${ path === "email-compose" ? "mm-active" : ""}`} to="/email-compose">Compose</Link></li>
					  <li><Link className={`${path === "email-inbox" ? "mm-active" : ""}`} to="/email-inbox">Inbox</Link></li>
					  <li><Link className={`${path === "email-read" ? "mm-active" : ""}`} to="/email-read">Read</Link></li>
					</ul>
				  </li>
				  <li><Link className={`${path === "app-calender" ? "mm-active" : ""}`}to="/app-calender">Calendar</Link></li>
				  <li className={`${shop.includes(path) ? "mm-active" : ""}`}><Link className="has-arrow" to="#" >Shop</Link>
					<ul  className={`${shop.includes(path) ? "mm-show" : ""}`}>
					  <li><Link className={`${ path === "ecom-product-grid" ? "mm-active" : ""}`} to="/ecom-product-grid">Product Grid</Link></li>
					  <li><Link className={`${ path === "ecom-product-list" ? "mm-active" : ""}`} to="/ecom-product-list">Product List</Link></li>
					  <li><Link className={`${ path === "ecom-product-detail" ? "mm-active" : "" }`} to="/ecom-product-detail">Product Details</Link></li>
					  <li><Link className={`${ path === "ecom-product-order" ? "mm-active" : "" }`} to="/ecom-product-order">Order</Link></li>
					  <li><Link className={`${ path === "ecom-checkout" ? "mm-active" : ""}`} to="/ecom-checkout">Checkout</Link></li>
					  <li><Link className={`${ path === "ecom-invoice" ? "mm-active" : "" }`} to="/ecom-invoice">Invoice</Link></li>
					  <li><Link className={`${ path === "ecom-customers" ? "mm-active" : "" }`} to="/ecom-customers">Customers</Link></li>
					</ul>
				  </li>
				</ul>
			  </li>
			  <li className={`${charts.includes(path) ? "mm-active" : ""}`}>
				<Link className="has-arrow ai-icon" to="#" >
				  <i className="flaticon-041-graph"></i>
				  <span className="nav-text">Charts</span>
				</Link>
				<ul >
				  <li><Link className={`${path === "chart-rechart" ? "mm-active" : ""}`} to="/chart-rechart">RechartJs</Link></li>
				  <li><Link className={`${path === "chart-chartjs" ? "mm-active" : ""}`} to="/chart-chartjs">Chartjs</Link></li>
				  <li><Link className={`${path === "chart-chartist" ? "mm-active" : ""}`} to="/chart-chartist">Chartist</Link></li>
				  <li><Link className={`${path === "chart-sparkline" ? "mm-active" : ""}`} to="/chart-sparkline">Sparkline</Link></li>
				  <li><Link className={`${path === "chart-apexchart" ? "mm-active" : ""}`} to="/chart-apexchart" >Apexchart</Link></li>
				</ul>
			  </li>
			  <li className={`${bootstrap.includes(path) ? "mm-active" : ""}`}>
				<Link className="has-arrow ai-icon" to="#" >
				  <i className="flaticon-086-star"></i>
				  <span className="nav-text">Bootstrap</span>
				</Link>
				<ul >
				  <li><Link className={`${path === "ui-accordion" ? "mm-active" : ""}`} to="/ui-accordion">Accordion</Link></li>
				  <li><Link className={`${path === "ui-alert" ? "mm-active" : ""}`} to="/ui-alert"> Alert</Link></li>
				  <li><Link className={`${path === "ui-badge" ? "mm-active" : ""}`} to="/ui-badge">Badge</Link></li>
				  <li><Link className={`${path === "ui-button" ? "mm-active" : ""}`} to="/ui-button">Button</Link></li>
				  <li><Link className={`${path === "ui-modal" ? "mm-active" : ""}`} to="/ui-modal">Modal</Link></li>
				  <li><Link className={`${path === "ui-button-group" ? "mm-active" : ""}`} to="/ui-button-group">Button Group</Link></li>
				  <li><Link className={`${path === "ui-list-group" ? "mm-active" : ""}`} to="/ui-list-group" >List Group</Link></li>
				  <li><Link className={`${path === "ui-card" ? "mm-active" : ""}`} to="/ui-card">Cards</Link></li>
				  <li><Link className={`${path === "ui-carousel" ? "mm-active" : ""}`} to="/ui-carousel">Carousel</Link></li>
				  <li><Link className={`${path === "ui-dropdown" ? "mm-active" : ""}`} to="/ui-dropdown">Dropdown</Link></li>
				  <li><Link className={`${path === "ui-popover" ? "mm-active" : ""}`} to="/ui-popover">Popover</Link></li>
				  <li><Link className={`${path === "ui-progressbar" ? "mm-active" : ""}`} to="/ui-progressbar">Progressbar</Link></li>
				  <li><Link className={`${path === "ui-tab" ? "mm-active" : ""}`} to="/ui-tab">Tab</Link></li>
				  <li><Link className={`${path === "ui-typography" ? "mm-active" : ""}`} to="/ui-typography">Typography</Link></li>
				  <li><Link className={`${path === "ui-pagination" ? "mm-active" : ""}`} to="/ui-pagination">Pagination</Link></li>
				  <li><Link className={`${path === "ui-grid" ? "mm-active" : ""}`} to="/ui-grid">Grid</Link></li>
				</ul>
			  </li>
				<li className={`${plugins.includes(path) ? "mm-active" : ""}`}>
					<Link className="has-arrow ai-icon" to="#" >
						<i className="flaticon-045-heart"></i><span className="nav-text">Plugins</span>
					</Link>
					<ul >
					  <li><Link className={`${path === "uc-select2" ? "mm-active" : ""}`} to="/uc-select2">Select 2</Link></li>
					  <li><Link className={`${path === "uc-noui-slider" ? "mm-active" : ""}`} to="/uc-noui-slider">Noui Slider</Link></li>
					  <li><Link className={`${path === "uc-sweetalert" ? "mm-active" : ""}`} to="/uc-sweetalert">Sweet Alert</Link></li>
					  <li><Link className={`${path === "uc-toastr" ? "mm-active" : ""}`} to="/uc-toastr">Toastr</Link></li>
					  <li><Link className={`${path === "map-jqvmap" ? "mm-active" : ""}`} to="/map-jqvmap">Jqv Map</Link></li>
					  <li><Link className={`${path === "uc-lightgallery" ? "mm-active" : ""}`} to="/uc-lightgallery">Light Gallery</Link></li>
					</ul>
				</li>
				<li className={`${redux.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow ai-icon" to="#" >
							<i className="flaticon-087-stop"></i><span className="nav-text">Redux</span>
						</Link>
					<ul>
					  <li><Link className={`${path === "todo" ? "mm-active" : ""}`} to="/todo">Todo</Link></li>
					  
					</ul>
				</li>
			  <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
				<Link to="widget-basic" className="ai-icon" >
				  <i className="flaticon-013-checkmark"></i>
				  <span className="nav-text">Widget</span>
				</Link>
			  </li>
			  <li className={`${forms.includes(path) ? "mm-active" : ""}`}>
				<Link className="has-arrow ai-icon" to="#" >
				  <i className="flaticon-072-printer"></i>
				  <span className="nav-text forms">Forms</span>
				</Link>
				<ul >
					<li><Link className={`${path === "form-element" ? "mm-active" : ""}`} to="/form-element">Form Elements</Link></li>
					<li><Link className={`${path === "form-wizard" ? "mm-active" : ""}`} to="/form-wizard"> Wizard</Link></li>
					<li><Link className={`${path === "form-ckeditor" ? "mm-active" : ""}`}to="/form-ckeditor">CkEditor</Link></li>
					<li><Link className={`${path === "form-pickers" ? "mm-active" : ""}`} to="/form-pickers">Pickers</Link></li>
					<li>
						<Link className={`${path === "form-validation-jquery" ? "mm-active" : ""}`} to="/form-validation-jquery">Form Validate</Link>
					</li>
				</ul>
			  </li>
			  <li className={`${table.includes(path) ? "mm-active" : ""}`}>
				<Link className="has-arrow ai-icon" to="#" ><i className="flaticon-043-menu"></i><span className="nav-text">Table</span></Link>
				<ul>
					<li>
						<Link className={`${ path === "table-filtering" ? "mm-active" : "" }`} to="/table-filtering">
							Table Filtering
						</Link>
					</li>
					<li>
						<Link className={`${ path === "table-sorting" ? "mm-active" : "" }`} to="/table-sorting">
							Table Sorting
						</Link>
					</li>
					<li>
						<Link className={`${ path === "table-bootstrap-basic" ? "mm-active" : "" }`} to="/table-bootstrap-basic">
							Bootstrap
						</Link>
					</li>
					<li>
						<Link className={`${ path === "table-datatable-basic" ? "mm-active" : ""}`} to="/table-datatable-basic">
							Datatable
						</Link>
					</li>
				</ul>
			  </li>
			  <li className={`${pages.includes(path) ? "mm-active" : ""}`}>
				<Link className="has-arrow ai-icon" to="#" >
				  <i className="flaticon-022-copy"></i>
				  <span className="nav-text">Pages</span>
				</Link>
				<ul >
				  <li className={`${error.includes(path) ? "mm-active" : ""}`}>
					<Link className="has-arrow" to="#" >Error</Link>
					<ul>
					  <li><Link className={`${ path === "page-error-400" ? "mm-active" : "" }`} to="/page-error-400">Error 400</Link></li>
					  <li><Link className={`${ path === "page-error-403" ? "mm-active" : "" }`} to="/page-error-403">Error 403</Link></li>
					  <li><Link className={`${ path === "page-error-404" ? "mm-active" : "" }`} to="/page-error-404">Error 404</Link></li>
					  <li><Link className={`${ path === "page-error-500" ? "mm-active" : "" }`} to="/page-error-500">Error 500</Link></li>
					  <li><Link className={`${ path === "page-error-503" ? "mm-active" : "" }`} to="/page-error-503">Error 503</Link></li>
					</ul>
				  </li>
				  <li><Link className={`${path === "page-lock-screen" ? "mm-active" : ""}`} to="/page-lock-screen">Lock Screen</Link></li>
				</ul>
			  </li> */}
        </MM>

        <div className="d-flex flex-column copyright-side-bar text-center justify-content-end">
          {/* <h6>InnSwitch Admin </h6> */}
          {/* <span className="fs-14 font-w400">© 2022 All Rights Reserved</span> */}
          {/* <p className="fs-12 mb-4">
            Made with <span className="heart"></span> by DexignLab
          </p> */}
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
