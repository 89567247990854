import React from 'react';
import {Dropdown} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import roomgrid1 from './../../../../images/roomgrid-img/pic-1.jpg';
import roomgrid2 from './../../../../images/roomgrid-img/pic-2.jpg';
import roomgrid3 from './../../../../images/roomgrid-img/pic-3.jpg';
import roomgrid4 from './../../../../images/roomgrid-img/pic-4.jpg';
import roomgrid5 from './../../../../images/roomgrid-img/pic-5.jpg';
import roomgrid6 from './../../../../images/roomgrid-img/pic-6.jpg';
import roomgrid7 from './../../../../images/roomgrid-img/pic-7.jpg';
import roomgrid8 from './../../../../images/roomgrid-img/pic-8.jpg';
import roomgrid9 from './../../../../images/roomgrid-img/pic-9.jpg';
import roomgrid10 from './../../../../images/roomgrid-img/pic-10.jpg';
import roomgrid11 from './../../../../images/roomgrid-img/pic-11.jpg';
import roomgrid12 from './../../../../images/roomgrid-img/pic-12.jpg';
import roomgrid13 from './../../../../images/roomgrid-img/pic-13.jpg';
import roomgrid14 from './../../../../images/roomgrid-img/pic-14.jpg';
import roomgrid15 from './../../../../images/roomgrid-img/pic-15.jpg';

const RoomGrid = [
	{ image:roomgrid1 , title:'404 Brrom Str, Fl2'},
	{ image:roomgrid2 , title:'Neoma Path'},
	{ image:roomgrid3 , title:'Godfrey Common'},
	{ image:roomgrid4 , title:'Homenick Cove'},
	{ image:roomgrid5 , title:'Ari Extension'},
	{ image:roomgrid6 , title:'Weimann Junctions'},
	{ image:roomgrid7 , title:'Dino Shores'},
	{ image:roomgrid8 , title:'Louisa Course'},
	{ image:roomgrid9 , title:'Misael Groves'},
	{ image:roomgrid10, title:'Darby Place'},
	{ image:roomgrid11, title:'Robert Harbor'},
	{ image:roomgrid12, title:'Trey Cape'},
	{ image:roomgrid13, title:'Anderson Isle'},
	{ image:roomgrid14, title:'Heloise Locks'},
	{ image:roomgrid15, title:'Marcella Court'},
];

const RoomGrid2 = [
	{ image:roomgrid5 },
	{ image:roomgrid9 },	
	{ image:roomgrid12},	
	{ image:roomgrid14},
	{ image:roomgrid15}
];
const RoomGrid3 = [
	{ image:roomgrid2 },
	{ image:roomgrid4 },
	{ image:roomgrid6 },
	{ image:roomgrid8 },
	{ image:roomgrid10},
	{ image:roomgrid11},
	{ image:roomgrid13}
];

const RoomData2 = () =>{
	return(
		<>
			<div className="row">
				{RoomGrid2.map((item, ind)=>(
					<div className="main-grid" key={ind}>
						<div className="grid-info">
							<div className="popular-rooms">									
								<Dropdown className="dropstart">
									<Dropdown.Toggle as="a" className="btn-link i-false" data-bs-toggle="dropdown" aria-expanded="false">
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
											<path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
											<path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
										</svg>
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item>Edit</Dropdown.Item>
										<Dropdown.Item>Delete</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
								<img src={item.image} alt="12" />
								<div className="content">									
									<span className="badge badge-success">AVAILABLE</span>
									<h3 className="font-w500 text-white pt-3 pb-2 m-0 text-nowrap"><Link to={"#"}>404 Brrom Str, Fl2</Link></h3>
									<span className="font-w400 text-white">Type 234</span>
								</div>
							</div>
							<div className="review-area">
								<div className="star-rate">
									<h4 className="mb-0 font-w500">3.4</h4>
									<ul>
										<li><i className="fa-solid fa-star"></i></li>
										<li><i className="fa-solid fa-star"></i></li>
										<li><i className="fa-solid fa-star"></i></li>
									</ul>
								</div>
								<p className="mb-0 font-w400">245 Reviews</p>
							</div>
							<ul className="feature-list">
								<li>Floor <span>G-05</span></li>
								<li>Bed Type <span>Double Bed</span></li>
							</ul>
						</div>
					</div>
				))}
			</div>
		</>
	)
}
const RoomData3 = () =>{
	return(
		<>
			<div className="row">
				{RoomGrid3.map((item, ind)=>(
					<div className="main-grid" key={ind}>
						<div className="grid-info">
							<div className="popular-rooms">									
								<Dropdown className="dropstart">
									<Dropdown.Toggle as="a" className="btn-link i-false" data-bs-toggle="dropdown" aria-expanded="false">
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
											<path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
											<path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
										</svg>
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item>Edit</Dropdown.Item>
										<Dropdown.Item>Delete</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
								<img src={item.image} alt="56" />
								<div className="content">
									<span className="badge badge-primary">SOLDOUT</span>
									<h3 className="font-w500 text-white pt-3 pb-2 m-0 text-nowrap"><Link to={"#"}>404 Brrom Str, Fl2</Link></h3>
									<span className="font-w400 text-white">Type 234</span>
								</div>
							</div>
							<div className="review-area">
								<div className="star-rate">
									<h4 className="mb-0 font-w500">3.4</h4>
									<ul>
										<li><i className="fa-solid fa-star"></i></li>
										<li><i className="fa-solid fa-star"></i></li>
										<li><i className="fa-solid fa-star"></i></li>
									</ul>
								</div>
								<p className="mb-0 font-w400">245 Reviews</p>
							</div>
							<ul className="feature-list">
								<li>Floor <span>G-05</span></li>
								<li>Bed Type <span>Double Bed</span></li>
							</ul>
						</div>
					</div>
				))}
			</div>
		</>
	)
}

const BoxedData = () =>{
	return(
		<>
			<div className="row">
				{RoomGrid.map((item, ind)=>(
					<div className="main-grid" key={ind}>
						<div className="grid-info">
							<div className="popular-rooms">									
								<Dropdown className="dropstart">
									<Dropdown.Toggle as="a" className="btn-link i-false" data-bs-toggle="dropdown" aria-expanded="false">
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
											<path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
											<path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
										</svg>
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item>Edit</Dropdown.Item>
										<Dropdown.Item>Delete</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
								<img src={item.image} alt="78" />
								<div className="content">
									{ind % 2 ===0 ? 
										<span className="badge badge-success">AVAILABLE</span>
									:	
										<span className="badge badge-primary">SOLDOUT</span>
									}
									<h3 className="font-w500 text-white pt-3 pb-2 m-0 text-nowrap"><Link to={"#"}>{item.title}</Link></h3>
									<span className="font-w400 text-white">Type 234</span>
								</div>
							</div>
							<div className="review-area">
								<div className="star-rate">
									<h4 className="mb-0 font-w500">3.4</h4>
									<ul>
										<li><i className="fa-solid fa-star"></i></li>
										<li><i className="fa-solid fa-star"></i></li>
										<li><i className="fa-solid fa-star"></i></li>
									</ul>
								</div>
								<p className="mb-0 font-w400">245 Reviews</p>
							</div>
							<ul className="feature-list">
								<li>Floor <span>G-05</span></li>
								<li>Bed Type <span>Double Bed</span></li>
							</ul>
						</div>
					</div>
				))}
			</div>
		</>
	)
}


export {RoomData2, RoomData3};
export default BoxedData;