// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Alert_alert__33QLW {\r\n  position: fixed;\r\n  /* top: 0; */\r\n  /* left: 0; */\r\n  /* right: 0; */\r\n  width: 83%;\r\n  margin-bottom: 10px;\r\n  padding: 10px;\r\n  text-align: center;\r\n  z-index: 9999;\r\n  opacity: 0;\r\n  visibility: hidden;\r\n  transition: opacity 0.3s ease-in-out, visibility 0.3s linear;\r\n}\r\n\r\n.Alert_alert__33QLW.Alert_show__NjLuH {\r\n  opacity: 1;\r\n  visibility: visible;\r\n}\r\n\r\n.Alert_success__6RpYq {\r\n  background-color: #4caf50;\r\n  color: white;\r\n}\r\n\r\n.Alert_error__zBhAU {\r\n  background-color: #ff584d;\r\n  color: white;\r\n}\r\n\r\n.Alert_close__\\+pa89 {\r\n  position: absolute;\r\n  top: 9px;\r\n  right: 10px;\r\n  cursor: pointer;\r\n  display: flex;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/jsx/components/Alert/Alert.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,aAAa;EACb,cAAc;EACd,UAAU;EACV,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,UAAU;EACV,kBAAkB;EAClB,4DAA4D;AAC9D;;AAEA;EACE,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,eAAe;EACf,aAAa;AACf","sourcesContent":[".alert {\r\n  position: fixed;\r\n  /* top: 0; */\r\n  /* left: 0; */\r\n  /* right: 0; */\r\n  width: 83%;\r\n  margin-bottom: 10px;\r\n  padding: 10px;\r\n  text-align: center;\r\n  z-index: 9999;\r\n  opacity: 0;\r\n  visibility: hidden;\r\n  transition: opacity 0.3s ease-in-out, visibility 0.3s linear;\r\n}\r\n\r\n.alert.show {\r\n  opacity: 1;\r\n  visibility: visible;\r\n}\r\n\r\n.success {\r\n  background-color: #4caf50;\r\n  color: white;\r\n}\r\n\r\n.error {\r\n  background-color: #ff584d;\r\n  color: white;\r\n}\r\n\r\n.close {\r\n  position: absolute;\r\n  top: 9px;\r\n  right: 10px;\r\n  cursor: pointer;\r\n  display: flex;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "Alert_alert__33QLW",
	"show": "Alert_show__NjLuH",
	"success": "Alert_success__6RpYq",
	"error": "Alert_error__zBhAU",
	"close": "Alert_close__+pa89"
};
export default ___CSS_LOADER_EXPORT___;
