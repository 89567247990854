import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import {Tab, Nav, Dropdown} from 'react-bootstrap';

import img1 from './../../../images/concierge-img/pic-1.jpg';
import img2 from './../../../images/concierge-img/pic-2.jpg';
import img3 from './../../../images/concierge-img/pic-3.jpg';
import img4 from './../../../images/concierge-img/pic-4.jpg';
import img5 from './../../../images/concierge-img/pic-5.jpg';
import img6 from './../../../images/concierge-img/pic-6.jpg';
import img7 from './../../../images/concierge-img/pic-7.jpg';
import img8 from './../../../images/concierge-img/pic-8.jpg';
import img9 from './../../../images/concierge-img/pic-9.jpg';
import img10 from './../../../images/concierge-img/pic-10.jpg';
import img11 from './../../../images/concierge-img/pic-11.jpg';
import img12 from './../../../images/concierge-img/pic-12.jpg';

const CardDate = [
	{image: img1 , title: 'Alfonso Franci' },
	{image: img2 , title: 'Andy Lee' },
	{image: img3 , title: 'Craig Schleifer' },
	{image: img4 , title: 'Davis Lubin' },
	{image: img5 , title: 'Davis Stanton' },
	{image: img6 , title: 'Giana Ekstrom Bothman' },
	{image: img7 , title: 'Gretchen Botosh' },
	{image: img8 , title: 'Jaxson Saris' },
	{image: img9 , title: 'Kadin Vetrovs' },
	{image: img10, title: 'Kaiya Culhane' },
	{image: img11, title: 'Alfonso Franci' },
	{image: img12, title: 'Roger Levin' },
];
const CardDate2 = [
	
	{image: img5 , title: 'Davis Stanton' },
	{image: img6 , title: 'Giana Ekstrom Bothman' },
	{image: img7 , title: 'Gretchen Botosh' },
	{image: img8 , title: 'Jaxson Saris' },
	{image: img9 , title: 'Kadin Vetrovs' },
	{image: img10, title: 'Kaiya Culhane' },
	
];
const CardDate3 = [
	{image: img2 , title: 'Andy Lee' },
	{image: img3 , title: 'Craig Schleifer' },
	{image: img11, title: 'Alfonso Franci' },
	{image: img12, title: 'Roger Levin' },
];

const DropdownBlog = () =>{
	return(
		<>
			<Dropdown>
				<Dropdown.Toggle as="div" className="btn-link concierge-list-2 i-false">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#717579" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#717579" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#717579" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropdown-menu">
					<Dropdown.Item>Edit</Dropdown.Item>
					<Dropdown.Item>Delete</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</>
	)
}

const Concierge = () =>{
	const [optionBtn, setOptionBtn] = useState('Sort by All');
	
	return(
		<>
			<div className="row">
				<Tab.Container defaultActiveKey="All">
					<div className="coin-tabs mb-5 mt-3 mt-sm-0 d-block d-md-flex align-items-center justify-content-between">
						<div className="coin-tabs">
							<Nav as="ul" className="nav nav-tabs" id="myTab" role="tablist">
								<Nav.Item as="li" className="nav-item" role="presentation">
									<Nav.Link  eventKey="All" type="button" role="tab" >All Status</Nav.Link>
								</Nav.Item>
								<Nav.Item as="li" className="nav-item" role="presentation">
									<Nav.Link  eventKey="Active" type="button">Active</Nav.Link>
								</Nav.Item>
								<Nav.Item as="li" className="nav-item" role="presentation">
									<Nav.Link eventKey="Inactive" type="button" >Inactive</Nav.Link>
								</Nav.Item>
							</Nav>
						</div>
						<div className="form-head d-block d-sm-flex align-items-center mt-md-0 mt-3">							
							<Dropdown  className="default-dropdown concierge-block">
								<Dropdown.Toggle as="div" className="btn i-false default-Select-btn me-3 ms-0">{optionBtn}<i className="fa-solid fa-angle-down"></i></Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={()=>setOptionBtn('Sort by All')} eventKey="All">Sort by All</Dropdown.Item>
									<Dropdown.Item onClick={()=>setOptionBtn('Active')} eventKey="Active">Active</Dropdown.Item>
									<Dropdown.Item onClick={()=>setOptionBtn('Inactive')} eventKey="Inactive">Inactive</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
							<Link to={"#"} className="btn btn-primary ms-0  ms-sm-0 ms-md-3">Add New Concierge</Link>
						</div>
					</div>
					<Tab.Content>
						<Tab.Pane eventKey="All">
							<div className="row">
								{CardDate.map((data, index)=>(
									<div className="col-xl-3 col-xxl-4 col-sm-6" key={index}>
										<div className="card main-card ">
											<div className="card-body">
												<div className="concierge-bx">
													<div className="concierge-img">
														<img src={data.image} alt="" />
													</div>
													<div className="concierge-content">
														<h2>{data.title}</h2>
														<p>#EMP-00025</p>
													</div>
													<div className="concierge-icon">
														<ul className="d-flex align-items-center justify-content-center">
															<li>
																<Link to={"#"} className="concierge-list">
																	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M16.5848 3.47492H15.6483V1.60201C15.6483 1.35365 15.5496 1.11546 15.374 0.939839C15.1984 0.76422 14.9602 0.665558 14.7119 0.665558C14.4635 0.665558 14.2253 0.76422 14.0497 0.939839C13.8741 1.11546 13.7754 1.35365 13.7754 1.60201V3.47492H6.28376V1.60201C6.28376 1.35365 6.1851 1.11546 6.00948 0.939839C5.83386 0.76422 5.59567 0.665558 5.34731 0.665558C5.09895 0.665558 4.86076 0.76422 4.68514 0.939839C4.50952 1.11546 4.41086 1.35365 4.41086 1.60201V3.47492H3.4744C2.72931 3.47492 2.01474 3.77091 1.48788 4.29776C0.961025 4.82462 0.665039 5.53919 0.665039 6.28428V7.22074H19.3941V6.28428C19.3941 5.53919 19.0981 4.82462 18.5713 4.29776C18.0444 3.77091 17.3298 3.47492 16.5848 3.47492Z" fill="#16B455"/>
																		<path d="M0.665039 16.5852C0.665039 17.3303 0.961025 18.0449 1.48788 18.5718C2.01474 19.0986 2.72931 19.3946 3.4744 19.3946H16.5848C17.3298 19.3946 18.0444 19.0986 18.5713 18.5718C19.0981 18.0449 19.3941 17.3303 19.3941 16.5852V9.09363H0.665039V16.5852Z" fill="#16B455"/>
																	</svg>
																</Link>
															</li>
															<li>
																<Link to={"#"} className="concierge-list">
																	<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M22.0202 17.2472L18.9572 14.1949C18.3521 13.6005 17.3828 13.6005 16.7777 14.1949L14.8339 16.1869C14.6358 16.3583 14.3466 16.3583 14.1485 16.1869C12.708 15.0357 11.3265 13.8147 10.0145 12.5189C8.70259 11.2176 7.47097 9.84677 6.31432 8.40631C6.13225 8.21354 6.13225 7.91366 6.31432 7.72089L8.34917 5.73423C8.94356 5.11842 8.94356 4.14383 8.34917 3.52802L5.28618 0.465026C4.66502 -0.113301 3.70114 -0.113301 3.07997 0.465026L0.563181 2.99253C0.563181 2.99253 0.450729 3.09963 0.413245 3.14247C-1.0058 4.84532 1.25932 10.2484 6.75342 15.7104C10.7535 19.7319 15.273 22.4254 17.9719 22.4254C18.4217 22.4682 18.8929 22.4147 19.2678 22.163C19.3427 22.1094 19.5516 21.922 19.5516 21.922L22.0202 19.4427C22.6199 18.8323 22.6199 17.8577 22.0202 17.2472Z" fill="#16B455"/>
																	</svg>
																</Link>
															</li>
															<li>
																<DropdownBlog />
															</li>
														</ul>
													</div>
													<div className="d-flex align-items-center justify-content-between py-4 concierge-title">
														<h4 className="mb-0">Join at January, 2014</h4>
														<span className=" badge badge-success font-w400 font-w400">Active</span>
													</div>
													<div className="concierge-footer">
														<span className="texh-light ">Job Desc</span>
														<p className=" mb-0 text-black">Answering guest inquiries, directing phone calls, coordinating travel plans, and more.</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
								<div className="d-flex align-items-center justify-content-between flex-wrap">
									<div className="mb-sm-0 mb-3">
										<h5 className="mb-0">Showing 12 of 12 Data</h5>
									</div>
									<nav>
										<ul className="pagination pagination-gutter pagination-primary style-1">
											<li className="page-item page-indicator job-search-page">
												<Link to={"#"} className="page-link">Prev</Link>
											</li>
											<li className="page-item active"><Link to={"#"} className="page-link">1</Link>
											</li>
											{/* <li className="page-item"><Link to={"#"} className="page-link">2</Link></li>	
											<li className="page-item"><Link to={"#"} className="page-link" >3</Link></li> */}
											<li className="page-item page-indicator job-search-page">
												<Link to={"#"} className="page-link" >Next</Link>
											</li>
										</ul>
									</nav>
								</div>
							</div>
						</Tab.Pane>
						<Tab.Pane eventKey="Active">
							<div className="row">
								{CardDate2.map((data, index)=>(
									<div className="col-xl-3 col-xxl-4 col-sm-6" key={index}>
										<div className="card main-card ">
											<div className="card-body">
												<div className="concierge-bx">
													<div className="concierge-img">
														<img src={data.image} alt="" />
													</div>
													<div className="concierge-content">
														<h2>{data.title}</h2>
														<p>#EMP-00025</p>
													</div>
													<div className="concierge-icon">
														<ul className="d-flex align-items-center justify-content-center">
															<li>
																<Link to={"#"} className="concierge-list">
																	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M16.5848 3.47492H15.6483V1.60201C15.6483 1.35365 15.5496 1.11546 15.374 0.939839C15.1984 0.76422 14.9602 0.665558 14.7119 0.665558C14.4635 0.665558 14.2253 0.76422 14.0497 0.939839C13.8741 1.11546 13.7754 1.35365 13.7754 1.60201V3.47492H6.28376V1.60201C6.28376 1.35365 6.1851 1.11546 6.00948 0.939839C5.83386 0.76422 5.59567 0.665558 5.34731 0.665558C5.09895 0.665558 4.86076 0.76422 4.68514 0.939839C4.50952 1.11546 4.41086 1.35365 4.41086 1.60201V3.47492H3.4744C2.72931 3.47492 2.01474 3.77091 1.48788 4.29776C0.961025 4.82462 0.665039 5.53919 0.665039 6.28428V7.22074H19.3941V6.28428C19.3941 5.53919 19.0981 4.82462 18.5713 4.29776C18.0444 3.77091 17.3298 3.47492 16.5848 3.47492Z" fill="#16B455"/>
																		<path d="M0.665039 16.5852C0.665039 17.3303 0.961025 18.0449 1.48788 18.5718C2.01474 19.0986 2.72931 19.3946 3.4744 19.3946H16.5848C17.3298 19.3946 18.0444 19.0986 18.5713 18.5718C19.0981 18.0449 19.3941 17.3303 19.3941 16.5852V9.09363H0.665039V16.5852Z" fill="#16B455"/>
																	</svg>
																</Link>
															</li>
															<li>
																<Link to={"#"} className="concierge-list">
																	<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M22.0202 17.2472L18.9572 14.1949C18.3521 13.6005 17.3828 13.6005 16.7777 14.1949L14.8339 16.1869C14.6358 16.3583 14.3466 16.3583 14.1485 16.1869C12.708 15.0357 11.3265 13.8147 10.0145 12.5189C8.70259 11.2176 7.47097 9.84677 6.31432 8.40631C6.13225 8.21354 6.13225 7.91366 6.31432 7.72089L8.34917 5.73423C8.94356 5.11842 8.94356 4.14383 8.34917 3.52802L5.28618 0.465026C4.66502 -0.113301 3.70114 -0.113301 3.07997 0.465026L0.563181 2.99253C0.563181 2.99253 0.450729 3.09963 0.413245 3.14247C-1.0058 4.84532 1.25932 10.2484 6.75342 15.7104C10.7535 19.7319 15.273 22.4254 17.9719 22.4254C18.4217 22.4682 18.8929 22.4147 19.2678 22.163C19.3427 22.1094 19.5516 21.922 19.5516 21.922L22.0202 19.4427C22.6199 18.8323 22.6199 17.8577 22.0202 17.2472Z" fill="#16B455"/>
																	</svg>
																</Link>
															</li>
															<li>
																<DropdownBlog /> 
															</li>
														</ul>
													</div>
													<div className="d-flex align-items-center justify-content-between py-4 concierge-title">
														<h4 className="mb-0">Join at January, 2014</h4>
														<span className=" badge badge-success font-w400 font-w400">Active</span>
													</div>
													<div className="concierge-footer">
														<span className="texh-light ">Job Desc</span>
														<p className=" mb-0 text-black">Answering guest inquiries, directing phone calls, coordinating travel plans, and more.</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</Tab.Pane>
						<Tab.Pane eventKey="Inactive">
							<div className="row">
								{CardDate3.map((data, index)=>(
									<div className="col-xl-3 col-xxl-4 col-sm-6" key={index}>
										<div className="card main-card ">
											<div className="card-body">
												<div className="concierge-bx">
													<div className="concierge-img">
														<img src={data.image} alt="" />
													</div>
													<div className="concierge-content">
														<h2>{data.title}</h2>
														<p>#EMP-00025</p>
													</div>
													<div className="concierge-icon">
														<ul className="d-flex align-items-center justify-content-center">
															<li>
																<Link to={"#"} className="concierge-list">
																	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M16.5848 3.47492H15.6483V1.60201C15.6483 1.35365 15.5496 1.11546 15.374 0.939839C15.1984 0.76422 14.9602 0.665558 14.7119 0.665558C14.4635 0.665558 14.2253 0.76422 14.0497 0.939839C13.8741 1.11546 13.7754 1.35365 13.7754 1.60201V3.47492H6.28376V1.60201C6.28376 1.35365 6.1851 1.11546 6.00948 0.939839C5.83386 0.76422 5.59567 0.665558 5.34731 0.665558C5.09895 0.665558 4.86076 0.76422 4.68514 0.939839C4.50952 1.11546 4.41086 1.35365 4.41086 1.60201V3.47492H3.4744C2.72931 3.47492 2.01474 3.77091 1.48788 4.29776C0.961025 4.82462 0.665039 5.53919 0.665039 6.28428V7.22074H19.3941V6.28428C19.3941 5.53919 19.0981 4.82462 18.5713 4.29776C18.0444 3.77091 17.3298 3.47492 16.5848 3.47492Z" fill="#16B455"/>
																		<path d="M0.665039 16.5852C0.665039 17.3303 0.961025 18.0449 1.48788 18.5718C2.01474 19.0986 2.72931 19.3946 3.4744 19.3946H16.5848C17.3298 19.3946 18.0444 19.0986 18.5713 18.5718C19.0981 18.0449 19.3941 17.3303 19.3941 16.5852V9.09363H0.665039V16.5852Z" fill="#16B455"/>
																	</svg>
																</Link>
															</li>
															<li>
																<Link to={"#"} className="concierge-list">
																	<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path d="M22.0202 17.2472L18.9572 14.1949C18.3521 13.6005 17.3828 13.6005 16.7777 14.1949L14.8339 16.1869C14.6358 16.3583 14.3466 16.3583 14.1485 16.1869C12.708 15.0357 11.3265 13.8147 10.0145 12.5189C8.70259 11.2176 7.47097 9.84677 6.31432 8.40631C6.13225 8.21354 6.13225 7.91366 6.31432 7.72089L8.34917 5.73423C8.94356 5.11842 8.94356 4.14383 8.34917 3.52802L5.28618 0.465026C4.66502 -0.113301 3.70114 -0.113301 3.07997 0.465026L0.563181 2.99253C0.563181 2.99253 0.450729 3.09963 0.413245 3.14247C-1.0058 4.84532 1.25932 10.2484 6.75342 15.7104C10.7535 19.7319 15.273 22.4254 17.9719 22.4254C18.4217 22.4682 18.8929 22.4147 19.2678 22.163C19.3427 22.1094 19.5516 21.922 19.5516 21.922L22.0202 19.4427C22.6199 18.8323 22.6199 17.8577 22.0202 17.2472Z" fill="#16B455"/>
																	</svg>
																</Link>
															</li>
															<li>
																<DropdownBlog /> 
															</li>
														</ul>
													</div>
													<div className="d-flex align-items-center justify-content-between py-4 concierge-title">
														<h4 className="mb-0">Join at January, 2014</h4>
														<span className=" badge badge-success font-w400 font-w400">Active</span>
													</div>
													<div className="concierge-footer">
														<span className="texh-light ">Job Desc</span>
														<p className=" mb-0 text-black">Answering guest inquiries, directing phone calls, coordinating travel plans, and more.</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</Tab.Pane>
					</Tab.Content>
				</Tab.Container>
			</div>
		</>
	)
}
export default Concierge;