import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import logo from '../../images/logo-full.png';
import { sendResetPassword } from 'store/slices/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';

function ForgotPassword(props) {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const sendResetPasswordApiStatus = useSelector(state => state.auth.sendResetPassword.status);
  const onSignUp = (data) => {
    dispatch(sendResetPassword(data));
  };

  return (
    <div className={`authincation h-100 p-meddle ${sendResetPasswordApiStatus === 'loading' && 'loader'}`
    }>
      <Container className="h-100">
        <Row className="justify-content-center h-100 align-items-center">
          <Col md={6}>
            <div className="authincation-content">
              <Row>
                <Col xl={12}>
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/login">
                        <Image src={logo} alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Sign up your account</h4>
                    {props.errorMessage && (
                      <div className="">{props.errorMessage}</div>
                    )}
                    {props.successMessage && (
                      <div className="">{props.successMessage}</div>
                    )}
                    <Form onSubmit={handleSubmit(onSignUp)}>
                      {(
                        <>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              <strong>Email</strong>
                            </Form.Label>
                            <Form.Control
                              {...register("email", { required: "Email is required", pattern: { value: /^\S+@\S+$/i, message: "Invalid email address" } })}
                              placeholder="Email"
                            />
                            {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}
                          </Form.Group>
                          <div className="text-center mt-4">
                            <Button
                              type="submit"
                              className="btn btn-primary btn-block"
                            >
                              Send Recovery Email
                            </Button>
                          </div>
                        </>
                      )}
                    </Form>
                    <div className="new-account mt-3">
                      <p className="">
                        <Link className="text-primary" to="/login">
                          Back to login
                        </Link>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div >
  );
}

export default ForgotPassword;