import React from 'react';
import {Link} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';

import PurchaseHistory from './Guest/PurchaseHistory';
import GuestMainSlider from './Guest/GuestMainSlider';

//Images
import user from './../../../images/user.jpg';

const FacilitiesList = ({titleName}) =>{
	return(
		<>
			<Link to={"#"}>
				<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M10 0C4.50742 0 0 4.50742 0 10C0 15.4926 4.50742 20 10 20C15.4926 20 20 15.4926 20 10C20 4.50742 15.4926 0 10 0ZM8.7898 14.5484L4.4107 10.1694L6.06781 8.51227L8.86648 11.3109L14.485 6.20344L16.062 7.93723L8.7898 14.5484Z" fill="#78D69D"/>
				</svg>
				{titleName}
			</Link>
		</>
	)
}

const GuestDetails  = () =>{
			
	return(
		<>
			<div className="row">
				<div className="col-xl-4">
					<div className="card h-auto">
						<div className="card-body profile-bx">
							<div className="profile-media">
								<div className="d-flex align-items-center align-items-sm-start">
									<img src={user} alt="" />
									<div className="media-content">
										<h3 className="fs-21">Thomas Braun</h3>
										<p className="fs-16 mb-0">#G-001234125</p>
									</div>
								</div>	
								<Dropdown className="dropdown">
									<Dropdown.Toggle as="div" className="btn-link i-false" data-bs-toggle="dropdown" aria-expanded="false">
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
											<path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
											<path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
										</svg>
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdown-menu">
										<Dropdown.Item className="dropdown-item">Edit</Dropdown.Item>
										<Dropdown.Item className="dropdown-item">Delete</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>
					<div className="card h-auto">
						<div className="card-header border-0 pb-0">
							<h4 className="fs-24 font-w500">Marcella Court</h4>
						</div>
						<div className="card-body pt-0">
							<div className="d-flex align-items-center justify-content-between mb-4">
								<p className="m-0">#R-245T345</p>
								<Link to={"#"} className="btn btn-primary btn-sm">BOOKED</Link>
							</div>
							<div className="d-flex align-items-center">
								<div className="me-5">
									<p className="fs-12">Type</p>
									<div className="d-flex">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M17.2917 9.37501H2.70833C2.25 9.37501 1.875 9.00001 1.875 8.54168V2.54168C1.875 1.25001 2.91667 0.208344 4.20833 0.208344H15.7917C17.0833 0.208344 18.125 1.25001 18.125 2.54168V8.54168C18.125 9.00001 17.75 9.37501 17.2917 9.37501ZM3.5 7.75001H16.4583V2.54168C16.4583 2.16668 16.1667 1.83334 15.75 1.83334H4.20833C3.83333 1.83334 3.5 2.12501 3.5 2.54168V7.75001Z" fill="#F66F4D"/>
											<path d="M18.958 17.375H1.04134C0.583008 17.375 0.208008 17 0.208008 16.5417V8.54168C0.208008 8.08334 0.583008 7.70834 1.04134 7.70834H18.9997C19.458 7.70834 19.833 8.08334 19.833 8.54168V16.5834C19.7913 17 19.4163 17.375 18.958 17.375ZM1.83301 15.75H18.1663V9.37501H1.83301V15.75Z" fill="#F66F4D"/>
											<path d="M9.7915 9.37502H5.24984C4.7915 9.37502 4.4165 9.00002 4.4165 8.54169C4.4165 6.45835 5.7915 4.79169 7.49984 4.79169C9.20817 4.79169 10.5832 6.45835 10.5832 8.54169C10.5832 9.00002 10.2498 9.37502 9.7915 9.37502ZM6.20817 7.75002H8.87484C8.6665 7.00002 8.12484 6.45835 7.5415 6.45835C6.95817 6.45835 6.4165 6.95835 6.20817 7.75002Z" fill="#F66F4D"/>
											<path d="M14.75 9.37502H10.2083C9.75 9.37502 9.375 9.00002 9.375 8.54169C9.375 6.45835 10.75 4.79169 12.4583 4.79169C14.1667 4.79169 15.5417 6.45835 15.5417 8.54169C15.5417 9.00002 15.1667 9.37502 14.75 9.37502ZM11.1667 7.75002H13.8333C13.625 7.00002 13.0833 6.45835 12.5 6.45835C11.875 6.41669 11.375 6.95835 11.1667 7.75002Z" fill="#F66F4D"/>
											<path d="M1.04134 19.7917C0.583008 19.7917 0.208008 19.4167 0.208008 18.9583V16.5417C0.208008 16.0833 0.583008 15.7083 1.04134 15.7083C1.49967 15.7083 1.87467 16.0833 1.87467 16.5417V18.9583C1.83301 19.4167 1.45801 19.7917 1.04134 19.7917Z" fill="#F66F4D"/>
											<path d="M18.9583 19.7917C18.5 19.7917 18.125 19.4167 18.125 18.9583V16.5417C18.125 16.0833 18.5 15.7083 18.9583 15.7083C19.4167 15.7083 19.7917 16.0833 19.7917 16.5417V18.9583C19.7917 19.4167 19.4167 19.7917 18.9583 19.7917Z" fill="#F66F4D"/>
											</svg>
										<h5 className="fs-15 mb-4 ms-3">Double Bed</h5>
									</div>
								</div>
								 <div className="ms-3">
									<p className="fs-12">Room Capacity</p>
									<div className="d-flex">
										<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M12.0837 9.76416C12.9036 9.12303 13.5029 8.24201 13.7982 7.24397C14.0935 6.24592 14.07 5.18061 13.7309 4.19658C13.3918 3.21255 12.7541 2.35886 11.9067 1.75455C11.0593 1.15024 10.0445 0.825439 9.00366 0.825439C7.96285 0.825439 6.94798 1.15024 6.10058 1.75455C5.25318 2.35886 4.61548 3.21255 4.27642 4.19658C3.93735 5.18061 3.91382 6.24592 4.2091 7.24397C4.50439 8.24201 5.10377 9.12303 5.92366 9.76416C4.37463 10.3805 3.04588 11.447 2.10888 12.8259C1.17188 14.2048 0.669646 15.8329 0.666992 17.5V18.3333C0.666992 18.5543 0.75479 18.7663 0.91107 18.9226C1.06735 19.0789 1.27931 19.1667 1.50033 19.1667H16.5003C16.7213 19.1667 16.9333 19.0789 17.0896 18.9226C17.2459 18.7663 17.3337 18.5543 17.3337 18.3333V17.5C17.3315 15.8336 16.8303 14.2061 15.8945 12.8273C14.9588 11.4485 13.6314 10.3816 12.0837 9.76416ZM5.66699 5.83333C5.66699 5.17406 5.86249 4.5296 6.22876 3.98143C6.59503 3.43327 7.11563 3.00603 7.72471 2.75374C8.3338 2.50145 9.00402 2.43543 9.65063 2.56405C10.2972 2.69267 10.8912 3.01014 11.3573 3.47631C11.8235 3.94249 12.141 4.53643 12.2696 5.18303C12.3982 5.82964 12.3322 6.49986 12.0799 7.10894C11.8276 7.71803 11.4004 8.23863 10.8522 8.6049C10.3041 8.97117 9.6596 9.16667 9.00033 9.16667C8.11627 9.16667 7.26842 8.81548 6.6433 8.19035C6.01818 7.56523 5.66699 6.71739 5.66699 5.83333ZM2.33366 17.5C2.33366 15.7319 3.03604 14.0362 4.28628 12.786C5.53652 11.5357 7.23222 10.8333 9.00033 10.8333C10.7684 10.8333 12.4641 11.5357 13.7144 12.786C14.9646 14.0362 15.667 15.7319 15.667 17.5H2.33366Z" fill="#F66F4D"/>
										</svg>
										<h5 className="fs-15 mb-4 ms-3">2-4 Person</h5>
									</div>
								</div>
							</div>
							<div>
								<p className="fs-12">Date Booking</p>
								<div className="d-flex">
									<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fillRule="evenodd" clipRule="evenodd" d="M10.6667 1.8V0.9C10.6667 0.4032 11.0649 0 11.5556 0C12.0462 0 12.4444 0.4032 12.4444 0.9V1.8C14.408 1.8 16 3.4119 16 5.4V14.4C16 16.3881 14.408 18 12.4444 18C9.90222 18 6.09689 18 3.55556 18C1.592 18 0 16.3881 0 14.4V5.4C0 3.4119 1.592 1.8 3.55556 1.8V0.9C3.55556 0.4032 3.95378 0 4.44444 0C4.93511 0 5.33333 0.4032 5.33333 0.9V1.8H10.6667ZM14.2222 9H1.77778V14.4C1.77778 15.3936 2.57333 16.2 3.55556 16.2H12.4444C13.4258 16.2 14.2222 15.3936 14.2222 14.4V9ZM12.4444 3.6V4.5C12.4444 4.9968 12.0462 5.4 11.5556 5.4C11.0649 5.4 10.6667 4.9968 10.6667 4.5V3.6H5.33333V4.5C5.33333 4.9968 4.93511 5.4 4.44444 5.4C3.95378 5.4 3.55556 4.9968 3.55556 4.5V3.6C2.57333 3.6 1.77778 4.4055 1.77778 5.4V7.2H14.2222V5.4C14.2222 4.4055 13.4258 3.6 12.4444 3.6Z" fill="#F66F4D"/>
									</svg>
									<h5 className="fs-15 mb-4 ms-3">Thursday, February 4 2021 at 2:24 AM</h5>
								</div>
							</div>
							<div className="facilities">
								<p className="fs-12">Facilities</p>
								<div className="row">
									<div className="col-xl-6 col-6">
										<ul>
											<li>
												<FacilitiesList titleName="Room Full AC" />												
											</li>
											<li>
												<FacilitiesList titleName="Shower" />	
											</li>
											<li>
												<FacilitiesList titleName="Bathup" />	
											</li>
										</ul>
									</div>
									<div className="col-xl-6 col-6">
										<ul>
											<li>
												<FacilitiesList titleName="Bathup" />	
											</li>
											<li>
												<FacilitiesList titleName="Room Full AC" />												
											</li>
											<li>
												<FacilitiesList titleName="Shower" />	
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="card-footer border-0 pt-0">
							<div className="facilities-more text-center mb-2">
								<Link to={"#"} className="fs-14 font-w500 text-nowrap">View More<i className=" ms-3 fa-solid fa-chevron-down"></i></Link>
							</div>
						</div>
					</div>
				</div>
				
				
				<div className="col-xl-8">
					<GuestMainSlider />
				</div>
				<div className="col-xl-12">
					<PurchaseHistory />
				</div>
				
			</div>
			
		</>
	)
}
export default GuestDetails;