import React, { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import call from "utils/request";
import { Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { MANDAL, JANPATH, TEHSIL } from "utils/constants";
import { capitalizeFirstChar } from "utils/helper";

const UserInvite = () => {
  const history = useHistory();
  const userInfo = JSON.parse(sessionStorage.getItem("userDetails"));
  const [config, setConfig] = useState([]);

  useEffect(() => {
    const getConfig = async () => {
      await call(
        {
          method: "get",
          url: "/api/config/all",
        },
        {
          Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
        }
      ).then(({ data }) => {
        setConfig(data);
      });
    };
    getConfig();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    getValues,
  } = useForm();

  const watchedField = watch("userType");
  const mandalWatchedField = watch("mandal");
  const janpathWatchedField = watch("janpath");

  const getUserFullTypePath = (userType = "", { mandal, janpath, tehsil }) => {
    switch (userType) {
      case MANDAL:
        return mandal;
      case JANPATH:
        return `${mandal}_${janpath}`;
      case TEHSIL:
        return `${mandal}_${janpath}_${tehsil}`;
      default:
        return "";
    }
  };

  const onSubmit = async (data) => {
    let createData = {
      name: data?.name,
      email: data?.email,
      userType: data?.userType,
      typeValue: data?.[data?.userType],
      password: data?.password,
      fullTypePath: getUserFullTypePath(data?.userType, data),
    };

    await call(
      {
        method: "post",
        url: "/api/auth/invite-user",
        data: createData,
      },
      {
        Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
      }
    )
      .then((res) => {
        if (res?.status === 201) {
          Swal.fire({
            title: "User added!",
            text: res?.data?.message || "User has been added successfully",
            icon: "success",
            showConfirmButton: false,
            timer: 500,
          });
          history.push(`/users`);
        }
      })
      .catch((err) => {
        const errMsg = err?.response?.data?.message;
        console.log({ errMsg });
      });
  };

  const OptionsComponent = useCallback(() => {
    return (
      <>
        {[MANDAL, JANPATH, TEHSIL].includes(getValues("userType")) && (
          <div className="form-group mb-3 col-md-6">
            <label>
              Select Mandal <span className="text-danger">*</span>
            </label>
            <select
              id="mandal"
              className="form-control"
              {...register("mandal", {
                required: "Mandal is required",
              })}
            >
              <option value="">Select Mandal</option>
              {config
                .map((x) => x.name)
                ?.map((item, i) => (
                  <option key={`mandal-${i}`} value={item}>
                    {capitalizeFirstChar(item)}
                  </option>
                ))}
            </select>
            {errors?.mandal && (
              <Form.Text className="text-danger">
                {errors?.mandal?.message}
              </Form.Text>
            )}
          </div>
        )}
        {(getValues("userType") === TEHSIL ||
          getValues("userType") === JANPATH) && (
          <div className="form-group mb-3 col-md-6">
            <label>
              Select Janpath <span className="text-danger">*</span>
            </label>
            <select
              id="janpath"
              className="form-control"
              {...register("janpath", {
                required: "janpath is required",
              })}
            >
              <option value="">Select Janpath</option>
              {config
                .find((x) => x.name === getValues("mandal"))
                ?.children.map((x) => x.name)
                ?.map((item, i) => (
                  <option key={`janpath-${i}`} value={item}>
                    {capitalizeFirstChar(item)}
                  </option>
                ))}
            </select>
            {errors?.janpath && (
              <Form.Text className="text-danger">
                {errors?.janpath?.message}
              </Form.Text>
            )}
          </div>
        )}
        {getValues("userType") === TEHSIL && (
          <div className="form-group mb-3 col-md-6">
            <label>
              Select Tehsil <span className="text-danger">*</span>
            </label>
            <select
              id="tehsil"
              className="form-control"
              {...register("tehsil", {
                required: "tehsil is required",
              })}
            >
              <option value="">Select Tehsil</option>
              {config
                .find((x) => x.name === getValues("mandal"))
                ?.children.find((x) => x.name === getValues("janpath"))
                ?.children.map((x) => x.name)
                ?.map((item, i) => (
                  <option key={`tehsil-${i}`} value={item}>
                    {capitalizeFirstChar(item)}
                  </option>
                ))}
            </select>
            {errors?.tehsil && (
              <Form.Text className="text-danger">
                {errors?.tehsil?.message}
              </Form.Text>
            )}
          </div>
        )}
      </>
    );
  }, [
    watchedField,
    mandalWatchedField,
    janpathWatchedField,
    config,
    errors,
    getValues,
    register,
  ]);

  return (
    <>
      <div>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label>
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        {...register("name", {
                          required: "Name is required",
                        })}
                        className="form-control"
                        placeholder="Select Name"
                      />
                      {errors?.name && (
                        <Form.Text className="text-danger">
                          {errors?.name?.message}
                        </Form.Text>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>
                        Username <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        {...register("email", {
                          required: "Email is required",
                        })}
                        className="form-control"
                        placeholder="Enter Email"
                      />
                      {errors?.email && (
                        <Form.Text className="text-danger">
                          {errors?.email?.message}
                        </Form.Text>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>
                        Password <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                        })}
                        className="form-control"
                        placeholder="Enter Password"
                        type="Password"
                      />
                      {errors?.password && (
                        <Form.Text className="text-danger">
                          {errors?.password?.message}
                        </Form.Text>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>
                        User Type <span className="text-danger">*</span>
                      </label>
                      <select
                        id="userType"
                        className="form-control"
                        {...register("userType", {
                          required: "User Type is required",
                        })}
                      >
                        <option value="">Select User Type</option>
                        {[MANDAL, JANPATH, TEHSIL]?.map((item, i) => (
                          <option key={`userType-${i}`} value={item}>
                            {capitalizeFirstChar(item)}
                          </option>
                        ))}
                      </select>
                      {errors?.userType && (
                        <Form.Text className="text-danger">
                          {errors?.userType?.message}
                        </Form.Text>
                      )}
                    </div>
                    <OptionsComponent />
                    <div className="column d-flex justify-content-between">
                      <Button
                        type="button"
                        className="btn px-4"
                        variant="light"
                        onClick={() => history.goBack()}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-primary px-4"
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInvite;
