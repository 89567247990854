import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import call from 'utils/request';
import { Button, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useHistory, useLocation } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';

function TenantEdit() {
  const history = useHistory();
  const location = useLocation();
  const pathName = location?.pathname?.split('/');
  const tenantId = location?.state
    ? location?.state?.id
    : pathName[2] !== 'create'
    ? pathName[2]
    : undefined;
  const [tenantData, setTenantData] = useState({});

  useEffect(() => {
    if (tenantId) {
      const getTenantData = async () => {
        await call({
          method: 'get',
          url: `/api/tenant/${tenantId}`,
        }).then((res) => {
          setTenantData(res?.data);
        });
      };
      getTenantData();
    }
  }, [tenantId]);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(() => {
    if (Object.keys(tenantData).length) {
      setValue('name', tenantData?.name);
      setValue('address', tenantData?.address);
      setValue('phone_number', tenantData?.phone);
      setValue('email', tenantData?.email);
      setValue('facebook', tenantData?.socialMedia?.facebook);
      setValue('instagram', tenantData?.socialMedia?.instagram);
      setValue('linkedIn', tenantData?.socialMedia?.linkedIn);
      setValue('pinterest', tenantData?.socialMedia?.pinterest);
      setValue('twitter', tenantData?.socialMedia?.twitter);
      setValue('youtube', tenantData?.socialMedia?.youtube);
      // setValue('logo', tenantData?.logo);
      setValue('privacyPolicy', tenantData?.privacyPolicy);
      setValue('termsAndConditions', tenantData?.termsAndConditions);
    }
  }, [tenantData, setValue]);

  const onSubmit = async (data) => {
    console.log(data, 'data');
    const updateData = new FormData();
    updateData.append('email', data?.email);
    updateData.append('phone', data?.phone_number);
    data?.logo[0] && updateData.append('logo', data?.logo[0]);
    updateData.append('address', data?.address);
    updateData.append('socialMedia.instagram', data?.instagram);
    updateData.append('socialMedia.facebook', data?.facebook);
    updateData.append('socialMedia.twitter', data?.twitter);
    updateData.append('socialMedia.linkedIn', data?.linkedIn);
    updateData.append('socialMedia.pinterest', data?.pinterest);
    updateData.append('socialMedia.youtube', data?.youtube);
    updateData.append('privacyPolicy', data?.privacyPolicy);
    updateData.append('termsAndConditions', data?.termsAndConditions);

    await call({
      method: 'patch',
      url: `/api/tenant/${tenantId}`,
      data: updateData,
    })
      .then((res) => {
        if (res?.status === 204) {
          Swal.fire({
            title: 'Updated!',
            text: res?.data?.message || 'Tenant details has been updated',
            icon: 'success',
            showConfirmButton: false,
            timer: 500,
          });
          history.push(`/tenant/${tenantData?._id}/image`, {
            id: tenantData?._id,
          });
        }
      })
      .catch((err) => {
        const items = err?.response?.data?.errors;
        for (let item of items) {
          // for (let key in item) {
          setError('afterSubmit', item, { message: item?.messages[0] });
          // }
        }
      });
  };

  return (
    <div>
      {' '}
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          {/* <div className="card-header">
            <h4 className="card-title">Horizontal Form</h4>
          </div> */}
          <div className="card-body">
            <div className="basic-form">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  {/* <div className="form-group mb-3 col-md-6">
                    <label>
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register('name', { required: 'Name is required' })}
                      className="form-control"
                      placeholder="Enter name"
                    />
                    {errors?.name && (
                      <Form.Text className="text-danger">
                        {errors?.name?.message}
                      </Form.Text>
                    )}
                  </div> */}
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Site Logo <span className="text-danger">*</span>
                    </label>
                    <input
                      type="file"
                      {...register('logo')}
                      className="form-control"
                      placeholder="Upload logo"
                    />
                    {tenantData?.logo && (
                      <div className="mt-2">
                        <span>Preview:</span>{' '}
                        <img
                          src={tenantData?.logo}
                          alt="logo-img"
                          height={60}
                          width={60}
                          style={{ borderRadius: '10%' }}
                        />
                      </div>
                    )}
                    {errors?.logo && (
                      <Form.Text className="text-danger">
                        {errors?.logo?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Address <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register('address', {
                        required: 'Address is required',
                      })}
                      className="form-control"
                      placeholder="Enter address"
                    />
                    {errors?.address && (
                      <Form.Text className="text-danger">
                        {errors?.address?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: /^\S+@\S+$/i,
                          message: 'Invalid email address',
                        },
                      })}
                      className="form-control"
                      placeholder="Enter email"
                    />
                    {errors?.email && (
                      <Form.Text className="text-danger">
                        {errors?.email?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Phone Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      {...register('phone_number', {
                        required: 'Phone number is required',
                      })}
                      className="form-control"
                      placeholder="Enter phone number"
                    />
                    {errors?.phone_number && (
                      <Form.Text className="text-danger">
                        {errors?.phone_number?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Facebook URL <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register('facebook')}
                      className="form-control"
                      placeholder="Enter facebook url"
                    />
                    {errors?.facebook && (
                      <Form.Text className="text-danger">
                        {errors?.facebook?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Instagram URL <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register('instagram')}
                      className="form-control"
                      placeholder="Enter instagram url"
                    />
                    {errors?.instagram && (
                      <Form.Text className="text-danger">
                        {errors?.instagram?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Twitter URL <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register('twitter')}
                      className="form-control"
                      placeholder="Enter twitter url"
                    />
                    {errors?.twitter && (
                      <Form.Text className="text-danger">
                        {errors?.twitter?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      LinkedIn URL <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register('linkedIn')}
                      className="form-control"
                      placeholder="Enter linkedIn url"
                    />
                    {errors?.linkedIn && (
                      <Form.Text className="text-danger">
                        {errors?.linkedIn?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Pinterest URL <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register('pinterest')}
                      className="form-control"
                      placeholder="Enter pinterest url"
                    />
                    {errors?.pinterest && (
                      <Form.Text className="text-danger">
                        {errors?.pinterest?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Youtube URL <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register('youtube')}
                      className="form-control"
                      placeholder="Enter youtube url"
                    />
                    {errors?.youtube && (
                      <Form.Text className="text-danger">
                        {errors?.youtube?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Privacy policy <span className="text-danger">*</span>
                    </label>
                    <Editor
                      className="form-control"
                      {...register('privacyPolicy', {
                        required: 'Privacy policy content is required',
                      })}
                      initialValue={tenantData?.privacyPolicy}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image code charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount',
                        ],
                        toolbar:
                          'undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \n' +
                          'bullist numlist outdent indent | removeformat | help ',
                        content_style: 'body { color: #828282 }',
                      }}
                      onEditorChange={(content) =>
                        setValue('privacyPolicy', content)
                      }
                    />
                    {errors?.privacyPolicy && (
                      <Form.Text className="text-danger">
                        {errors?.privacyPolicy?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Terms and Conditions{' '}
                      <span className="text-danger">*</span>
                    </label>
                    <Editor
                      className="form-control"
                      {...register('termsAndConditions', {
                        required: 'Terms and condition content is required',
                      })}
                      initialValue={tenantData?.termsAndConditions}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image code charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount',
                        ],
                        toolbar:
                          'undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \n' +
                          'bullist numlist outdent indent | removeformat | help ',
                        content_style: 'body { color: #828282 }',
                      }}
                      onEditorChange={(content) =>
                        setValue('termsAndConditions', content)
                      }
                    />
                    {errors?.termsAndConditions && (
                      <Form.Text className="text-danger">
                        {errors?.termsAndConditions?.message}
                      </Form.Text>
                    )}
                  </div>
                </div>
                <div className="row">
                  {!!errors.afterSubmit && (
                    <span className="text-center text-danger">
                      {errors?.afterSubmit?.messages[0]}
                    </span>
                  )}
                </div>
                <div className="column d-flex justify-content-between">
                  <Button
                    type="button"
                    className="btn px-4"
                    variant="light"
                    onClick={() => history.goBack()}
                    //   disabled={isSubmitting}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    className="btn btn-primary px-4"
                    disabled={isSubmitting}
                  >
                    Next
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TenantEdit;
