// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_reportEvent__2g8VC {\r\n  text-align: start !important;\r\n}\r\n\r\n.style_data__eUi7h {\r\n  text-align: end;\r\n  margin-left: 10px;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/jsx/components/AppsMenu/Calendar/style.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".reportEvent {\r\n  text-align: start !important;\r\n}\r\n\r\n.data {\r\n  text-align: end;\r\n  margin-left: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reportEvent": "style_reportEvent__2g8VC",
	"data": "style_data__eUi7h"
};
export default ___CSS_LOADER_EXPORT___;
