import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import { Tab, Nav, Dropdown } from 'react-bootstrap';
// import PerfectScrollbar from 'react-perfect-scrollbar';
// import loadable from '@loadable/component';
// import pMinDelay from 'p-min-delay';
import call from "utils/request";
//Import Components
// import EventCalendar from "../AppsMenu/Calendar/EventCalendar";
// import moment from "moment";
// import { BOOKING_STATUS } from "utils/constants";

const Home = () => {
  const userInfo = JSON.parse(sessionStorage.getItem("userDetails"));
  const [loading, setLoading] = useState(false);
  const [noOfEvents, setNoOfEvents] = useState(0);

  useEffect(() => {
    // changeBackground({ value: 'light', label: 'Light' });
    const getReservations = async () => {
      setLoading(false);
      await call(
        {
          method: "get",
          url: "/api/common/count-events",
        },
        {
          Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
        }
      ).then(({ data }) => {
        setNoOfEvents(data);
        setLoading(false);
      });
    };
    getReservations();
  }, []);

  if (loading) {
    return <div className="loader vh-100"></div>;
  }

  return (
    !loading && (
      <>
        <div className="px-5 py-3">
          <div
            className="col card mx-3"
            style={{
              height: "9rem",
            }}
          >
            <div className="card-body px-3 d-flex flex-column">
              <div className="row d-flex flex-column">
                <div
                  className="col fw-bolder text-primary mb-2"
                  style={{
                    fontSize: "1.5rem",
                  }}
                >
                  No. of Events
                </div>
                <div
                  className="col fw-bolder text-success"
                  style={{
                    fontSize: "1.4rem",
                  }}
                >
                  {noOfEvents}
                </div>
              </div>
            </div>
          </div>
          {/* <select
            className="form-select py-3"
            onChange={onStatusChange}
            value={reservationStatus}
          >
            {BOOKING_STATUS?.map((item, i) => (
              <option key={`booking-status-${i}`} value={item?.value}>
                {item?.label}
              </option>
            ))}
          </select>
          asdasdas */}
        </div>
        {/* <EventCalendar data={reservations} /> */}
      </>
    )
  );
};
export default Home;
