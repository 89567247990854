import { useState, useEffect } from "react";
import call from "utils/request";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Badge, Table } from "react-bootstrap";
import PaginationDetails from "./sanskriti-utsav/PaginationDetails";
import {
  capitalizeFirstChar,
  getFromForPagination,
  getToForPagination,
} from "utils/helper";
import { useDebounce } from "react-use";
import { get } from "lodash";

function UsersList() {
  const userInfo = JSON.parse(sessionStorage.getItem("userDetails"));
  const permissions = userInfo?.permissions;
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [paginationDetails, setPaginationDetails] = useState({});

  useEffect(() => {
    getUsersList();
  }, []);

  const getUsersList = async (page = 1) => {
    setLoading(true);
    await call(
      {
        method: "get",
        url: `/api/users/paginate?page=${page}`,
      },
      {
        Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
      }
    ).then(({ data: { docs, ...rest } }) => {
      setUsers(docs);
      setPaginationDetails({
        ...rest,
        pageNumbers: Array.from(
          { length: rest.totalPages },
          (_, index) => index + 1
        ),
      });
      setLoading(false);
    });
  };

  const getUsersWithSearch = async (page = 1) => {
    await call(
      {
        method: "get",
        url: `/api/users/paginate?searchText=${searchText}&page=${page}`,
      },
      {
        Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
      }
    ).then(({ data: { docs, ...rest } }) => {
      setUsers(docs);
      setPaginationDetails({
        ...rest,
        pageNumbers: Array.from(
          { length: rest.totalPages },
          (_, index) => index + 1
        ),
      });
    });
  };

  const handleEditClick = (data) => {
    const state = data;
    history.push(`/users/${data?._id}/edit`, state);
  };

  useDebounce(
    () => {
      getUsersWithSearch();
    },
    500,
    [searchText]
  );

  if (loading) {
    return <div className="loader vh-100"></div>;
  }

  const getTableBody = () => {
    return users.map(
      ({ _id: userId, name, email, userType, typeValue }, index) => (
        <tr key={index}>
          <td>{name}</td>
          <td>{email}</td>
          <td>
            <Badge>{capitalizeFirstChar(userType) || "-"}</Badge>
          </td>
          <td>{capitalizeFirstChar(typeValue) || "-"}</td>
        </tr>
      )
    );
  };

  return (
    !loading && (
      <>
        <div className="row">
          <div className="d-flex justify-content-between mb-2">
            <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value || "")}
              />
              <span className="mx-2 w-100">{`Showing ${getFromForPagination(
                paginationDetails
              )} - ${getToForPagination(paginationDetails)} of ${get(
                paginationDetails,
                "totalDocs",
                0
              )} Entries`}</span>
            </div>
            {permissions?.USER?.create && (
              <Link
                to="/users/invite"
                className="btn btn-primary mb-1 me-1 px-5 py-2"
              >
                Add
              </Link>
            )}
          </div>
          {!users?.length ? (
            <div className="d-flex align-items-center justify-content-center vh-100">
              <h2>No Users</h2>
            </div>
          ) : (
            <>
              <Table responsive className="header-border ">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Username</th>
                    <th>User Type</th>
                    <th>Type Value</th>
                    {/* <th>Actions</th> */}
                  </tr>
                </thead>
                <tbody>{getTableBody()}</tbody>
              </Table>
              <Row className="mt-2">
                <Col className="text-end ms-auto">
                  <PaginationDetails
                    paginationDetails={paginationDetails}
                    onClick={(page) => {
                      getUsersWithSearch(page);
                    }}
                  />
                </Col>
              </Row>
              {/* {users?.map((data, ind) => (
                <div className="card" key={`users-${ind}`}>
                  <div className="card-body pb-0 px-3">
                    <div className="row">
                      <div className="col-xl-3 col-lg-3 col-md-3">
                        <div className="review-bx d-flex align-items-center">
                          <div className="review-content">
                            <div className="d-flex flex-row align-items-start">
                              <h3 className="font-w500 me-3">{data?.name}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col align-items-center">
                        <div className="d-flex">
                          <div className="d-flex">
                            <div className="description-content">
                              <p className="font-w500 fw-bolder">Username: </p>
                            </div>
                            <div className="description-content">
                              <p className="font-w500">&nbsp; {data?.email}</p>
                            </div>
                          </div>
                          <div className="d-flex ms-3">
                            <div className="description-content">
                              <p className="font-w500 fw-bolder">User Type: </p>
                            </div>
                            <div className="description-content">
                              <p className="font-w500">
                                &nbsp; {capitalizeFirstChar(data?.userType)}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex ms-3">
                            <div className="description-content">
                              <p className="font-w500 fw-bolder">
                                Selected Value:{" "}
                              </p>
                            </div>
                            <div className="description-content">
                              <p className="font-w500">
                                &nbsp; {data?.typeValue}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-5 col-lg-5 col-md-5">
                        <div className="description-bx d-inline-block d-sm-flex align-items-center">
                          <div className="col-xl-4 col-lg-4 col-md-4 description-content">
                            <p className="font-w500 fw-bolder">Username:</p>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 description-content">
                            <p className="font-w500">{data?.email}</p>
                          </div>
                        </div>
                        <div className="description-bx d-inline-block d-sm-flex align-items-center">
                          <div className="col-xl-2 col-lg-2 col-md-2 description-content">
                            <p className="font-w500 fw-bolder">Role:</p>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 description-content">
                            <p className="font-w500">
                              {data?.roleDetails?.role}
                            </p>
                          </div>
                        </div>
                      </div>

                      TODO: Implement Edit
                      {permissions?.USER?.update && (
                        <div className="col-xl-1 col-lg-1 col-md-1 d-flex flex-column justify-content-between align-items-end">
                          <div className="d-flex justify-content-end align-items-start mb-3">
                            <button
                              className="btn btn-secondary btn-sm"
                              onClick={() => handleEditClick(data)}
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))} */}
            </>
          )}
        </div>
      </>
    )
  );
}

export default UsersList;
