import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import CancellationPolicyComponent from "jsx/components/CancellationPolicyComponent";
import call from "utils/request";

function CancellationPolicy() {
  const userInfo = JSON.parse(sessionStorage.getItem("userDetails"));
  const permissions = userInfo?.permissions;
  const history = useHistory();
  const location = useLocation();
  const pathName = location?.pathname?.split("/");
  const propertyId = location?.state ? location?.state?.id : pathName[2];
  const [showRule, setShowRule] = useState(false);
  const [rules, setRules] = useState([]);
  const [ruleArray, setRuleArray] = useState([]);
  const [policyData, setPolicyData] = useState();

  const inputStyle = {
    maxWidth: "45px",
    textAlign: "center",
  };

  useEffect(() => {
    const getPolicyData = async () => {
      await call({
        method: "get",
        url: `/api/cancellationPolicy/paginate?propertyId=${propertyId}`,
      }).then((res) => {
        setPolicyData(res?.data?.docs);
      });
    };
    getPolicyData();
  }, []);

  const onAddRule = () => {
    setShowRule(true);
    const initialRules = [
      {
        fromHours: 0,
        toHours: 24,
        refundType: "Percent",
        refundValue: 25,
      },
      {
        fromHours: 24,
        toHours: -1,
        refundType: "Percent",
        refundValue: 50,
      },
    ];
    setRules(initialRules);
  };

  const onAddRuleIncrement = () => {
    let tempRuleArr = [];
    const length = ruleArray && ruleArray?.length;
    const rule = {
      fromHours: ruleArray?.length
        ? ruleArray[length - 1]?.toHours
        : rules[0]?.toHours,
      toHours: ruleArray?.length
        ? ruleArray[length - 1]?.toHours + 12
        : rules[0]?.toHours + 12,
      refundValue: 0,
      refundType: "Percent",
    };
    tempRuleArr.push({ ...rule });
    setRuleArray([...ruleArray, ...tempRuleArr]);

    // setRules([...rules, ...tempRuleArr]);
  };

  const onRuleDelete = (index) => {
    let tempRuleArray = [...ruleArray];
    tempRuleArray.splice(index, 1);
    setRuleArray(tempRuleArray);
  };

  const onValueChange = (e, key, index) => {
    let tempArr = rules?.map((item, i) => {
      if (index === i) {
        item[key] = e.target.value;
      }
      return item;
    });
    setRules(tempArr);
  };

  const onKeyValueChange = (key, value, index) => {
    let tempArray = ruleArray?.map((item, i) => {
      if (index === i) {
        item[key] = value;
      }
      return item;
    });
    setRuleArray(tempArray);
  };

  const onSubmit = async () => {
    rules.splice(1, 0, ...ruleArray);
    rules[rules?.length - 1].fromHours = rules[rules?.length - 2]?.toHours;
    const cancellationData = {
      propertyId: propertyId,
      rules: rules,
    };

    await call({
      method: "post",
      url: `/api/cancellationPolicy`,
      data: cancellationData,
    }).then((res) => {
      if (res?.status === 201) {
        history.push(`/properties`);
      }
    });
  };

  return (
    <>
      <div className="row">
        <div className="column d-flex flex-wrap justify-content-between">
          <Button
            type="button"
            className="btn px-4"
            variant="light"
            onClick={() => history.goBack()}
            //   disabled={isSubmitting}
          >
            Back
          </Button>
          {(permissions?.PROPERTY?.create || permissions?.PROPERTY?.update) && (
            <Button
              type="button"
              className="btn btn-primary px-4"
              // variant="light"
              onClick={() => onAddRule()}
              disabled={policyData}
            >
              Add Policy Rule
            </Button>
          )}
        </div>
        <div>
          {showRule && (
            <>
              <p>
                If the guest cancels{" "}
                <span>
                  <input
                    value={rules[0]?.toHours}
                    type="number"
                    name="hours"
                    onChange={(e) => onValueChange(e, "toHours", 0)}
                    style={inputStyle}
                  />
                </span>{" "}
                hours before check-in date or post checkin, the guest will be
                charged{" "}
                <select
                  className="py-1 px-3"
                  onChange={(e) => onValueChange(e, "refundType", 0)}
                >
                  <option value="Percent">Percent</option>
                  <option value="Amount">Amount</option>
                </select>{" "}
                <input
                  name="amount"
                  type="number"
                  style={inputStyle}
                  value={rules[0]?.refundValue}
                  onChange={(e) => onValueChange(e, "refundValue", 0)}
                />{" "}
                of the total booking amount.
              </p>
              {permissions?.PROPERTY?.create && (
                <button
                  className="btn btn-success py-1 px-3 fs-6"
                  onClick={() => onAddRuleIncrement()}
                >
                  Add rule
                </button>
              )}
              {ruleArray?.map((item, i) => (
                <div className="d-flex flex-row" key={`rule-component-${i}`}>
                  <CancellationPolicyComponent
                    item={item}
                    index={i}
                    setValue={onKeyValueChange}
                  />
                  {permissions?.PROPERTY?.delete && (
                    <div
                      onClick={() => onRuleDelete(i)}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fas fa-square-xmark fa-2x"></i>
                    </div>
                  )}
                </div>
              ))}
              <p>
                If the guest cancels anytime after the booking is made and
                before the{" "}
                <span>
                  <input
                    value={
                      ruleArray?.length
                        ? ruleArray[ruleArray?.length - 1]?.toHours
                        : rules[rules?.length - 1]?.fromHours
                    }
                    disabled
                    style={inputStyle}
                    onChange={(e) =>
                      onValueChange(e, "toHours", rules?.length - 1)
                    }
                  />
                </span>{" "}
                of check-in date. The guest will be charged{" "}
                <select
                  className="py-1 px-3"
                  onChange={(e) =>
                    onValueChange(e, "refundType", rules?.length - 1)
                  }
                >
                  <option value="Percent">Percent</option>
                  <option value="Amount">Amount</option>
                </select>{" "}
                <input
                  style={inputStyle}
                  onChange={(e) =>
                    onValueChange(e, "refundValue", rules?.length - 1)
                  }
                  defaultValue={rules[rules?.length - 1]?.refundValue}
                />{" "}
                of the total booking amount.
              </p>
            </>
          )}
          {policyData?.map((policy, ind) => (
            <div key={`cancellation-policy-${ind}`} className="mt-3 ps-4">
              {policy?.toHours !== -1 ? (
                <h6>
                  If the guest cancels between {policy?.fromHours} and{" "}
                  {policy?.toHours} hours before check-in date or post checkin,
                  the guest will be charged{" "}
                  {policy?.refundType !== "Percent" ? "Rs." : ""}{" "}
                  {policy?.refundValue}{" "}
                  {policy?.refundType === "Percent" ? "%" : ""} of the total
                  booking amount.
                </h6>
              ) : (
                <h6>
                  If the guest cancels anytime after the booking is made and
                  before the {policy?.fromHours} of check-in date. The guest
                  will be charged{" "}
                  {policy?.refundType !== "Percent" ? "Rs." : ""}{" "}
                  {policy?.refundValue}{" "}
                  {policy?.refundType === "Percent" ? "%" : ""} of the total
                  booking amount.
                </h6>
              )}
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-end mt-5">
          {(permissions?.PROPERTY?.create || permissions?.PROPERTY?.update) && (
            <Button
              type="button"
              className="btn btn-primary px-4"
              // variant="light"
              onClick={() => onSubmit()}
              //   disabled={isSubmitting}
            >
              Finish Onboarding
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default CancellationPolicy;
