import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import call from "../../utils/request";
import swal from "sweetalert";
import { setUserInfo } from "utils/helper";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    register: {
      status: "idle",
    },
    verifyEmail: {
      status: "idle",
    },
    login: {
      status: "idle",
    },
    sendResetPassword: {
      status: "idle",
    },
    resetPassword: {
      status: "idle",
    },
    auth: {
      token: null,
      user: null,
      permissions: null,
    },
    errorMessage: "",
    successMessage: "",
    showLoading: false,
  },
  reducers: {
    updateStoreFromLocalStorage(state, action) {
      state.auth = action.payload;
    },
    signupConfirmedAction(state, action) {
      state.auth = action.payload;
      state.errorMessage = "";
      state.successMessage = "Signup Successfully Completed";
      state.showLoading = false;
    },
    loginConfirmedAction(state, action) {
      state.auth = action.payload;
      state.errorMessage = "";
      state.successMessage = "Login Successfully Completed";
      state.showLoading = false;
    },
    logoutAction(state) {
      state.errorMessage = "";
      state.successMessage = "";
      state.auth = {
        token: null,
        user: null,
        permissions: null,
      };
    },
    signupFailedAction(state, action) {
      state.errorMessage = action.payload;
      state.successMessage = "";
      state.showLoading = false;
    },
    loginFailedAction(state, action) {
      state.errorMessage = action.payload;
      state.successMessage = "";
      state.showLoading = false;
    },
    loadingToggleAction(state, action) {
      state.showLoading = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(registerUser.pending, (state, action) => {
        state.register.status = "loading";
        state.register.payload = action.payload;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.register.status = "succeeded";
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.register.status = "failed";
        state.register.error = action.error.message;
        state.register.payload = action.payload;
      })
      .addCase(verifyEmail.pending, (state, action) => {
        state.verifyEmail.status = "loading";
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.verifyEmail.status = "succeeded";
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.verifyEmail.status = "failed";
        state.verifyEmail.error = action.error.message;
        state.verifyEmail.payload = action.payload;
      })
      .addCase(login.pending, (state, action) => {
        state.login.status = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.login.status = "succeeded";
        state.auth = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.login.status = "failed";
        state.login.error = action.error.message;
        state.login.payload = action.payload;
        state.auth = {
          token: null,
          user: null,
          permissions: null,
        };
      })
      .addCase(sendResetPassword.pending, (state, action) => {
        state.sendResetPassword.status = "loading";
      })
      .addCase(sendResetPassword.fulfilled, (state, action) => {
        state.sendResetPassword.status = "succeeded";
        state.sendResetPassword = action.payload;
      })
      .addCase(sendResetPassword.rejected, (state, action) => {
        state.sendResetPassword.status = "failed";
        state.sendResetPassword.error = action.error.message;
        state.sendResetPassword.payload = action.payload;
      })
      .addCase(resetPassword.pending, (state, action) => {
        state.resetPassword.status = "loading";
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetPassword.status = "succeeded";
        state.resetPassword = action.payload;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPassword.status = "failed";
        state.resetPassword.error = action.error.message;
        state.resetPassword.payload = action.payload;
      });
  },
});

const { reducer, actions } = authSlice;
export const {
  signupConfirmedAction,
  loginConfirmedAction,
  logoutAction,
  signupFailedAction,
  loginFailedAction,
  loadingToggleAction,
  updateStoreFromLocalStorage,
} = actions;

export default reducer;

export function logout(dispatch) {
  sessionStorage.removeItem("userDetails");
  window.appHistory.push("/login");
  dispatch(actions.logoutAction());
}

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await call({
        method: "post",
        url: "/api/auth/reset-password",
        data,
      });
      swal("Message", "Password updated successfully", "success").then(() => {
        window.appHistory.push(`/login`);
      });
      return response.data;
    } catch (error) {
      if (error.response.data.message) {
        swal("Oops", error.response.data.message, "error");
      } else {
        swal("Oops", "Something went wrong", "error");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/register",
  async (data, { rejectWithValue }) => {
    try {
      console.log("data", data);
      const response = await call({
        method: "post",
        url: "/api/auth/register/seller",
        data,
      });
      swal("Message", response.data.message, "success").then(() => {
        window.appHistory.push(`/verify-email?email=${data.email}`);
      });
      return response.data;
    } catch (error) {
      if (error.response.data.message) {
        swal("Oops", error.response.data.message, "error");
      } else {
        swal("Oops", "Something went wrong", "error");
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const sendResetPassword = createAsyncThunk(
  "auth/sendResetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await call({
        method: "post",
        url: "/api/auth/send-password-reset",
        data,
      });
      swal(
        "Message",
        "Password reset email sent, please check the mail",
        "success"
      ).then(() => {
        window.appHistory.push(`/reset-password?email=${data.email}`);
      });
      return response.data;
    } catch (error) {
      if (error.response.data.message) {
        swal("Oops", error.response.data.message, "error");
      } else {
        swal("Oops", "Something went wrong", "error");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await call({
        method: "post",
        url: "/api/auth/verify-code/seller",
        data,
      });
      swal(
        "Message",
        "Account Verified Successfully. Please login",
        "success"
      ).then(() => {
        window.appHistory.push(`/login`);
      });
      return response.data;
    } catch (error) {
      if (error.response.data.message) {
        swal("Oops", error.response.data.message, "error");
      } else {
        swal("Oops", "Something went wrong", "error");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(
    new Date().getTime() + tokenDetails.expiresIn * 1000
  );
  sessionStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer) {
  setTimeout(() => {
    dispatch(logout());
  }, timer);
}

export function checkAutoLogin(dispatch) {
  const tokenDetailsString = sessionStorage.getItem("userDetails");
  let tokenDetails = "";
  if (!tokenDetailsString) {
    dispatch(logout());
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(logout());
    return;
  }
  dispatch(loginConfirmedAction(tokenDetails));

  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer);
}

export const login = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await call({
        method: "post",
        url: "/api/auth/login",
        data,
      });
      setUserInfo(response.data);
      const tenantId = response?.data?.user?.tenantId;
      if (response?.data?.user?.isFirstTimeLogin) {
        window.appHistory.push(`tenant/${tenantId}/edit`);
      } else {
        const permissions = response.data?.permissions;
        const permissionRoutes = {
          USER: "/users",
          EVENT: "/events",
          // ROLE: '/roles',
          // PROPERTY: '/properties',
          // TENANT: '/tenant',
          // COUPON: '/coupons',
          // REPORT: '/reports',
          // PAYMENT: '/payments',
        };

        const permissionsKeys = [
          "USER",
          "EVENT",
          // "ROLE",
          // 'PROPERTY',
          // // 'TENANT',
          // // 'COUPON',
          // // 'REPORT',
          // // 'PAYMENT',
        ];

        window.appHistory.push("/");
        // for (const key of permissionsKeys) {
        //   if (permissions[key]?.read) {
        //     const route = permissionRoutes[key];
        //     if (route) {
        //       window.appHistory.push(route);
        //       break;
        //     }
        //   }
        // }
      }
      // sessionStorage.setItem('userDetails', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      if (error.response.data.message) {
        swal("Oops", error.response.data.message, "error");
      } else {
        swal("Oops", "Something went wrong", "error");
      }
      return rejectWithValue(error.response.data);
    }
  }
);
