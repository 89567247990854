import { configureStore, combineReducers } from '@reduxjs/toolkit';

// import postsReducer from './reducers/PostsReducer';
// import authReducer from './reducers/AuthReducer';
// import todoReducers from './reducers/Reducers';
import authSlice from './slices/AuthSlice';

const rootReducer = combineReducers({
  // posts: postsReducer,
  // auth: authReducer,
  // todoReducers,
  auth: authSlice
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(/* any custom middleware */),
  devTools: true, // Enable Redux DevTools extension
});

export default store;
