import axios from "axios";
import moment from "moment";
import { DATE_FORMAT } from "./constants";

export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const
  // let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;
  // expiredTimer = setTimeout(() => {
  //   alert('Token expired');

  //   sessionStorage.removeItem('userDetails');
  //   delete axios.defaults.headers.common.Authorization;

  //   window.location.href = '/login';
  // }, timeLeft);
};

export const setUserInfo = (user) => {
  const token = user?.token;
  axios.defaults.headers.common.Authorization = `Bearer ${token?.accessToken}`;
  sessionStorage.setItem("userDetails", JSON.stringify(user));
  tokenExpired(token?.expiresIn);
};

export const getUserInfo = () => {
  return JSON.parse(sessionStorage.getItem("userDetails"));
};

export const getMediaType = (media) => {
  const temp = media?.split(".");
  return temp[temp?.length - 1];
};

export const dateStringToMillis = (dateString, format = DATE_FORMAT) => {
  return !isNaN(dateString)
    ? moment(dateString).valueOf()
    : moment(dateString, format).valueOf();
};

export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

// export const getBase64 = async (url) => {
//   const data = await fetch(url);
//   const blob = await data.blob();
//   return new Promise((resolve) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(blob);
//     reader.onloadend = () => {
//       const base64data = reader.result;
//       resolve(base64data)
//     };
//   });
// };

export function capitalizeFirstChar(str = "") {
  return `${str.charAt(0).toUpperCase()}${str.substring(1)}`;
}

export function getFromForPagination({ page = 1, limit = 50 }) {
  return (page - 1) * limit + 1;
}

export function getToForPagination({
  page = 1,
  limit = 50,
  totalPages = 0,
  totalDocs = 0,
}) {
  return totalPages === page ? totalDocs : page * limit;
}
