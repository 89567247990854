import React, { useEffect, useState, useCallback } from "react";
import { isEmpty, map } from "lodash";
import call from "utils/request";
import { Modal, Spinner, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone";
import { REGISTRATION_FIELD_MAP } from "utils/constants";
import * as XLSX from "xlsx/xlsx.mjs";
import moment from "moment-timezone";

const Registrations = () => {
  const [loading, setLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [noOfRegistrations, setNoOfRegistrations] = useState(0);
  const [fileToBeUploaded, setFileToBeUploaded] = useState([]);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const userInfo = JSON.parse(sessionStorage.getItem("userDetails"));
  const permissions = userInfo?.permissions;

  const getReservations = async () => {
    setLoading(false);
    await call({
      method: "get",
      url: "/api/common/count-registrations",
    }).then(({ data }) => {
      setNoOfRegistrations(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getReservations();
  }, []);

  const onDropFile = useCallback((acceptedFiles) => {
    setFileToBeUploaded(acceptedFiles);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      onDrop: onDropFile,
      accept: {
        "text/csv": [".csv"],
      },
      multiple: false,
    });

  const uploadFile = async () => {
    setIsFileUploading(true);
    const formData = new FormData();
    formData.append("media", acceptedFiles[0]);

    await call({
      method: "post",
      url: `/api/registrations/upload`,
      data: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            title: "Uploaded!",
            text: res?.data?.message || "File has been uploaded",
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
          });
          setIsFileUploading(false);
          setShowModal(false);
          setFileToBeUploaded([]);
          getReservations();
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Upload Error!",
          text: "Unable to process the CSV. Try again sometime later.",
          icon: "error",
          showConfirmButton: false,
          timer: 1000,
        });
        setIsFileUploading(false);
        setShowModal(false);
        setFileToBeUploaded([]);
        getReservations();
      });
  };

  const downloadRegistrations = (data = []) => {
    var ws = XLSX.utils.json_to_sheet(data);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Registrations");
    XLSX.writeFile(
      wb,
      `sanskriti-utsav-registrations-${moment().format("DD-MMM-YYYY")}.csv`
    );
  };

  const getRegistrationsForExport = async () => {
    setIsExporting(true);
    await call({
      method: "get",
      url: "/api/registrations/for-export",
    })
      .then(({ data }) => {
        const shapedData = [];
        for (const entry of data) {
          const { _id, createdAt, updatedAt, fullZonePath, ...rest } = entry;
          const shapedObj = {};
          Object.entries(rest).forEach(([key, value]) => {
            shapedObj[REGISTRATION_FIELD_MAP[key]] = value;
          });
          shapedData.push(shapedObj);
        }
        if (isEmpty(shapedData)) {
          Swal.fire({
            title: "No Registrations to Export",
            text: "Something went wrong or you don't have the permission!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          downloadRegistrations(shapedData);
        }
        setIsExporting(false);
      })
      .catch((err) => {
        console.log("Entering Catch", err);
        Swal.fire({
          title: "Unable to export",
          text: "Something went wrong or you don't have the permission!",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
        setIsExporting(false);
      });
  };

  if (loading) {
    return <div className="loader vh-100"></div>;
  }

  return (
    !loading && (
      <>
        <div className="d-flex justify-content-end mb-2">
          <div
            className="btn btn-primary mb-1 me-1 px-5 py-2"
            onClick={getRegistrationsForExport}
          >
            {isExporting ? (
              <Spinner
                className="spinner-border spinner-border-sm"
                role="status"
              />
            ) : (
              "Export Registrations"
            )}
          </div>
          {permissions?.REGISTRATION?.create && (
            <div
              className="btn btn-primary mb-1 me-1 px-5 py-2"
              onClick={() => setShowModal(true)}
            >
              Upload Data
            </div>
          )}
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>Upload Data</Modal.Header>
            <Modal.Body>
              <div
                {...getRootProps({ className: "dropzone" })}
                className="dz-drag-hover dz-preview-img  dz-message dropzone border p-5 c-pointer"
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the file here ...</p>
                ) : (
                  <p>Drag and drop your file here, or click to select file.</p>
                )}
              </div>
              {!isEmpty(fileToBeUploaded) && (
                <div className="text-sm ml-4" sm={12}>
                  Uploaded Files:
                  <ul>
                    {map(fileToBeUploaded || [], (item, index) => (
                      <li key={index}>{item.path}</li>
                    ))}
                  </ul>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn btn-primary mb-1 me-1 px-5 py-2"
                disabled={isEmpty(fileToBeUploaded) || isFileUploading}
                onClick={uploadFile}
              >
                {isFileUploading ? (
                  <Spinner
                    className="spinner-border spinner-border-sm"
                    role="status"
                  />
                ) : (
                  "Upload"
                )}
              </Button>
              <div
                className="btn btn-light mb-1 me-1 px-5 py-2"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </div>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="px-5 py-3">
          <div
            className="col card mx-3"
            style={{
              height: "9rem",
            }}
          >
            <div className="card-body px-3 d-flex flex-column">
              <div className="row d-flex flex-column">
                <div
                  className="col fw-bolder text-primary mb-2"
                  style={{
                    fontSize: "1.5rem",
                  }}
                >
                  No. of Registrations
                </div>
                <div
                  className="col fw-bolder text-success"
                  style={{
                    fontSize: "1.4rem",
                  }}
                >
                  {noOfRegistrations}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};
export default Registrations;
