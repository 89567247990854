import { useState, useEffect } from 'react';
import call from 'utils/request';
import { Link } from 'react-router-dom';
import { getUserInfo } from 'utils/helper';
import img4 from '../../images/big/img4.jpg';

function TenantDetails() {
  const userInfo = getUserInfo();
  const permissions = userInfo?.permissions;
  const [tenantDetails, setTenantDetails] = useState();
  useEffect(() => {
    const getTenantDetail = async () => {
      await call({
        method: 'get',
        url: `/api/tenant/${userInfo?.user?.tenantId}`,
      }).then((res) => {
        setTenantDetails(res?.data);
      });
    };
    getTenantDetail();
  }, [userInfo?.user?.tenantId]);
  return (
    <>
      <div className="row">
        <div className="mb-2">
          <div className="card">
            <div className="card-body pb-0 px-3">
              <div className="row">
                <div className="col-xl-5 col-lg-5 col-md-5">
                  <div className="review-bx d-flex align-items-center">
                    <div className="review-img">
                      <img
                        src={
                          tenantDetails?.bannerImages?.length
                            ? tenantDetails?.bannerImages[0]?.image
                            : img4
                        }
                        alt="123"
                      />
                    </div>
                    <div className="review-content">
                      <div className="d-flex flex-row align-items-start">
                        <h3 className="font-w500 me-3">
                          {tenantDetails?.name}
                        </h3>
                      </div>
                      <p className="review-date font-w400">
                        {tenantDetails?.address}
                      </p>
                      <p className="review-date font-w400">
                        {tenantDetails?.phone}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-3 col-md-3">
                  <div className="description-bx d-inline-block d-sm-flex align-items-center">
                    {/* <div className="description-content">
                      <p className="font-w500">{tenantDetails.description}</p>
                    </div> */}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 d-flex flex-column">
                  <div className="d-flex justify-content-center mb-3">
                    {permissions?.TENANT?.update && (
                      <Link
                        to={`/tenant/${tenantDetails?._id}/edit`}
                        className="btn btn-secondary btn-sm"
                      >
                        <i className="fas fa-pencil-alt"></i>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TenantDetails;
