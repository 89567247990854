import React from "react";
import { useForm } from "react-hook-form";
import call from "utils/request";
import { Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

const RoomCleaning = () => {
  const history = useHistory();
  const location = useLocation();
  const userInfo = JSON.parse(sessionStorage.getItem("userDetails"));
  const permissions = userInfo?.permissions;
  const pathName = location?.pathname?.split("/");
  const propertyId = location?.state ? location?.state?.id : pathName[2];
  const roomId = location?.state
    ? location?.state?.roomId
    : pathName[4] !== "create"
    ? pathName[4]
    : undefined;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    let createData = {
      propertyId,
      roomId,
      date: moment(data?.date)
        .startOf()
        .add(5, "hours")
        .add(30, "minutes")
        .valueOf(),
      count: parseInt(data?.count),
    };

    await call(
      {
        method: "post",
        url: "/api/rooms/forCleaning",
        data: createData,
      },
      {
        Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          Swal.fire({
            title: "Marked!",
            text: res?.data?.message || "Room has been marked for cleaning",
            icon: "success",
            showConfirmButton: false,
            timer: 500,
          });
          history.push(`/properties/${propertyId}/rooms`);
        }
      })
      .catch((err) => {
        const errMsg = err?.response?.data?.message;
        console.log({ errMsg });
      });
  };

  return (
    <>
      <div>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label>
                        Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        {...register("date", {
                          required: "Date is required",
                        })}
                        className="form-control"
                        placeholder="Select Date"
                      />
                      {errors?.date && (
                        <Form.Text className="text-danger">
                          {errors?.date?.message}
                        </Form.Text>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>
                        Count <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        {...register("count", {
                          required: "Room Count is required",
                        })}
                        className="form-control"
                        placeholder="Enter Room Count Value. If the room is cleaned, enter 0 as count."
                      />
                      {errors?.count && (
                        <Form.Text className="text-danger">
                          {errors?.count?.message}
                        </Form.Text>
                      )}
                    </div>
                    <div className="column d-flex justify-content-between mb-3">
                      <Button
                        type="button"
                        className="btn px-4"
                        variant="light"
                        onClick={() => history.goBack()}
                      >
                        Cancel
                      </Button>
                      {(permissions?.ROOM?.create ||
                        permissions?.ROOM?.update) && (
                        <Button
                          type="submit"
                          className="btn btn-primary px-4"
                          disabled={isSubmitting}
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                    <p>
                      Note: Room cleaning is a temporary service and will not
                      block any rooms from reserving. If you want to block the
                      room, mark it as 'Out of Order'.
                    </p>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoomCleaning;
