import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import call from "utils/request";
import { Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";
import {
  JANPATH,
  MANDAL,
  TEHSIL,
  SUPER_ADMIN,
  NORMAL_USER,
} from "utils/constants";
import { capitalizeFirstChar } from "utils/helper";
import { get } from "lodash";
import moment from "moment-timezone";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";

function EventForm() {
  const userInfo = JSON.parse(sessionStorage.getItem("userDetails"));
  const permissions = userInfo?.permissions;
  const history = useHistory();
  const location = useLocation();
  const pathName = location?.pathname?.split("/");
  const eventId = location?.state
    ? location?.state?.id
    : pathName[2] !== "create"
    ? pathName[2]
    : undefined;
  const [eventData, setEventData] = useState({});
  const [errMessage, setErrMessage] = useState("");
  const [config, setConfig] = useState([]);

  useEffect(() => {
    const getConfig = async () => {
      await call({
        method: "get",
        url: "/api/config/all",
      }).then(({ data }) => {
        setConfig(data);
      });
    };
    getConfig();
  }, []);

  useEffect(() => {
    if (eventId) {
      const getEventData = async () => {
        await call({
          method: "get",
          url: `/api/events/${eventId}`,
        }).then((res) => {
          setEventData(res?.data);
        });
      };
      getEventData();
    }
  }, [eventId]);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting },
    watch,
    getValues,
  } = useForm({
    defaultValues: {},
  });

  const watchedField = watch("zoneType");
  const mandalWatchedField = watch("mandal");
  const janpathWatchedField = watch("janpath");
  const watchedDateTime = watch("eventDateTime");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (Object.keys(eventData).length) {
          setValue("eventName", eventData?.eventName);
          setValue("location", eventData?.location);
          setValue("zoneType", eventData?.zoneType);
          setValue("zoneTypeValue", eventData?.zoneTypeValue);
          setValue("description", eventData?.description);
          setValue("mandal", eventData?.mandal);
          setValue("janpath", eventData?.janpath);
          setValue("tehsil", eventData?.tehsil);
          setValue("eventDateTime", moment(eventData?.eventDateTime));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [eventData, setValue]);

  const getFullZonePath = (zoneType = "", { mandal, janpath, tehsil }) => {
    switch (zoneType) {
      case MANDAL:
        return mandal;
      case JANPATH:
        return `${mandal}_${janpath}`;
      case TEHSIL:
        return `${mandal}_${janpath}_${tehsil}`;
      default:
        return "";
    }
  };

  const onSubmit = async (data) => {
    let createData = {
      eventName: data?.eventName,
      location: data?.location,
      zoneType: data?.zoneType,
      zoneTypeValue: data?.[data?.zoneType],
      description: data?.description,
      eventDateTime: moment(data?.eventDateTime).valueOf(),
      fullZonePath: getFullZonePath(data?.zoneType, data),
    };

    if (!Object.keys(eventData).length) {
      await call({
        method: "post",
        url: "/api/events",
        data: createData,
      })
        .then((res) => {
          if (res?.status === 201) {
            Swal.fire({
              title: "Created!",
              text: res?.data?.message || "Event has been created",
              icon: "success",
              showConfirmButton: false,
              timer: 500,
            });
            history.push("/events");
            // history.push(`/properties/${res?.data?._id}/image`, {
            //   id: res?.data?._id,
            // });
          }
        })
        .catch((err) => {
          const errMsg = err?.response?.data?.message;
          setErrMessage(errMsg);
          // for (let item of items) {
          //   // for (let key in item) {
          //   setError('afterSubmit', item, { message: item?.messages[0] });
          //   // }
          // }
          // setTimeout(() => {
          //   setError('');
          // }, 3000);
        });
    } else {
      await call({
        method: "patch",
        url: `/api/events/${eventId}`,
        data: createData,
      })
        .then((res) => {
          if (res?.status === 204) {
            Swal.fire({
              title: "Updated!",
              text: res?.data?.message || "EVent has been updated",
              icon: "success",
              showConfirmButton: false,
              timer: 500,
            });
            history.push("/events");
            // history.push(`/properties/${propertyData?._id}/image`, {
            //   id: propertyData?.id,
            // });
          }
        })
        .catch((err) => {
          const items = err?.response?.data?.errors;
          for (let item of items) {
            // for (let key in item) {
            setError("afterSubmit", item, { message: item?.messages[0] });
            // }
          }
        });
    }
  };

  const getTypeOptions = () => {
    const userRole = get(userInfo, "user.roleDetails.role", "");
    const userType = get(userInfo, "user.userType", "");
    if (userRole === SUPER_ADMIN) {
      return [MANDAL, JANPATH, TEHSIL];
    }

    if (userRole === NORMAL_USER) {
      switch (userType) {
        case MANDAL:
          return [MANDAL, JANPATH, TEHSIL];
        case JANPATH:
          return [JANPATH, TEHSIL];
        case TEHSIL:
          return [TEHSIL];
        default:
          return [];
      }
    }
  };

  const OptionsComponent = useCallback(() => {
    return (
      <>
        {[MANDAL, JANPATH, TEHSIL].includes(getValues("zoneType")) && (
          <div className="form-group mb-3 col-md-6">
            <label>
              Select Mandal <span className="text-danger">*</span>
            </label>
            <select
              id="mandal"
              className="form-control"
              {...register("mandal", {
                required: "Mandal is required",
              })}
            >
              <option value="">Select Mandal</option>
              {config
                .map((x) => x.name)
                ?.map((item, i) => (
                  <option key={`mandal-${i}`} value={item}>
                    {capitalizeFirstChar(item)}
                  </option>
                ))}
            </select>
            {errors?.mandal && (
              <Form.Text className="text-danger">
                {errors?.mandal?.message}
              </Form.Text>
            )}
          </div>
        )}
        {(getValues("zoneType") === TEHSIL ||
          getValues("zoneType") === JANPATH) && (
          <div className="form-group mb-3 col-md-6">
            <label>
              Select Janpath <span className="text-danger">*</span>
            </label>
            <select
              id="janpath"
              className="form-control"
              {...register("janpath", {
                required: "janpath is required",
              })}
            >
              <option value="">Select Janpath</option>
              {config
                .find((x) => x.name === getValues("mandal"))
                ?.children.map((x) => x.name)
                ?.map((item, i) => (
                  <option key={`janpath-${i}`} value={item}>
                    {capitalizeFirstChar(item)}
                  </option>
                ))}
            </select>
            {errors?.janpath && (
              <Form.Text className="text-danger">
                {errors?.janpath?.message}
              </Form.Text>
            )}
          </div>
        )}
        {getValues("zoneType") === TEHSIL && (
          <div className="form-group mb-3 col-md-6">
            <label>
              Select Tehsil <span className="text-danger">*</span>
            </label>
            <select
              id="tehsil"
              className="form-control"
              {...register("tehsil", {
                required: "tehsil is required",
              })}
            >
              <option value="">Select Tehsil</option>
              {config
                .find((x) => x.name === getValues("mandal"))
                ?.children.find((x) => x.name === getValues("janpath"))
                ?.children.map((x) => x.name)
                ?.map((item, i) => (
                  <option key={`tehsil-${i}`} value={item}>
                    {capitalizeFirstChar(item)}
                  </option>
                ))}
            </select>
            {errors?.tehsil && (
              <Form.Text className="text-danger">
                {errors?.tehsil?.message}
              </Form.Text>
            )}
          </div>
        )}
      </>
    );
  }, [
    watchedField,
    mandalWatchedField,
    janpathWatchedField,
    config,
    errors,
    getValues,
    register,
  ]);

  return (
    <div>
      {" "}
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          {/* <div className="card-header">
            <h4 className="card-title">Horizontal Form</h4>
          </div> */}
          <div className="card-body">
            <div className="basic-form">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Event Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("eventName", {
                        required: "Event Name is required",
                      })}
                      className="form-control"
                      placeholder="Enter event name"
                    />
                    {errors?.eventName && (
                      <Form.Text className="text-danger">
                        {errors?.eventName?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Location</label>
                    <input
                      type="text"
                      {...register("location")}
                      className="form-control"
                      placeholder="Enter event location"
                    />
                    {errors?.location && (
                      <Form.Text className="text-danger">
                        {errors?.location?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Event Date and Time</label>
                    <DateTime
                      inputProps={{
                        placeholder: "Select Event Date",
                      }}
                      name="eventDateTime"
                      initialValue={moment().valueOf()}
                      timeFormat="hh:mm A"
                      value={watchedDateTime}
                      onChange={(value) => {
                        setValue("eventDateTime", value);
                      }}
                      dateFormat="DD-MM-YYYY"
                    />
                    {errors?.eventDateTime && (
                      <Form.Text className="text-danger">
                        {errors?.eventDateTime?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Zone Type <span className="text-danger">*</span>
                    </label>
                    <select
                      id="zoneType"
                      className="form-control"
                      {...register("zoneType", {
                        required: "Zone Type is required",
                      })}
                    >
                      <option value="">Select Zone Type</option>
                      {getTypeOptions()?.map((item, i) => (
                        <option key={`zoneType-${i}`} value={item}>
                          {capitalizeFirstChar(item)}
                        </option>
                      ))}
                    </select>
                    {errors?.zoneType && (
                      <Form.Text className="text-danger">
                        {errors?.zoneType?.message}
                      </Form.Text>
                    )}
                  </div>
                  <OptionsComponent />
                  <div className="form-group mb-3 col-md-6">
                    <label>Description</label>
                    <textarea
                      {...register("description")}
                      className="form-control"
                      rows="4"
                      id="description"
                      placeholder="Enter description"
                    ></textarea>
                    {errors?.description && (
                      <Form.Text className="text-danger">
                        {errors?.description?.message}
                      </Form.Text>
                    )}
                  </div>
                </div>
                <div className="row">
                  {errMessage !== "" && (
                    <span className="text-center text-danger">
                      {errMessage}
                    </span>
                  )}
                </div>
                <div className="column d-flex justify-content-between">
                  <Button
                    type="button"
                    className="btn px-4"
                    variant="light"
                    onClick={() => history.goBack()}
                    //   disabled={isSubmitting}
                  >
                    Back
                  </Button>
                  {(permissions?.EVENT?.create ||
                    permissions?.EVENT?.update) && (
                    <Button
                      type="submit"
                      className="btn btn-primary px-4"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventForm;
