import { useState, useEffect, useCallback } from "react";
import call from "utils/request";
import { Link, useLocation, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import img4 from "../../images/big/img4.jpg";
import { Button } from "react-bootstrap";

function RoomsList() {
  const location = useLocation();
  const history = useHistory();
  const userInfo = JSON.parse(sessionStorage.getItem("userDetails"));
  const permissions = userInfo?.permissions;
  const pathName = location?.pathname?.split("/");
  const propertyId = location?.state ? location?.state?.id : pathName[2];
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);

  const getRoomsList = useCallback(async () => {
    setLoading(true);
    await call({
      method: "get",
      url: `/api/rooms/paginate?propertyId=${propertyId}`,
    }).then((res) => {
      setRooms(res?.data?.docs);
      setLoading(false);
    });
  }, [propertyId]);

  useEffect(() => {
    if (propertyId) {
      getRoomsList();
    }
  }, [propertyId, getRoomsList]);

  const onDelete = async (id) => {
    await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete the room?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#F8857D",
      dangerMode: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await call(
          {
            method: "delete",
            url: `/api/rooms/${id}`,
          },
          {
            Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
          }
        ).then((res) => {
          if (res?.status === 204) {
            Swal.fire("Deleted", "Room has been deleted!", "success");
            setTimeout(() => getRoomsList(), 500);
          }
        });
      }
    });
  };

  if (loading) {
    return <div className="loader vh-100"></div>;
  }

  return (
    !loading && (
      <>
        <div className="row">
          <div className="d-flex justify-content-between mb-2">
            <Link
              to={`/properties`}
              className="btn btn-warning mb-1 me-1 px-3 py-2"
            >
              <i className="bi bi-arrow-left fs-20"></i> property list
            </Link>
            {permissions?.ROOM?.create && (
              <Link
                to={`/properties/${propertyId}/rooms/create`}
                className="btn btn-primary mb-2 me-1 px-5 py-2"
              >
                Add
              </Link>
            )}
          </div>
          {!rooms?.length ? (
            <div className="mt-5 py-5" style={{ minHeight: "60vh" }}>
              <h2 className="text-center">No data</h2>
              <p className="text-center">Add atleast 1 room to continue</p>
            </div>
          ) : (
            <>
              {rooms?.map((data, ind) => (
                <div className="card" key={`rooms-list-${ind}`}>
                  <div className="card-body pb-0 px-3">
                    <div className="row">
                      <div className="col-xl-5 col-lg-5 col-md-6">
                        <div className="review-bx d-flex align-items-center">
                          <div className="review-img">
                            <img
                              src={data.media.length ? data.media[0] : img4}
                              alt="123"
                            />
                          </div>
                          <div className="review-content">
                            <div className="d-flex flex-row align-items-start">
                              <h4 className="font-w500 me-3">{data.type}</h4>
                              {data?.isActive ? (
                                <span className="badge badge-success fw-400 px-3">
                                  Active
                                </span>
                              ) : (
                                <span className="badge badge-danger fw-400 px-3">
                                  Inactive
                                </span>
                              )}
                            </div>
                            <p className="font-w400 m-1">
                              default occupency:{" "}
                              <span className="font-w600">
                                {data?.defaultAdults}
                              </span>
                            </p>
                            <p className="font-w400 m-1">
                              default price:{" "}
                              <span className="font-w600">{data?.price}</span>
                            </p>
                            <p className="d-block font-w400 m-1">
                              amenities:{" "}
                              {data?.amenities?.map((item) => {
                                return (
                                  <span
                                    className="font-w600"
                                    key={`amenities-list-${item?._id}`}
                                  >
                                    {item?.name},{" "}
                                  </span>
                                );
                              })}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4">
                        <div className="description-bx d-inline-block d-sm-flex align-items-center">
                          <div className="description-content">
                            <p className="font-w500">{data.description}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 d-flex flex-column justify-content-between">
                        <div className="d-flex justify-content-between align-items-start mb-3">
                          {permissions?.ROOM?.update && (
                            <Link
                              to={`/properties/${propertyId}/rooms/${data?._id}/edit`}
                              className="btn btn-secondary shadow btn-sm me-4"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                          )}
                          {permissions?.ROOM?.delete && (
                            <button
                              type="button"
                              className="btn btn-primary shadow btn-sm"
                              onClick={() => onDelete(data?._id)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          )}
                        </div>
                        <div className="d-flex justify-content-between align-items-end mb-3">
                          {permissions?.ROOM?.update && (
                            <>
                              <Link
                                to={`/properties/${propertyId}/rooms/${data?._id}/cleaning`}
                                className="btn btn-success shadow btn-sm me-2"
                              >
                                Mark Rooms for Cleaning
                              </Link>
                              <Link
                                to={`/properties/${propertyId}/rooms/${data?._id}/room-out-of-order`}
                                className="btn btn-info shadow btn-sm ms-2"
                              >
                                Mark Rooms for Out of Order
                              </Link>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="column d-flex flex-wrap justify-content-between">
          <Button
            type="button"
            className="btn px-4"
            variant="light"
            onClick={() => history.goBack()}
            //   disabled={isSubmitting}
          >
            Back
          </Button>
          {(permissions?.PROPERTY?.create || permissions?.PROPERTY?.update) && (
            <Button
              type="button"
              className="btn btn-primary px-4"
              disabled={!rooms?.length}
              onClick={() =>
                history.push(`/properties/${propertyId}/cancellation-policy`, {
                  id: propertyId,
                })
              }
            >
              Next
            </Button>
          )}
        </div>
      </>
    )
  );
}

export default RoomsList;
