import React from 'react';

const CancellationPolicyComponent = ({ item, setValue, index }) => {
  const inputStyle = {
    maxWidth: '45px',
    textAlign: 'center',
  };

  const onTimeChange = (e) => {
    setValue('toHours', e.target.value, index);
  };
  const onAmountChange = (e) => {
    setValue('refundValue', e.target.value, index);
  };

  const onTypeSelect = (e) => {
    setValue('refundType', e.target.value, index);
  };
  return (
    <>
      <p>
        If the guest cancels between{' '}
        <span>
          <input
            type="number"
            name="to_hours"
            style={inputStyle}
            defaultValue={item?.toHours}
            onChange={onTimeChange}
          />
        </span>{' '}
        and{' '}
        <span>
          <input
            name="from_hours"
            type="number"
            style={inputStyle}
            value={item?.fromHours}
            disabled
          />
        </span>{' '}
        hours before check-in date or post checkin, the guest will be charged{' '}
        <select className="py-1 px-3" onChange={onTypeSelect}>
          <option value="Percent">Percent</option>
          <option value="Amount">Amount</option>
        </select>{' '}
        <input
          type="number"
          name="amount"
          style={inputStyle}
          defaultValue={item?.refundValue}
          onChange={onAmountChange}
        />{' '}
        of the total booking amount.
      </p>
    </>
  );
};

export default CancellationPolicyComponent;
