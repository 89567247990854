import { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { getMediaType } from 'utils/helper';
import call from 'utils/request';
import Swal from 'sweetalert2';
import DropFile from 'jsx/components/CustomComponents/DropFile';

function BannerImageUpload() {
  const history = useHistory();
  const location = useLocation();
  const pathName = location?.pathname?.split('/');
  const [selectedFile, setSelectedFile] = useState([]);
  const [bannerImages, setBannerImages] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [editable, setEditable] = useState(false);
  const tenantId = location?.state ? location?.state?.id : pathName[2];

  const deleteIconStyle = {
    position: 'relative',
    left: '90px',
    top: '10px',
    // height: '10px',
    // width: '10px',
    cursor: 'pointer',
  };
  const editIconStyle = {
    position: 'relative',
    left: '80px',
    top: '10px',
    // height: '10px',
    // width: '10px',
    cursor: 'pointer',
  };
  const {
    register,
    handleSubmit,
    setValue,
    // setError,
    reset,
    formState: { isSubmitting },
  } = useForm();

  const getTenantData = useCallback(async () => {
    await call({
      method: 'get',
      url: `/api/tenant/${tenantId}`,
    }).then((res) => {
      const data = res?.data?.bannerImages;
      data?.map((item, ind) => {
        setValue(`text${ind}`, item?.text);
        return item;
      });
      setBannerImages(data);
    });
  }, [tenantId]);

  useEffect(() => {
    if (tenantId) {
      getTenantData();
    }
  }, [tenantId, getTenantData]);

  const onSubmit = async (data) => {
    const mediaLength = Object.keys(selectedFile)?.length;
    const formData = new FormData();
    let i;

    for (i = 0; i < mediaLength; i++) {
      formData.append(
        `banners[${i}][text]`,
        Object.values(data)[bannerImages?.length + i]
      );
      formData.append(`banners[${i}][image]`, selectedFile[i]);
    }
    // for (i = 0; i < bannerImages?.length; i++) {
    //   formData.append(`banners[${i}][text]`, Object.values(data)[i]);
    // }
    setSubmitting(true);
    await call({
      method: 'post',
      url: `/api/tenant/banners/${tenantId}`,
      // url: `/api/properties/media/64bbdffcf445f8002d08b720`,
      data: formData,
    }).then((res) => {
      if (res.status === 200) {
        Swal.fire({
          title: 'Uploaded!',
          text: res?.data?.message || 'Banner images has been uploaded',
          icon: 'success',
          showConfirmButton: false,
          timer: 500,
        });
        reset();
        setSubmitting(false);
        history.push(`/tenant`);
      }
    });
  };

  const onDeleteMedia = async (index) => {
    await Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete the media?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#F8857D',
      dangerMode: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (bannerImages?.length) {
          await call({
            method: 'delete',
            url: `/api/tenant/banners/${tenantId}/${index}`,
          }).then((res) => {
            if (res?.status === 204) {
              Swal.fire('Deleted', 'Media has been deleted!', 'success');
              setTimeout(() => getTenantData(), 500);
            }
          });
        }
      }
    });
  };

  const onEditMedia = async (ind, item) => {
    await Swal.fire({
      title: 'Banner Content',
      html: `
      <div>
        <label>Banner text</label>
        <input className="form-control" id="swal-input-1" placeholder="Type your content here.." value="${item?.text}" type="text" />
      </div>
      <div className="align-self-start" >
        <label>Font color</label>
        <input type="color" name="font-color" className="form-control" id="swal-input-2" value="${item?.fontColor}" />
      </div>
      <div>
        <label>Font size</label>
        <input type="number" className="form-control" id="swal-input-3" value="${item?.fontSize}" />
      </div>`,
      preConfirm: () => {
        return {
          text: document.getElementById('swal-input-1').value,
          fontColor: document.getElementById('swal-input-2').value,
          fontSize: document.getElementById('swal-input-3').value,
        };
      },
      showCancelButton: true,
      confirmButtonText: 'Save',
      confirmButtonColor: '#07bc0c',
      cancelButtonColor: '#e74c3c',
    }).then(async (result) => {
      console.log(result, 'res');
      if (result?.isConfirmed && Object.keys(result?.value).length) {
        const updateData = {
          ...result?.value,
        };
        await call({
          method: 'patch',
          url: `/api/tenant/banners/${tenantId}/${ind}`,
          data: updateData,
        }).then((res) => {
          if (res?.status === 200) {
            Swal.fire('Updated', 'Banner text has been updated!', 'success');
            setTimeout(() => getTenantData(), 500);
          }
        });
      }
    });
  };

  console.log(bannerImages, 'banner');
  return (
    <div className="row">
      <div className="col-xl-12 col-lg-12">
        <div className="card h-100">
          <div className="card-body">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <h3 className="mb-3">Add photos to enrich user experience!</h3>
              <div className="mb-4">
                <DropFile
                  setSelectedFile={setSelectedFile}
                  isSubmitting={submitting}
                />
                {/* {bannerImages?.map((item, ind) => (
                  <div key={`banner-img-${item?._id}`}>
                    <label>Banner text{ind + 1}</label>
                    <input
                      type="text"
                      {...register(`text${ind}`, {
                        required: 'Banner text is required',
                      })}
                    />
                  </div>
                ))} */}
                {selectedFile?.map((file, i) => (
                  <div
                    key={`banner-img-${i}`}
                    className="mt-2 d-flex flex-column"
                  >
                    <label>Banner text{bannerImages?.length + i + 1}</label>{' '}
                    <input
                      type="text"
                      {...register(`text`)}
                      className="w-25 form-control"
                    />
                  </div>
                ))}
              </div>
              <div style={{ marginBottom: '7rem' }}>
                <p>Preview</p>
                {bannerImages?.length ? (
                  <div className="d-grid img-preview">
                    {bannerImages?.map((item, index) => {
                      return (
                        <div
                          key={`property-item-${index}`}
                          className="me-4"
                          style={{ height: '150px', width: '180px' }}
                        >
                          <span
                            style={editIconStyle}
                            onClick={() => onEditMedia(index, item)}
                          >
                            <i className="fa-solid fa-square-pen fa-2x"></i>
                          </span>
                          <span
                            style={deleteIconStyle}
                            onClick={() => onDeleteMedia(index)}
                          >
                            <i className="fas fa-circle-xmark fa-2x"></i>
                          </span>
                          {getMediaType(item?.image) === 'mp4' ? (
                            <video
                              width="130px"
                              height="130px"
                              style={{
                                objectFit: 'cover',
                                borderRadius: '10px',
                              }}
                              controls
                              alt="banner-video"
                            >
                              <source src={item?.image} type="video/mp4" />
                            </video>
                          ) : (
                            <>
                              <img
                                src={item?.image}
                                height="130px"
                                width="130px"
                                alt="banner-img"
                                style={{
                                  borderRadius: '10px',
                                  objectFit: 'cover',
                                }}
                              />
                              {/* {editable ? (
                                <input
                                  type="text"
                                  className='mt-1 form-control'
                                  {...register(`text${index}`, {
                                    required: 'Banner text is required',
                                  })}
                                />
                              ) : ( */}
                              <p className="fw-bold mt-1">{item?.text}</p>
                              {/* )} */}
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="column d-flex justify-content-between align-self-end">
                <Button
                  type="button"
                  className="btn px-4"
                  variant="light"
                  onClick={() =>
                    history.push(`/tenant/${tenantId}/edit`, { id: tenantId })
                  }
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary px-4"
                  disabled={isSubmitting}
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerImageUpload;
