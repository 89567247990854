import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';

import card1 from './../../../../images/event-card-img/pic-1.jpg';
import card2 from './../../../../images/event-card-img/pic-2.jpg';
import card3 from './../../../../images/event-card-img/pic-3.jpg';
import card4 from './../../../../images/event-card-img/pic-4.jpg';

const roomListData = [
	{image:card1},
	{image:card2},
	{image:card3},
	{image:card4},
	{image:card1},
	{image:card3}
];
const roomListData2 = [
	{image:card2},
	{image:card3},
	{image:card4},	
	{image:card3}
];
const roomListData3 = [
	{image:card4},
	{image:card1}
	
];


const ListData = () =>{
	
	const [data, setData] = useState(
		document.querySelectorAll("#example2_wrapper tbody tr")
	);
	const sort = 8;
	const activePag = useRef(0);

	// Active data
	const chageData = (frist, sec) => {
		for (var i = 0; i < data.length; ++i) {
			if (i >= frist && i < sec) {
				data[i].classList.remove("d-none");
			} else {
				data[i].classList.add("d-none");
			}
		}
	};
   // use effect
   useEffect(() => {
      setData(document.querySelectorAll("#example2_wrapper tbody tr"));
      //chackboxFun();
	}, []);

  
   // Active pagginarion
   activePag.current === 0 && chageData(0, sort);
   // paggination
   let paggination = Array(Math.ceil(data.length / sort))
      .fill()
      .map((_, i) => i + 1);

   // Active paggination & chage data
	const onClick = (i) => {
		activePag.current = i;
		chageData(activePag.current * sort, (activePag.current + 1) * sort);
		//settest(i);
	};
	
	const chackbox = document.querySelectorAll(".sorting_1 input");
	const motherChackBox = document.querySelector(".sorting_asc input");
	const chackboxFun = (type) => {
      for (let i = 0; i < chackbox.length; i++) {
        const element = chackbox[i];
        if (type === "all") {
            if (motherChackBox.checked) {
               element.checked = true;
            } else {
               element.checked = false;
            }
        }else {
            if (!element.checked) {
               motherChackBox.checked = false;
               break;
            } else {
               motherChackBox.checked = true;
            }
        }
      }
    };
	
	return(
		<>	
			<div className="table-responsive card">
				<div className="table-responsive">
					<div id="example2_wrapper" className="dataTables_wrapper no-footer">
						<table className="table border-0 display mb-4 dataTablesCard booking-table table-responsive-lg " id="guestTable-all6">
							<thead>
								<tr>
									<th className="sorting_asc bg-none">
										<div className="form-check style-3">
											<input type="checkbox" onClick={() => chackboxFun("all")} className="form-check-input" id="checkAll" required=""/>
										</div>
									</th>
									<th>Room Name</th>
									<th>Bed Type</th>
									<th>Room Floor</th>
									<th>Status</th>
									<th>Book Date</th>
									<th className="bg-none"></th>
								</tr>
							</thead>
							<tbody>
								{roomListData.map((data, ind)=>(
									<tr key={ind}>
										<td className="sorting_1">
											<div className="form-check  style-3">
												<input className="form-check-input" onClick={() => chackboxFun()} 
													id={"customCheckBox4"+ ind} type="checkbox" value="" 
												/>
											</div>
										</td>
										<td>
											<div className="guest-bx">
												<img className="me-4" src={data.image} alt="" />
												<div>
													<span className="text-primary fs-16 font-w400 text-nowrap">#R-001235</span>
													<h4 className="mb-0 mt-1 fs-18 font-w500 text-nowrap"><Link to={"#"} className="text-black">404 Brrom Str, Fl2</Link></h4>
												</div>
											</div>
										</td>
										<td>
											<div>
												<span className="fs-15 font-w500 text-nowrap">Double Bed</span>
											</div>
										</td>
										<td>
											<div>
												<span className="fs-15 font-w500 text-nowrap">Floor G-05</span>
											</div>
										</td>
										<td>
											<div>
												{ind % 2 ===0 ? 
													<span className="btn btn-success btn-sm">AVAILABLE</span>
												: 
													<span className="btn btn-primary btn-sm">SOLDOUT</span>
												}
											</div>
										</td>
										<td>
											<div>
												<h5 className="fs-15 font-w500 text-nowrap">Oct 29th, 2020</h5>
												<span className="fs-14 font-w500 text-nowrap">08:29 AM</span>
											</div>
										</td>
										<td>
											<Dropdown>
												<Dropdown.Toggle as="a" className="btn-link i-false" data-bs-toggle="dropdown" aria-expanded="false">
													<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#717579" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
														<path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#717579" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
														<path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#717579" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
													</svg>
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<Dropdown.Item>Edit</Dropdown.Item>
													<Dropdown.Item>Delete</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</td>
									</tr>
								))}

							</tbody>
						</table>
						<div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
							<div className="dataTables_info">
								Showing {activePag.current * sort + 1} to{" "}
								{data.length > (activePag.current + 1) * sort
									? (activePag.current + 1) * sort
									: data.length}{" "}
								of {data.length} entries
							</div>
							<div
								className="dataTables_paginate paging_simple_numbers mb-0"
								id="example2_paginate"
							>
								<Link
									className="paginate_button previous disabled"
									to="/room"
									onClick={() =>
									   activePag.current > 0 &&
									   onClick(activePag.current - 1)
									}
								 >
									{/* <i className="fa fa-angle-double-left" aria-hidden="true"></i> */}
									Previous
								</Link>
								<span>
									{paggination.map((number, i) => (
									   <Link
										  key={i}
										  to="/room"
										  className={`paginate_button  ${
											 activePag.current === i ? "current" : ""
										  } `}
										  onClick={() => onClick(i)}
									   >
										  {number}
									   </Link>
									))}
								</span>

								<Link
									className="paginate_button next"
									to="/room"
									onClick={() =>
									   activePag.current + 1 < paggination.length &&
									   onClick(activePag.current + 1)
									}
								>
									{/* <i className="fa fa-angle-double-right" aria-hidden="true"></i> */}
									Next
								</Link>
							</div>
						</div>						
					</div>	
				</div>
			</div>	
		</>
	)
}

const ListData2 = () =>{
	return(
		<>	
			<div className="table-responsive card">
				<div className="table-responsive">
					<table className="table border-0 display mb-4 dataTablesCard booking-table table-responsive-lg " id="guestTable-all6">
						<thead>
							<tr>
								<th className="bg-none">
									<div className="form-check style-3">
										<input className="form-check-input" type="checkbox" value="" id="checkAll" />
									</div>
								</th>
								<th>Room Name</th>
								<th>Bed Type</th>
								<th>Room Floor</th>
								<th>Status</th>
								<th>Book Date</th>
								<th className="bg-none"></th>
							</tr>
						</thead>
						<tbody>
							{roomListData2.map((data, ind)=>(
								<tr key={ind}>
									<td>
										<div className="form-check  style-3">
										  <input className="form-check-input" type="checkbox" value="" />
										</div>
									</td>
									<td>
										<div className="guest-bx">
											<img className="me-4" src={data.image} alt="" />
											<div>
												<span className="text-primary fs-16 font-w400 text-nowrap">#R-001235</span>
												<h4 className="mb-0 mt-1 fs-18 font-w500 text-nowrap"><Link to={"#"} className="text-black">404 Brrom Str, Fl2</Link></h4>
											</div>
										</div>
									</td>
									<td>
										<div>
											<span className="fs-15 font-w500 text-nowrap">Double Bed</span>
										</div>
									</td>
									<td>
										<div>
											<span className="fs-15 font-w500 text-nowrap">Floor G-05</span>
										</div>
									</td>
									<td>
										<div>
											<span className="btn btn-success btn-sm">AVAILABLE</span>
											
										</div>
									</td>
									<td>
										<div>
											<h5 className="fs-15 font-w500 text-nowrap">Oct 29th, 2020</h5>
											<span className="fs-14 font-w500 text-nowrap">08:29 AM</span>
										</div>
									</td>
									<td>
										<Dropdown>
											<Dropdown.Toggle as="a" className="btn-link i-false" data-bs-toggle="dropdown" aria-expanded="false">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#717579" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#717579" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#717579" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item>Edit</Dropdown.Item>
												<Dropdown.Item>Delete</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</td>
								</tr>
							))}

						</tbody>
					</table>					
				</div>
			</div>	
		</>
	)
}
const ListData3 = () =>{
	return(
		<>	
			<div className="table-responsive card">
				<div className="table-responsive">
					<table className="table border-0 display mb-4 dataTablesCard booking-table table-responsive-lg " id="guestTable-all6">
						<thead>
							<tr>
								<th className="bg-none">
									<div className="form-check style-3">
										<input className="form-check-input" type="checkbox" value="" id="checkAll" />
									</div>
								</th>
								<th>Room Name</th>
								<th>Bed Type</th>
								<th>Room Floor</th>
								<th>Status</th>
								<th>Book Date</th>
								<th className="bg-none"></th>
							</tr>
						</thead>
						<tbody>
							{roomListData3.map((data, ind)=>(
								<tr key={ind}>
									<td>
										<div className="form-check  style-3">
										  <input className="form-check-input" type="checkbox" value="" />
										</div>
									</td>
									<td>
										<div className="guest-bx">
											<img className="me-4" src={data.image} alt="" />
											<div>
												<span className="text-primary fs-16 font-w400 text-nowrap">#R-001235</span>
												<h4 className="mb-0 mt-1 fs-18 font-w500 text-nowrap"><Link to={"#"} className="text-black">404 Brrom Str, Fl2</Link></h4>
											</div>
										</div>
									</td>
									<td>
										<div>
											<span className="fs-15 font-w500 text-nowrap">Double Bed</span>
										</div>
									</td>
									<td>
										<div>
											<span className="fs-15 font-w500 text-nowrap">Floor G-05</span>
										</div>
									</td>
									<td>
										<div>
											<span className="btn btn-primary btn-sm">SOLDOUT</span>											
										</div>
									</td>
									<td>
										<div>
											<h5 className="fs-15 font-w500 text-nowrap">Oct 29th, 2020</h5>
											<span className="fs-14 font-w500 text-nowrap">08:29 AM</span>
										</div>
									</td>
									<td>
										<Dropdown>
											<Dropdown.Toggle as="a" className="btn-link i-false" data-bs-toggle="dropdown" aria-expanded="false">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#717579" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#717579" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#717579" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item>Edit</Dropdown.Item>
												<Dropdown.Item>Delete</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</td>
								</tr>
							))}

						</tbody>
					</table>					
				</div>
			</div>	
		</>
	)
}
export {ListData2, ListData3};
export default ListData;