import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import call from "utils/request";
import { Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";

function PropertyAdd() {
  const userInfo = JSON.parse(sessionStorage.getItem("userDetails"));
  const permissions = userInfo?.permissions;
  const history = useHistory();
  const location = useLocation();
  const pathName = location?.pathname?.split("/");
  const propertyId = location?.state
    ? location?.state?.id
    : pathName[2] !== "create"
    ? pathName[2]
    : undefined;
  const [propertyData, setPropertyData] = useState({});
  const [propertyType, setPropertyType] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState([]);
  const [errMessage, setErrMessage] = useState("");
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(null);
  const [states, setStates] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    const getPropertyType = async () => {
      await call({
        method: "get",
        url: `/api/propertyType/paginate`,
      }).then((res) => {
        setPropertyType(res?.data?.docs);
      });
    };
    getPropertyType();
  }, []);

  useEffect(() => {
    if (propertyId) {
      const getPropertyData = async () => {
        await call({
          method: "get",
          url: `/api/properties/${propertyId}`,
        }).then((res) => {
          setPropertyData(res?.data);
        });
      };
      getPropertyData();
    }
  }, [propertyId]);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [countriesResponse, currenciesResponse] = await Promise.all([
          call({ method: "get", url: "/api/countries/paginate" }),
          call({ method: "get", url: "/api/currencies/paginate" }),
        ]);
        setCountries(countriesResponse?.data?.docs);
        setCurrencies(currenciesResponse?.data?.docs);
        if (Object.keys(propertyData).length) {
          setValue("name", propertyData?.name);
          setValue("website", propertyData?.website);
          setValue("description", propertyData?.description);
          setValue("tax_number", propertyData?.taxIdentificationNumber);
          setValue("tax_percentage", propertyData?.taxPercentage);
          setValue("address", propertyData?.address);
          setValue("contact_name", propertyData?.contactName);
          setValue("phone_number", propertyData?.phone);
          setValue("email", propertyData?.email);
          setValue("country", propertyData?.country);
          setValue("city", propertyData?.city);
          setValue("propertyCurrency", propertyData?.propertyCurrency);
          setValue("pincode", propertyData?.pincode);
          setValue("star_rating", propertyData?.starRating);
          setValue("latitude", propertyData?.latitude);
          setValue("longitude", propertyData?.longitude);
          setValue("property_type", propertyData?.propertyTypeId);
          setSelectedPropertyType(propertyData?.propertyType);
          setCountry(propertyData?.country);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [propertyData, setValue]);

  useEffect(() => {
    if (country) {
      const fetchState = async () => {
        const [stateResponse] = await Promise.all([
          call({
            method: "get",
            url: `/api/states/paginate?countryCode=${country}`,
          }),
        ]);
        setStates(stateResponse?.data?.docs);
        if (Object.keys(propertyData).length)
          setValue("state", propertyData?.state);
      };
      fetchState();
    }
  }, [propertyData, setValue, country]);

  const onSubmit = async (data) => {
    let createData = {
      autoAssignReservations: false,
      multiRoomMode: false,
      disableAutoRoomMovement: false,
      allowCheckInBeforeCheckout: false,
      name: data?.name,
      description: data?.description,
      taxIdentificationNumber: data?.tax_number,
      taxPercentage: data?.tax_percentage,
      address: data?.address,
      contactName: data?.contact_name,
      phone: data?.phone_number,
      email: data?.email,
      propertyCurrency: data?.propertyCurrency,
      city: data?.city,
      state: data?.state,
      country: data?.country,
      pincode: data?.pincode,
      starRating: data?.star_rating,
      latitude: data?.latitude,
      longitude: data?.longitude,
      checkinHours: 24,
      propertyTypeId: data?.property_type,
    };

    if (data?.website !== "") {
      createData = { ...createData, website: data?.website };
    }

    if (!Object.keys(propertyData).length) {
      await call({
        method: "post",
        url: "/api/properties",
        data: createData,
      })
        .then((res) => {
          if (res?.status === 201) {
            Swal.fire({
              title: "Created!",
              text: res?.data?.message || "Property has been created",
              icon: "success",
              showConfirmButton: false,
              timer: 500,
            });
            history.push(`/properties/${res?.data?._id}/image`, {
              id: res?.data?._id,
            });
          }
        })
        .catch((err) => {
          const errMsg = err?.response?.data?.message;
          setErrMessage(errMsg);
          // for (let item of items) {
          //   // for (let key in item) {
          //   setError('afterSubmit', item, { message: item?.messages[0] });
          //   // }
          // }
          // setTimeout(() => {
          //   setError('');
          // }, 3000);
        });
    } else {
      await call({
        method: "patch",
        url: `/api/properties/${propertyId}`,
        data: createData,
      })
        .then((res) => {
          if (res?.status === 204) {
            Swal.fire({
              title: "Updated!",
              text: res?.data?.message || "Property has been updated",
              icon: "success",
              showConfirmButton: false,
              timer: 500,
            });
            history.push(`/properties/${propertyData?._id}/image`, {
              id: propertyData?.id,
            });
          }
        })
        .catch((err) => {
          const items = err?.response?.data?.errors;
          for (let item of items) {
            // for (let key in item) {
            setError("afterSubmit", item, { message: item?.messages[0] });
            // }
          }
        });
    }
  };

  return (
    <div>
      {" "}
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          {/* <div className="card-header">
            <h4 className="card-title">Horizontal Form</h4>
          </div> */}
          <div className="card-body">
            <div className="basic-form">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("name", { required: "Name is required" })}
                      className="form-control"
                      placeholder="Enter name"
                    />
                    {errors?.name && (
                      <Form.Text className="text-danger">
                        {errors?.name?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Property type <span className="text-danger">*</span>
                    </label>
                    <select
                      defaultValue={selectedPropertyType}
                      onChange={(e) => setSelectedPropertyType(e.target.value)}
                      id="inputState"
                      className="form-control"
                      {...register("property_type", {
                        required: "Property type is required",
                      })}
                    >
                      <option value="" disabled>
                        Select...
                      </option>
                      {propertyType?.map((item, i) => (
                        <option key={`property-type-${i}`} value={item?._id}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    {errors?.property_type && (
                      <Form.Text className="text-danger">
                        {errors?.property_type?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Website <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("website")}
                      className="form-control"
                      placeholder="Enter website"
                    />
                    {errors?.website && (
                      <Form.Text className="text-danger">
                        {errors?.website?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Tax Identifiction Number{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("tax_number", {
                        required: "Tax identification number is required",
                      })}
                      className="form-control"
                      placeholder="Enter tax identification number"
                    />
                    {errors?.tax_number && (
                      <Form.Text className="text-danger">
                        {errors?.tax_number?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Tax percentage <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      {...register("tax_percentage", {
                        required: "Tax percentage is required",
                      })}
                      className="form-control"
                      placeholder="Enter tax percentage"
                    />
                    {errors?.tax_percentage && (
                      <Form.Text className="text-danger">
                        {errors?.tax_percentage?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Address <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("address", {
                        required: "Address is required",
                      })}
                      className="form-control"
                      placeholder="Enter address"
                    />
                    {errors?.address && (
                      <Form.Text className="text-danger">
                        {errors?.address?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^\S+@\S+$/i,
                          message: "Invalid email address",
                        },
                      })}
                      className="form-control"
                      placeholder="Enter email"
                    />
                    {errors?.email && (
                      <Form.Text className="text-danger">
                        {errors?.email?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Contact Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("contact_name", {
                        required: "Contact name is required",
                      })}
                      className="form-control"
                      placeholder="Enter contact name"
                    />
                    {errors?.contact_name && (
                      <Form.Text className="text-danger">
                        {errors?.contact_name?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Phone Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      {...register("phone_number", {
                        required: "Phone number is required",
                      })}
                      className="form-control"
                      placeholder="Enter phone number"
                    />
                    {errors?.phone_number && (
                      <Form.Text className="text-danger">
                        {errors?.phone_number?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Country <span className="text-danger">*</span>
                    </label>
                    <select
                      id="country"
                      className="form-control"
                      {...register("country", {
                        required: "Country is required",
                      })}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Country
                      </option>
                      {countries?.map((item, i) => (
                        <option key={`country-${i}`} value={item.code}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    {errors?.country && (
                      <Form.Text className="text-danger">
                        {errors?.country?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      State <span className="text-danger">*</span>
                    </label>
                    <select
                      id="state"
                      className="form-control"
                      {...register("state", {
                        required: "State is required",
                      })}
                    >
                      <option value="" disabled>
                        Select State
                      </option>
                      {states?.map((item, i) => (
                        <option key={`state-${i}`} value={item.code}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    {errors?.state && (
                      <Form.Text className="text-danger">
                        {errors?.state?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      City <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("city", {
                        required: "City is required",
                      })}
                      className="form-control"
                      placeholder="Enter city"
                    />
                    {errors?.city && (
                      <Form.Text className="text-danger">
                        {errors?.city?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Property Currency <span className="text-danger">*</span>
                    </label>
                    <select
                      id="propertyCurrency"
                      className="form-control"
                      {...register("propertyCurrency", {
                        required: "Property currency is required",
                      })}
                    >
                      <option value="" disabled>
                        Select Property Currency
                      </option>
                      {currencies?.map((item, i) => (
                        <option key={`currency-${i}`} value={item.code}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    {errors?.propertyCurrency && (
                      <Form.Text className="text-danger">
                        {errors?.propertyCurrency?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Pincode <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      {...register("pincode", {
                        required: "Pincode is required",
                      })}
                      className="form-control"
                      placeholder="Enter pincode"
                    />
                    {errors?.pincode && (
                      <Form.Text className="text-danger">
                        {errors?.pincode?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Star Rating <span className="text-danger">*</span>
                    </label>
                    <select
                      defaultValue={""}
                      id="inputState"
                      className="form-control"
                      {...register("star_rating", {
                        required: "Rating is required",
                      })}
                    >
                      <option value="" disabled>
                        Select...
                      </option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                    </select>
                    {errors?.star_rating && (
                      <Form.Text className="text-danger">
                        {errors?.star_rating?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Latitude <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("latitude", {
                        required: "Latitude is required",
                        pattern: {
                          value: /^[-+]?[0-9]+(\.[0-9]+)*$/,
                          message: "Invalid email address",
                        },
                      })}
                      className="form-control"
                      placeholder="Enter latitude"
                    />
                    {errors?.latitude && (
                      <Form.Text className="text-danger">
                        {errors?.latitude?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Longitude <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("longitude", {
                        required: "Longitude is required",
                        pattern: {
                          value: /^[-+]?[0-9]+(\.[0-9]+)*$/,
                          message: "Invalid email address",
                        },
                      })}
                      className="form-control"
                      placeholder="Enter longitude"
                    />
                    {errors?.longitude && (
                      <Form.Text className="text-danger">
                        {errors?.longitude?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Description <span className="text-danger">*</span>
                    </label>
                    <textarea
                      {...register("description", {
                        required: "Description is required",
                      })}
                      className="form-control"
                      rows="4"
                      id="description"
                      placeholder="Enter description"
                    ></textarea>
                    {errors?.description && (
                      <Form.Text className="text-danger">
                        {errors?.description?.message}
                      </Form.Text>
                    )}
                  </div>
                </div>
                <div className="row">
                  {errMessage !== "" && (
                    <span className="text-center text-danger">
                      {errMessage}
                    </span>
                  )}
                </div>
                <div className="column d-flex justify-content-between">
                  <Button
                    type="button"
                    className="btn px-4"
                    variant="light"
                    onClick={() => history.goBack()}
                    //   disabled={isSubmitting}
                  >
                    Back
                  </Button>
                  {(permissions?.PROPERTY?.create ||
                    permissions?.PROPERTY?.update) && (
                    <Button
                      type="submit"
                      className="btn btn-primary px-4"
                      disabled={isSubmitting}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyAdd;
