import { useState, useEffect } from "react";
import call from "utils/request";
import img4 from "../../images/big/img4.jpg";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { CLIENT_BASE_URL } from "utils/constants";

function PropertiesList() {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const userInfo = JSON.parse(sessionStorage.getItem("userDetails"));
  const permissions = userInfo?.permissions;

  useEffect(() => {
    getPropertiesList();
  }, []);

  const getPropertiesList = async () => {
    setLoading(true);
    await call({
      method: "get",
      url: `/api/properties/paginate?tenantId=${userInfo?.user?.tenantId}`,
    }).then((res) => {
      setProperties(res?.data?.docs);
      setLoading(false);
    });
  };

  const onDelete = async (id) => {
    await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete the property?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#F8857D",
      dangerMode: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await call({
          method: "delete",
          url: `/api/properties/${id}`,
        }).then((res) => {
          if (res?.status === 204) {
            Swal.fire("Deleted", "Room has been deleted!", "success");
            setTimeout(() => getPropertiesList(), 500);
          }
        });
      }
    });
  };

  if (loading) {
    return <div className="loader vh-100"></div>;
  }

  return (
    !loading && (
      <>
        <div className="row">
          {permissions?.PROPERTY?.create && (
            <div className="d-flex justify-content-end mb-2">
              <Link
                to="/properties/create"
                className="btn btn-primary mb-1 me-1 px-5 py-2"
              >
                Add
              </Link>
            </div>
          )}
          {!properties?.length ? (
            <div className="d-flex align-items-center justify-content-center vh-100">
              <h2>No data</h2>
            </div>
          ) : (
            <>
              {properties?.map((data, ind) => (
                <div className="card" key={`property-${ind}`}>
                  <div className="card-body pb-0 px-3">
                    <div className="row">
                      <div className="col-xl-5 col-lg-5 col-md-5">
                        <div className="review-bx d-flex align-items-center">
                          <div className="review-img">
                            <img
                              src={data.media.length ? data.media[0] : img4}
                              alt="123"
                            />
                          </div>
                          <div className="review-content">
                            <div className="d-flex flex-row align-items-start">
                              <h3 className="font-w500 me-3">{data.name}</h3>
                              {/* {data?.isActive ? (
                                <span className="badge badge-success fw-400 px-3">
                                  Active
                                </span>
                              ) : (
                                <span className="badge badge-danger fw-400 px-3">
                                  Inactive
                                </span>
                              )} */}
                            </div>
                            <p className="review-date font-w400">
                              {data?.address}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-3 col-md-3">
                        <div className="description-bx d-inline-block d-sm-flex align-items-center">
                          <div className="description-content">
                            <p className="font-w500">{data.description}</p>
                          </div>
                          <div className="rating-bx">
                            <div className="rating-content d-flex d-sm-block mt-2 mt-sm-0 d-flex d-sm-block mt-2 mt-sm-0"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-4 d-flex flex-column justify-content-between">
                        <div className="d-flex justify-content-between align-items-start mb-3">
                          {permissions?.PROPERTY?.update && (
                            <Link
                              to={`/properties/${data?._id}/edit`}
                              className="btn btn-secondary btn-sm"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                          )}
                          {permissions?.PROPERTY?.delete && (
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              onClick={() => onDelete(data?._id)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          )}
                          {permissions?.ROOM?.read && (
                            <Link
                              to={`/properties/${data?._id}/rooms`}
                              className="btn btn-info shadow btn-sm me-1"
                            >
                              Rooms
                            </Link>
                          )}
                        </div>
                        <div className="d-flex justify-content-end align-items-end mb-3">
                          <Link
                            to={`/properties/${data?._id}/dashboard`}
                            className="btn btn-success shadow btn-sm me-5"
                          >
                            View dashboard
                          </Link>
                          <a
                            // href={`${data?.website}/property/${data?._id}`}
                            href={`${CLIENT_BASE_URL}/home/${data?._id}`}
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-info shadow btn-sm me-1"
                          >
                            View Property
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </>
    )
  );
}

export default PropertiesList;
