import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import call from "utils/request";
import { Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";

function RoomCreate() {
  const userInfo = JSON.parse(sessionStorage.getItem("userDetails"));
  const permissions = userInfo?.permissions;
  const history = useHistory();
  const location = useLocation();
  const pathName = location?.pathname?.split("/");
  const propertyId = location?.state ? location?.state?.id : pathName[2];
  // const propertyId = '64be4390f445f8002d08c8fc';
  const roomId = location?.state
    ? location?.state?.roomId
    : pathName[4] !== "create"
    ? pathName[4]
    : undefined;
  const [roomData, setRoomData] = useState({});
  const [amenities, setAmenities] = useState();
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const getamenities = async () => {
      await call({
        method: "get",
        url: "/api/amenities/paginate",
      }).then((res) => {
        setAmenities(res?.data?.docs);
      });
    };
    getamenities();
  }, []);

  useEffect(() => {
    if (roomId) {
      const getRoomData = async () => {
        await call({
          method: "get",
          url: `/api/rooms/${roomId}`,
        }).then((res) => {
          setRoomData(res?.data);
        });
      };
      getRoomData();
    }
  }, [roomId]);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(() => {
    if (Object.keys(roomData).length) {
      setValue("type", roomData?.type);
      setValue("description", roomData?.description);
      setValue("room_nos", roomData?.roomNos);
      setValue("default_adults", roomData?.defaultAdults);
      setValue("max_adults", roomData?.maxNoOfAdults);
      setValue("max_children", roomData?.maxNoOfChildren);
      setValue("price", roomData?.price);
      setValue("additional_price_adult", roomData?.pricePerAdditionalAdult);
      setValue("additional_price_children", roomData?.pricePerAdditionalChild);
      setValue("room_id_prefix", roomData?.roomIdPrefix);
      setValue("room_color", roomData?.roomColorCode);
      setValue("amenities", roomData?.amenities);
      setSelectedAmenities(roomData?.amenities?.map((item) => item?._id));
    }
  }, [roomData, setValue]);

  const onAminitiesSelect = (eve) => {
    const checkedIndex = amenities?.findIndex(
      (item) => item._id === eve.target.value
    );
    let tempList = [...amenities];
    if (!selectedAmenities.includes(eve.target.value)) {
      selectedAmenities.push(eve.target.value);
      tempList[checkedIndex].checked = true;
    } else {
      const selectedJob = selectedAmenities.indexOf(eve.target.value);
      selectedAmenities.splice(selectedJob, 1);
      setSelectedAmenities(selectedAmenities);
      tempList[checkedIndex].checked = false;
    }

    setAmenities(tempList);
  };

  const onSubmit = async (data) => {
    const createData = {
      type: data?.type,
      description: data?.description,
      roomNos: data?.room_nos,
      defaultAdults: data?.default_adults,
      maxNoOfAdults: data?.max_adults,
      maxNoOfChildren: data?.max_children,
      price: data?.price,
      pricePerAdditionalAdult: data?.additional_price_adult,
      pricePerAdditionalChild: data?.additional_price_children,
      roomIdPrefix: data?.room_id_prefix,
      roomColorCode: data?.room_color,
      propertyId: propertyId,
      amenities: selectedAmenities,
    };

    if (!selectedAmenities?.length) {
      setErrorMessage("Select atleast minimum 1 amenity");
    }

    if (!Object.keys(roomData).length && selectedAmenities.length) {
      setErrorMessage("");
      await call({
        method: "post",
        url: "/api/rooms",
        data: createData,
      })
        .then((res) => {
          if (res?.status === 201) {
            Swal.fire({
              title: "Created!",
              text: res?.data?.message || "Room has been created",
              icon: "success",
              showConfirmButton: false,
              timer: 500,
            });
            history.push(
              `/properties/${propertyId}/rooms/${res?.data?._id}/image`,
              {
                id: propertyId,
                roomId: res?.data?._id,
              }
            );
          }
        })
        .catch((err) => {
          const items = err?.response?.data?.errors;
          for (let item of items) {
            // for (let key in item) {
            setError("afterSubmit", item, { message: item?.messages[0] });
            // }
          }
        });
    } else if (Object.keys(roomData).length && selectedAmenities.length) {
      setErrorMessage("");
      await call({
        method: "patch",
        url: `/api/rooms/${roomId}`,
        data: createData,
      })
        .then((res) => {
          if (res?.status === 204) {
            Swal.fire({
              title: "Updated!",
              text: res?.data?.message || "Room has been updated",
              icon: "success",
              showConfirmButton: false,
              timer: 500,
            });
            history.push(`/properties/${propertyId}/rooms/${roomId}/image`, {
              id: propertyId,
              roomId: roomId,
            });
          }
        })
        .catch((err) => {
          const items = err?.response?.data?.errors;
          for (let item of items) {
            // for (let key in item) {
            setError("afterSubmit", item, { message: item?.messages[0] });
            // }
          }
        });
    }
  };

  return (
    <div>
      {" "}
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          {/* <div className="card-header">
            <h4 className="card-title">Horizontal Form</h4>
          </div> */}
          <div className="card-body">
            <div className="basic-form">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Room type <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("type", {
                        required: "Room type is required",
                      })}
                      className="form-control"
                      placeholder="Enter type"
                    />
                    {errors?.type && (
                      <Form.Text className="text-danger">
                        {errors?.type?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Room nos <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("room_nos", {
                        required: "Room nos are required",
                      })}
                      className="form-control"
                      placeholder="Enter room nos"
                    />
                    {errors?.room_nos && (
                      <Form.Text className="text-danger">
                        {errors?.room_nos?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Default adults <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("default_adults", {
                        required: "Default adult count is required",
                      })}
                      className="form-control"
                      placeholder="Enter default adult count"
                    />
                    {errors?.default_adults && (
                      <Form.Text className="text-danger">
                        {errors?.default_adults?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Max of adults <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("max_adults", {
                        required: "Max adult count is required",
                      })}
                      className="form-control"
                      placeholder="Enter max adult count"
                    />
                    {errors?.max_adults && (
                      <Form.Text className="text-danger">
                        {errors?.max_adults?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Max of children <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("max_children", {
                        required: "Max children count is required",
                      })}
                      className="form-control"
                      placeholder="Enter max children count"
                    />
                    {errors?.max_children && (
                      <Form.Text className="text-danger">
                        {errors?.max_children?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Price <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("price", {
                        required: "Price is required",
                      })}
                      className="form-control"
                      placeholder="Enter price"
                    />
                    {errors?.price && (
                      <Form.Text className="text-danger">
                        {errors?.price?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Price per additional adult{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("additional_price_adult", {
                        required: "Additional price per adult is required",
                      })}
                      className="form-control"
                      placeholder="Enter additional price per adult"
                    />
                    {errors?.additional_price_adult && (
                      <Form.Text className="text-danger">
                        {errors?.additional_price_adult?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Price per additional children{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("additional_price_children", {
                        required: "Additional price per children is required",
                      })}
                      className="form-control"
                      placeholder="Enter additional price per children"
                    />
                    {errors?.additional_price_children && (
                      <Form.Text className="text-danger">
                        {errors?.additional_price_children?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Room id prefix <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("room_id_prefix", {
                        required: "Room id prefix is required",
                      })}
                      className="form-control"
                      placeholder="Enter room id prefix"
                    />
                    {errors?.room_id_prefix && (
                      <Form.Text className="text-danger">
                        {errors?.room_id_prefix?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Room Color <span className="text-danger">*</span>
                    </label>
                    <input
                      type="color"
                      {...register("room_color", {
                        required: "Room color is required",
                      })}
                      className="form-control"
                      placeholder="Enter room color"
                    />
                    {errors?.room_color && (
                      <Form.Text className="text-danger">
                        {errors?.room_color?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Description <span className="text-danger">*</span>
                    </label>
                    <textarea
                      {...register("description", {
                        required: "Description is required",
                      })}
                      className="form-control"
                      rows="4"
                      id="description"
                      placeholder="Enter description"
                    ></textarea>
                    {errors?.description && (
                      <Form.Text className="text-danger">
                        {errors?.description?.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-12">
                    <p>
                      Amenities <span className="text-danger">*</span>
                    </p>
                    {/* <select
                      defaultValue={''}
                      id="inputState"
                      className="form-control"
                      {...register('amenities', {
                        required: 'amenities is required',
                      })}
                    >
                      <option value="" disabled>
                        Select...
                      </option>
                      {amenities?.map((item) => {
                        return (
                          <option
                            key={`amenities-list-${item?._id}`}
                            value={item?._id}
                          >
                            {item?.name}
                          </option>
                        );
                      })}
                    </select> */}
                    {amenities?.map((item) => {
                      return (
                        <Button
                          className="me-2 mb-2"
                          variant={
                            selectedAmenities?.includes(item?._id)
                              ? "success"
                              : "outline-success"
                          }
                          key={`amenities-list-${item?._id}`}
                          value={item?._id}
                          onClick={onAminitiesSelect}
                        >
                          {item?.name}
                        </Button>
                      );
                    })}
                  </div>
                  {errorMessage !== "" && (
                    <Form.Text className="text-danger mb-2 fs-6 text-center">
                      {errorMessage}
                    </Form.Text>
                  )}
                </div>
                <div className="row">
                  {!!errors.afterSubmit && (
                    <span className="text-center text-danger">
                      {errors?.afterSubmit?.messages[0]}
                    </span>
                  )}
                </div>
                <div className="column d-flex justify-content-between">
                  <Button
                    type="button"
                    className="btn px-4"
                    variant="light"
                    onClick={() =>
                      history.push(`/properties/${propertyId}/rooms`)
                    }
                    //   disabled={isSubmitting}
                  >
                    Back
                  </Button>
                  {(permissions?.ROOM?.create || permissions?.ROOM?.update) && (
                    <Button
                      type="submit"
                      className="btn btn-primary px-4"
                      disabled={isSubmitting}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoomCreate;
