import moment from 'moment';
import React, { useState, useEffect } from 'react';
import call from 'utils/request';
import { getUserInfo } from 'utils/helper';
import { Link } from 'react-router-dom';

const CouponsList = () => {
  const userInfo = getUserInfo();
  const permissions = userInfo?.permissions;
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCouponsList();
  }, [userInfo?.user?.tenantId]);

  const getCouponsList = async () => {
    setLoading(true);
    await call({
      method: 'get',
      url: `/api/coupon/paginate?tenantId=${userInfo?.user?.tenantId}`,
    }).then((res) => {
      setCoupons(res?.data?.docs);
      setLoading(false);
    });
  };

  if (loading) {
    return <div className="loader vh-100"></div>;
  }

  return (
    <>
      {!loading && (
        <>
          <div className="row">
            {permissions?.COUPON?.create && (
              <div className="d-flex justify-content-end mb-2">
                <Link
                  to="/coupons/create"
                  className="btn btn-primary mb-1 me-1 px-5 py-2"
                >
                  Add
                </Link>
              </div>
            )}
            {!coupons?.length ? (
              <div className="d-flex align-items-center justify-content-center vh-100">
                <h2>No data</h2>
              </div>
            ) : (
              <>
                {coupons?.map((data, ind) => (
                  <div className="card" key={`coupon-${ind}`}>
                    <div className="card-body pb-0 px-3">
                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3">
                          <div className="review-bx d-flex align-items-center">
                            <div className="review-content">
                              <div className="d-flex flex-row align-items-start">
                                <h3 className="font-w500 me-3">
                                  {data.couponCode}
                                </h3>
                                {data?.isActive ? (
                                  <span className="badge badge-success fw-400 px-3">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge badge-danger fw-400 px-3">
                                    Inactive
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                          <div className="description-bx d-inline-block d-sm-flex align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-6 description-content">
                              <p className="font-w500 fw-bolder">
                                Discount Type:
                              </p>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 description-content">
                              <p className="font-w500">
                                {data.couponDiscountType}
                              </p>
                            </div>
                          </div>
                          <div className="description-bx d-inline-block d-sm-flex align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-6 description-content">
                              <p className="font-w500 fw-bolder">
                                Discount Value:
                              </p>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 description-content">
                              <p className="font-w500">
                                {data.couponDiscountValue}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                          <div className="description-bx d-inline-block d-sm-flex align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-6 description-content">
                              <p className="font-w500 fw-bolder">Valid From:</p>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 description-content">
                              <p className="font-w500">
                                {moment(data.fromDate).format('DD-MM-YYYY')}
                              </p>
                            </div>
                          </div>
                          <div className="description-bx d-inline-block d-sm-flex align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-6 description-content">
                              <p className="font-w500 fw-bolder">Valid To:</p>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 description-content">
                              <p className="font-w500">
                                {moment(data.toDate).format('DD-MM-YYYY')}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-1 col-lg-1 col-md-1 d-flex flex-column justify-content-between">
                          <div className="d-flex justify-content-end align-items-start mb-3">
                            {permissions?.COUPON?.update && (
                              <Link
                                to={`/coupons/${data?._id}/edit`}
                                className="btn btn-secondary btn-sm"
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CouponsList;
