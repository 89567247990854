import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import call from 'utils/request';
import { Button, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useHistory, useLocation } from 'react-router-dom';
import { getUserInfo, dateStringToMillis } from 'utils/helper';
import moment from 'moment';
import { DATE_FORMAT } from 'utils/constants';

const CoupounAdd = () => {
  const history = useHistory();
  const location = useLocation();
  const userInfo = getUserInfo();
  const permissions = userInfo?.permissions;
  const [errMessage, setErrMessage] = useState('');
  const [couponData, setCouponData] = useState({});
  const [property, setProperty] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState({});
  const [couponType, setCouponType] = useState('');
  const couponDiscountTypes = useRef(['Amount', 'Percent']);
  const pathName = location?.pathname?.split('/');
  const couponId = location?.state
    ? location?.state?.id
    : pathName[2] !== 'create'
    ? pathName[2]
    : undefined;

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(() => {
    if (couponId) {
      const getCouponData = async () => {
        await call({
          method: 'get',
          url: `/api/coupon/${couponId}`,
        }).then((res) => {
          setCouponData(res?.data);
        });
      };
      getCouponData();
    }
  }, [couponId]);

  useEffect(() => {
    getPropertiesList();
  }, []);

  const getPropertiesList = async () => {
    await call({
      method: 'get',
      url: `/api/properties/paginate?tenantId=${userInfo?.user?.tenantId}`,
    }).then((res) => {
      setProperty(res?.data?.docs);
    });
  };

  useEffect(() => {
    if (Object.keys(couponData).length) {
      setValue('property', couponData?.propertyId);
      setValue('couponCode', couponData?.couponCode);
      setValue('couponDiscountType', couponData?.couponDiscountType);
      setValue('couponDiscountValue', couponData?.couponDiscountValue);
      setValue('fromDate', moment(couponData?.fromDate).format(DATE_FORMAT));
      setValue('toDate', moment(couponData?.toDate).format(DATE_FORMAT));
      setValue('isActive', couponData?.isActive);
      setSelectedProperty(couponData?.property);
    }
  }, [couponData, setValue]);

  const onSubmit = async (data) => {
    let createData = {
      propertyId: data?.property,
      couponCode: data?.couponCode,
      couponDiscountType: data?.couponDiscountType,
      couponDiscountValue: data?.couponDiscountValue,
      fromDate: dateStringToMillis(data?.fromDate),
      toDate: dateStringToMillis(data?.toDate),
      isActive: data?.isActive,
    };

    if (!Object.keys(couponData).length) {
      await call({
        method: 'post',
        url: '/api/coupon',
        data: createData,
      })
        .then((res) => {
          if (res?.status === 201) {
            Swal.fire({
              title: 'Created!',
              text: res?.data?.message || 'Coupon has been created',
              icon: 'success',
              showConfirmButton: false,
              timer: 500,
            });
            history.push('/coupons');
          }
        })
        .catch((err) => {
          const errMsg = err?.response?.data?.message;
          setErrMessage(errMsg);
        });
    } else {
      await call({
        method: 'patch',
        url: `/api/coupon/${couponId}`,
        data: createData,
      })
        .then((res) => {
          if (res?.status === 204) {
            Swal.fire({
              title: 'Updated!',
              text: res?.data?.message || 'Coupon has been updated',
              icon: 'success',
              showConfirmButton: false,
              timer: 500,
            });
            history.push('/coupons');
          }
        })
        .catch((err) => {
          const items = err?.response?.data?.errors;
          for (let item of items) {
            setError('afterSubmit', item, { message: item?.messages[0] });
          }
        });
    }
  };

  return (
    <>
      <div>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label>
                        Property <span className="text-danger">*</span>
                      </label>
                      <select
                        defaultValue={selectedProperty}
                        onChange={(e) => setSelectedProperty(e.target.value)}
                        id="inputState"
                        className="form-control"
                        {...register('property', {
                          required: 'Property is required',
                        })}
                      >
                        <option value="" disabled>
                          Select Property
                        </option>
                        {property?.map((item, i) => (
                          <option key={`property-${i}`} value={item?._id}>
                            {item?.name}
                          </option>
                        ))}
                      </select>
                      {errors?.propertyId && (
                        <Form.Text className="text-danger">
                          {errors?.propertyId?.message}
                        </Form.Text>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>
                        Coupon Code <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        {...register('couponCode', {
                          required: 'Coupon Code is required',
                        })}
                        className="form-control"
                        placeholder="Enter Coupon Code"
                        onChange={(e) => {
                          setValue('couponCode', e.target.value.toUpperCase());
                        }}
                      />
                      {errors?.couponCode && (
                        <Form.Text className="text-danger">
                          {errors?.couponCode?.message}
                        </Form.Text>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>
                        Coupon Type <span className="text-danger">*</span>
                      </label>
                      <select
                        id="inputState"
                        className="form-control"
                        {...register('couponDiscountType', {
                          required: 'Coupon Type is required',
                        })}
                        onChange={(e) => {
                          setCouponType(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select Coupon Type
                        </option>
                        {couponDiscountTypes.current?.map((item, i) => (
                          <option key={`coupon-type-${i}`} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                      {errors?.couponDiscountType && (
                        <Form.Text className="text-danger">
                          {errors?.couponDiscountType?.message}
                        </Form.Text>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>
                        Coupon Value <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        {...register('couponDiscountValue', {
                          required: 'Coupon Value is required',
                          pattern: {
                            value: /^[0-9]*$/,
                            message: 'Coupon Value must be a number',
                          },
                          validate: (value) => {
                            if (couponType === 'Percent') {
                              const minValue = 1;
                              const maxValue = 100;

                              if (parseFloat(value) < minValue) {
                                return `Coupon Value must be at least ${minValue}`;
                              }

                              if (parseFloat(value) > maxValue) {
                                return `Coupon Value must be at most ${maxValue}`;
                              }
                            }
                            return true;
                          },
                        })}
                        className="form-control"
                        placeholder="Enter Coupon Value"
                      />
                      {errors?.couponDiscountValue && (
                        <Form.Text className="text-danger">
                          {errors?.couponDiscountValue?.message}
                        </Form.Text>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>
                        From Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        {...register('fromDate', {
                          required: 'From Date is required',
                        })}
                        className="form-control"
                        placeholder="Select From Date"
                      />
                      {errors?.fromDate && (
                        <Form.Text className="text-danger">
                          {errors?.fromDate?.message}
                        </Form.Text>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>
                        To Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        {...register('toDate', {
                          required: 'To Date is required',
                        })}
                        className="form-control"
                      />
                      {errors?.toDate && (
                        <Form.Text className="text-danger">
                          {errors?.toDate?.message}
                        </Form.Text>
                      )}
                    </div>
                    <div className="d-flex flex-row mb-3">
                      <label className="m-0 p-0">Active:</label>
                      <input
                        type="checkbox"
                        {...register('isActive')}
                        className="form-check-input m-0 p-0 ms-2"
                        defaultChecked={true}
                      />
                    </div>
                    <div className="column d-flex justify-content-between">
                      <Button
                        type="button"
                        className="btn px-4"
                        variant="light"
                        onClick={() => history.goBack()}
                      >
                        Cancel
                      </Button>
                      {(permissions?.COUPON?.create ||
                        permissions?.COUPON?.update) && (
                        <Button
                          type="submit"
                          className="btn btn-primary px-4"
                          disabled={isSubmitting}
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoupounAdd;
