import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';
import call from 'utils/request';
import { getUserInfo, loadScript } from 'utils/helper';
import { Button, Table } from 'react-bootstrap';
import { RAZOR_PAY_KEY } from 'utils/constants';
import { useHistory } from 'react-router-dom';

const Payments = () => {
  const history = useHistory();
  const userInfo = getUserInfo();
  const permissions = userInfo?.permissions;
  const [loading, setLoading] = useState(false);
  const [tenantDetails, setTenantDetails] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    const getTenantDetail = async () => {
      await call({
        method: 'get',
        url: `/api/tenant/${userInfo?.user?.tenantId}`,
      }).then((res) => {
        setTenantDetails(res?.data);
      });
    };
    getTenantDetail();
  }, [userInfo?.user?.tenantId]);

  useEffect(() => {
    const getSubscriptionList = async () => {
      await call(
        {
          method: 'get',
          url: `/api/subscriptions/paginate`,
        },
        {
          Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
        }
      ).then((res) => {
        setSubscriptions(res?.data?.docs);
      });
    };
    getSubscriptionList();
  }, []);

  if (loading) {
    return <div className="loader vh-100"></div>;
  }

  const onPayClick = async () => {
    await call(
      {
        method: 'post',
        url: '/api/subscriptions/create-subscription',
        data: { tenantId: userInfo?.user?.tenantId },
      },
      {
        Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
      }
    ).then((res) => {
      razorPayPortal(res?.data);
    });
  };

  const razorPayPortal = async (subscriptionData) => {
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }
    const options = {
      key: RAZOR_PAY_KEY, // Enter the Key ID generated from the Dashboard
      amount: subscriptionData?.amount,
      currency: 'INR',
      name: userInfo?.user?.name,
      description: 'Test Transaction',
      //   image: { logo },
      order_id: subscriptionData?.razorpayOrderId,
      handler: async function (response) {
        console.log(response, 'razor pay response');
        const data = {
          subscriptionId: subscriptionData?._id,
          razorpayPaymentId: response.razorpay_payment_id,
          //   razorpaySignature: response.razorpay_signature,
        };

        await call(
          {
            method: 'post',
            url: `/api/subscriptions/confirm-subscription`,
            data: data,
          },
          {
            Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
          }
        ).then((res) => {
          window.location.reload(true);
          history.push('/payments/status', { status: 'Success' });
        });
      },
      notes: {
        address: 'Hill view resort',
      },
      theme: {
        color: '#61dafb',
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <>
      {!loading && (
        <>
          <div className="row d-flex mt-3">
            <div
              className="col card mx-3"
              style={{
                height: '9rem',
              }}
            >
              <div className="card-body px-3 d-flex flex-column">
                <div className="row d-flex flex-column">
                  <div
                    className="col fw-bolder text-primary mb-2"
                    style={{
                      fontSize: '1.5rem',
                    }}
                  >
                    Current Subscription Plan
                  </div>
                  <div className="d-flex justify-content-start align-items-center">
                    <div
                      className="h3 fw-bolder text-success mb-0 me-2"
                      style={{
                        fontSize: '1.4rem',
                      }}
                    >
                      ₹6000
                    </div>
                    <div
                      className="fw-bolder"
                      style={{
                        fontSize: '1.0rem',
                      }}
                    >
                      - Standard Plan (365 Days)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col card mx-3"
              style={{
                height: '9rem',
              }}
            >
              <div className="card-body px-3 d-flex flex-column">
                <div className="row d-flex flex-column">
                  <div
                    className="col fw-bolder text-primary mb-2"
                    style={{
                      fontSize: '1.5rem',
                    }}
                  >
                    Next Payment Date
                  </div>
                  <div
                    className="col fw-bolder text-success"
                    style={{
                      fontSize: '1.4rem',
                    }}
                  >
                    {moment()
                      .add(tenantDetails?.noOfDaysLeft, 'days')
                      .format('DD MMM YYYY')}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col card mx-3"
              style={{
                height: '9rem',
              }}
            >
              <div className="card-body px-3 d-flex flex-column">
                <div className="row d-flex flex-column">
                  <div
                    className="col fw-bolder text-primary mb-2"
                    style={{
                      fontSize: '1.5rem',
                    }}
                  >
                    Remaining Subscription Days
                  </div>
                  <div
                    className="col fw-bolder text-success"
                    style={{
                      fontSize: '1.4rem',
                    }}
                  >
                    {tenantDetails?.noOfDaysLeft}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {permissions?.PAYMENT?.create && (
            <div className="row">
              <div className="col card mx-3">
                <div className="card-body px-3">
                  <div className="d-flex flex-row justify-content-around align-items-center">
                    <h5 className="mb-0">
                      {tenantDetails?.noOfDaysLeft === 0
                        ? 'Thank you for choosing us. To continue enjoying our service, kindly make your payment.'
                        : 'Thank you for choosing our services. You have the option to make an advance payment to proceed.'}
                    </h5>
                    <Button
                      className="bg-warning w-25 my-2 py-2"
                      onClick={onPayClick}
                    >
                      Pay
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col card mx-3">
              <div className="card-body px-3">
                <div className="d-flex flex-column">
                  <div
                    className="fw-bolder text-black mb-2"
                    style={{
                      fontSize: '1.5rem',
                    }}
                  >
                    Payment History
                  </div>
                </div>
                <div className="d-flex flex-column my-3">
                  <div className="table-container">
                    <Table hover>
                      <thead>
                        <tr>
                          <th>S. No.</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subscriptions.length > 0 ? (
                          subscriptions.map((payment, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>
                                  {payment?.paymentDate
                                    ? moment(payment?.paymentDate).format(
                                        'DD-MM-YYYY'
                                      )
                                    : '--'}
                                </td>
                                <td>
                                  {payment?.paymentDate
                                    ? moment(payment?.paymentDate)
                                        .tz('Asia/Kolkata')
                                        .format('HH:mm')
                                    : '--'}
                                </td>
                                <td>
                                  {payment?.status === 'CONFIRMED' ? (
                                    <span className="badge bg-success">
                                      {payment?.status}
                                    </span>
                                  ) : (
                                    <span className="badge bg-warning">
                                      {payment?.status}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="text-center">
                            <td colspan="4">No Payments</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Payments;
