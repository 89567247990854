import { useState, useEffect } from "react";
import call from "utils/request";
import { Link, useHistory } from "react-router-dom";

function RolesList() {
  const userInfo = JSON.parse(sessionStorage.getItem("userDetails"));
  const permissions = userInfo?.permissions;
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getRolesList();
  }, []);

  const getRolesList = async () => {
    setLoading(true);
    await call(
      {
        method: "get",
        url: `/api/roles/paginate`,
      },
      {
        Authorization: `${userInfo?.token?.tokenType} ${userInfo?.token?.accessToken}`,
      }
    ).then((res) => {
      setRoles(res?.data?.docs);
      setLoading(false);
    });
  };

  const handleEditClick = (data) => {
    const state = data;
    history.push(`/roles/${data?._id}/edit`, state);
  };

  const handleShowClick = (data) => {
    const state = { ...data, view: true };
    history.push(`/roles/${data?._id}/edit`, state);
  };

  if (loading) {
    return <div className="loader vh-100"></div>;
  }

  return (
    !loading && (
      <>
        <div className="row">
          {permissions?.ROLE?.create && (
            <div className="d-flex justify-content-end mb-2">
              <Link
                to="/roles/create"
                className="btn btn-primary mb-1 me-1 px-5 py-2"
              >
                Create Role
              </Link>
            </div>
          )}
          {!roles?.length ? (
            <div className="d-flex align-items-center justify-content-center vh-100">
              <h2>No Roles</h2>
            </div>
          ) : (
            <>
              {roles?.map((data, ind) => (
                <div className="card" key={`roles-${ind}`}>
                  <div className="card-body pb-0 px-3">
                    <div className="row d-flex justify-content-center">
                      <div className="col-xl-11 col-lg-11 col-md-11">
                        <div className="review-bx d-flex align-items-center">
                          <div className="review-content">
                            <div className="d-flex flex-row align-items-start">
                              <h4 className="font-w500 me-3">{data?.role}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      {data?.role === "ADMIN" && permissions?.ROLE?.read && (
                        <div className="col-xl-1 col-lg-1 col-md-1 d-flex flex-column justify-content-center align-items-end">
                          <div className="d-flex justify-content-center align-items-start mb-3">
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => handleShowClick(data)}
                            >
                              <i className="fas fa-eye"></i>
                            </button>
                          </div>
                        </div>
                      )}

                      {data?.role !== "ADMIN" && permissions?.ROLE?.update && (
                        <div className="col-xl-1 col-lg-1 col-md-1 d-flex flex-column justify-content-center align-items-end">
                          <div className="d-flex justify-content-center align-items-start mb-3">
                            <button
                              className="btn btn-secondary btn-sm"
                              onClick={() => handleEditClick(data)}
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </>
    )
  );
}

export default RolesList;
