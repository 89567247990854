import React, { useCallback, useEffect, useState } from "react";
import { isEmpty, get, map } from "lodash";
import call from "utils/request";
import { Badge, Button, Card, Row, Col, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { capitalizeFirstChar } from "utils/helper";
import { useHistory, useLocation } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import moment from "moment-timezone";

function EventDetails() {
  const history = useHistory();
  const location = useLocation();
  const pathName = location?.pathname?.split("/");
  const eventId = location?.state
    ? location?.state?.id
    : pathName[2] !== "create"
    ? pathName[2]
    : undefined;
  const [eventData, setEventData] = useState({});
  const [loading, setLoading] = useState(false);
  const userInfo = JSON.parse(sessionStorage.getItem("userDetails"));
  const permissions = userInfo?.permissions;

  const [mediaToBeUploaded, setMediaToBeUploaded] = useState([]);
  const [resultToBeUploaded, setResultToBeUploaded] = useState([]);
  const [isMediaUploading, setIsMediaUploading] = useState(false);
  const [isResultUploading, setIsResultUploading] = useState(false);

  const getEventData = async () => {
    setLoading(true);
    await call({
      method: "get",
      url: `/api/events/${eventId}`,
    })
      .then((res) => {
        setEventData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error in Getting event data", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getEventData();
  }, []);

  const onDropMedia = useCallback(
    (acceptedFiles) => {
      setMediaToBeUploaded([...mediaToBeUploaded, ...acceptedFiles]);
    },
    [mediaToBeUploaded]
  );

  const onDropResult = useCallback((acceptedFiles) => {
    setResultToBeUploaded(acceptedFiles);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const {
    getRootProps: mediaRootProps,
    getInputProps: mediaInputProps,
    isDragActive: mediaIsDragActive,
    acceptedFiles: mediaAcceptedFiles,
  } = useDropzone({ onDrop: onDropMedia });

  // eslint-disable-next-line no-unused-vars
  const {
    getRootProps: resultRootProps,
    getInputProps: resultInputProps,
    isDragActive: resultIsDragActive,
    acceptedFiles: resultAcceptedFiles,
  } = useDropzone({ onDrop: onDropResult, multiple: false });

  const uploadMedia = async () => {
    setIsMediaUploading(true);
    const formData = new FormData();
    let i;
    for (i = 0; i < mediaAcceptedFiles.length || 0; i++) {
      formData.append(`media[${i}]`, mediaAcceptedFiles[i]);
    }
    await call({
      method: "post",
      url: `/api/events/${eventId}/media`,
      data: formData,
    }).then((res) => {
      if (res.status === 200) {
        Swal.fire({
          title: "Uploaded!",
          text: res?.data?.message || "Media has been uploaded",
          icon: "success",
          showConfirmButton: false,
          timer: 500,
        });
        getEventData();
        setIsMediaUploading(false);
        setMediaToBeUploaded([]);
      }
    });
  };

  const uploadResult = async () => {
    setIsResultUploading(true);
    const formData = new FormData();
    formData.append("media", resultAcceptedFiles[0]);

    await call({
      method: "post",
      url: `/api/events/${eventId}/result`,
      data: formData,
    }).then((res) => {
      if (res.status === 200) {
        Swal.fire({
          title: "Uploaded!",
          text: res?.data?.message || "Result has been uploaded",
          icon: "success",
          showConfirmButton: false,
          timer: 1000,
        });
        getEventData();
        setIsResultUploading(false);
        setResultToBeUploaded([]);
      }
    });
  };

  const onDeleteMedia = async (mediaId, mediaIndex) => {
    await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete the Media?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#F8857D",
      dangerMode: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await call({
          method: "delete",
          url: `/api/events/${eventId}/media/${mediaId}`,
          params: { mediaIndex },
        }).then((res) => {
          if (res?.status === 200) {
            Swal.fire("Deleted", "Media has been deleted!", "success");
            setTimeout(() => getEventData(), 500);
          }
        });
      }
    });
  };

  const onDeleteResult = async () => {
    await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete the result?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#F8857D",
      dangerMode: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await call({
          method: "delete",
          url: `/api/events/${eventId}/result`,
        }).then((res) => {
          if (res?.status === 200) {
            Swal.fire("Deleted", "Result has been deleted!", "success");
            setTimeout(() => getEventData(), 500);
          }
        });
      }
    });
  };

  if (loading) {
    return <div className="loader vh-100"></div>;
  }

  return (
    !loading && (
      <>
        <div className="row">
          {permissions?.EVENT?.create && (
            <div className="d-flex justify-content-start mb-2">
              <Button
                type="button"
                className="btn px-4"
                variant="light"
                onClick={() => history.goBack()}
                //   disabled={isSubmitting}
              >
                Back
              </Button>
            </div>
          )}
          {isEmpty(eventData) ? (
            <div className="d-flex align-items-center justify-content-center vh-100">
              <h2>Event Not Found</h2>
            </div>
          ) : (
            <>
              <Row>
                <Col md="8">
                  <Card>
                    <Card.Header className="h3">
                      {get(eventData, "eventName")}
                    </Card.Header>
                    <Card.Body>
                      <p>
                        Description:{" "}
                        <span className="fw-bolder">
                          {get(eventData, "description", "-")}
                        </span>
                      </p>
                      <p>
                        Location:{" "}
                        <span className="fw-bolder">
                          {get(eventData, "location", "-")}
                        </span>
                      </p>
                      <p>
                        Zone Type:{" "}
                        <span className="fw-bolder">
                          <Badge>
                            {capitalizeFirstChar(
                              get(eventData, "zoneType", "-")
                            )}
                          </Badge>
                        </span>
                      </p>
                      <p>
                        Zone Type Value:{" "}
                        <span className="fw-bolder">
                          {capitalizeFirstChar(
                            get(eventData, "zoneTypeValue", "-")
                          )}
                        </span>
                      </p>
                      <p>
                        Event Date & Time:{" "}
                        <span className="fw-bolder">
                          {moment(get(eventData, "eventDateTime")).format(
                            "DD-MM-YYYY hh:mm A"
                          )}
                        </span>
                      </p>
                      {!isEmpty(get(eventData, "result", {})) && (
                        <p className="d-flex align-items-center">
                          Result:{" "}
                          <span
                            title="View Result File"
                            onClick={() =>
                              window.open(
                                get(eventData, "result.path"),
                                "_blank"
                              )
                            }
                            className="mx-1 c-pointer badge badge-success"
                            variant="primary"
                          >
                            {`${get(eventData, "eventName", "")}-${get(
                              eventData,
                              "zoneType",
                              ""
                            )}-${get(eventData, "result.uniqueKey", "")}.${get(
                              eventData,
                              "result.name",
                              ""
                            )
                              .split(".")
                              .pop()}`}
                          </span>
                          <span
                            className="p-0 m-0 btn btn-outline"
                            size="sm"
                            outline
                            onClick={() => onDeleteResult()}
                          >
                            <span className="btn-inner--icon text-danger">
                              <i className="fas fa-trash" />
                            </span>
                          </span>
                        </p>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <Card.Header className="h5">Upload Media</Card.Header>
                    <Card.Body>
                      <div
                        {...mediaRootProps({ className: "dropzone" })}
                        className="dz-drag-hover dz-preview-img  dz-message dropzone border p-5 c-pointer"
                      >
                        <input {...mediaInputProps()} />
                        {mediaIsDragActive ? (
                          <p>Drop the file here ...</p>
                        ) : (
                          <p>
                            Drag and drop your file here, or click to select
                            file.
                          </p>
                        )}
                      </div>
                      {!isEmpty(mediaToBeUploaded) && (
                        <div
                          for="exampleSelect"
                          className="text-sm ml-4"
                          sm={12}
                        >
                          Uploaded Files:
                          <ul>
                            {map(mediaToBeUploaded || [], (item) => (
                              <li>{item.path}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                      <Button
                        className="btn btn-sm mt-3"
                        disabled={
                          isEmpty(mediaToBeUploaded) || isMediaUploading
                        }
                        onClick={uploadMedia}
                      >
                        {isMediaUploading ? (
                          <Spinner
                            className="spinner-border spinner-border-sm"
                            role="status"
                          />
                        ) : (
                          "Upload"
                        )}
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <Card>
                    <Card.Header className="h5">Media</Card.Header>
                    <Card.Body>
                      {get(eventData, "media", []).map((media, i) => (
                        <React.Fragment className="mt-2">
                          <span
                            onClick={() => window.open(media.path, "_blank")}
                            className="mx-1 c-pointer badge badge-success"
                            variant="primary"
                          >
                            {`${get(eventData, "eventName", "")}-${get(
                              eventData,
                              "zoneType",
                              ""
                            )}-${get(media, "uniqueKey", "")}.${get(
                              media,
                              "name",
                              ""
                            )
                              .split(".")
                              .pop()}`}
                          </span>
                          <span
                            className="p-0 m-0 btn btn-outline"
                            size="sm"
                            outline
                            onClick={() =>
                              onDeleteMedia(get(media, "uniqueKey", ""), i)
                            }
                          >
                            <span className="btn-inner--icon text-danger">
                              <i className="fas fa-trash" />
                            </span>
                          </span>
                        </React.Fragment>
                      ))}
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card className="mb-3">
                    <Card.Header className="h5">Upload Result</Card.Header>
                    <Card.Body>
                      <div
                        {...resultRootProps({ className: "dropzone" })}
                        className="dz-drag-hover dz-preview-img  dz-message dropzone border p-5 c-pointer"
                      >
                        <input {...resultInputProps()} />
                        {resultIsDragActive ? (
                          <p>Drop the file here ...</p>
                        ) : (
                          <p>
                            Drag and drop your file here, or click to select
                            file.
                          </p>
                        )}
                      </div>
                      {!isEmpty(resultToBeUploaded) && (
                        <div
                          for="exampleSelect"
                          className="text-sm ml-4"
                          sm={12}
                        >
                          Uploaded Files:
                          <ul>
                            {map(resultToBeUploaded || [], (item) => (
                              <li>{item.path}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                      <Button
                        className="btn btn-sm mt-3"
                        disabled={
                          isEmpty(resultToBeUploaded) || isResultUploading
                        }
                        onClick={uploadResult}
                      >
                        {isResultUploading ? (
                          <Spinner
                            className="spinner-border spinner-border-sm"
                            role="status"
                          />
                        ) : (
                          "Upload"
                        )}
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </div>
      </>
    )
  );
}

export default EventDetails;
