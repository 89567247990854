export const CLIENT_BASE_URL = process.env.REACT_APP_CLIENT_BASE_URL;
export const REACT_APP_GITHUB_SHA = process.env.REACT_APP_GITHUB_SHA;
export const MAX_MEDIA_UPLOAD_SIZE = 2e7;
export const PAYMENT_STATUS_CONFIRMED = "CONFIRMED";
export const PAYMENT_STATUS_UNSUCCESSFUL = "PAYMENT_UNSUCCESSFUL";
export const DB_TIMESTAMP = "YYYY-MM-DD HH:mm:ss";
export const BOOKING_STATUS = [
  {
    label: "Confirmed",
    value: "CONFIRMED",
  },
  {
    label: "Payment unsuccessful",
    value: "PAYMENT_UNSUCCESSFUL",
  },
  {
    label: "Cancelled",
    value: "CANCELLED",
  },
  {
    label: "Blocked",
    value: "BLOCKED",
  },
];
export const DATE_FORMAT = "YYYY-MM-DD";
export const RAZOR_PAY_KEY = process.env.REACT_APP_RAZOR_PAY_KEY;

export const MANDAL = "mandal";
export const JANPATH = "janpath";
export const TEHSIL = "tehsil";
export const SUPER_ADMIN = "SUPER_ADMIN";
export const NORMAL_USER = "NORMAL_USER";

export const REGISTRATION_FIELD_MAP = {
  mandal: 'मंडल का नाम/ Name of Mandal ',
  janpath: 'जनपद का नाम/ Name of District',
  tehsil: "तहसील का नाम/ Name of Tehsil ",
  participantName: "प्रतिभागी का नाम/Participant Name ",
  fathersName: "पिता का नाम/Fathers Name ",
  mothersName: "माता का नाम/Mothers Name",
  mobileNumber: "मोबाइल न./Mobile Number ",
  adhaarNumber: "आधार संख्या/Adhaar Card No.",
  dob: "जन्मतिथि/Date of Birth",
  email: "ईमेल आईडी/Email ID",
  address: "प्रतिभागी का पूर्ण पता/Complete Address of Participant ",
  address1: "प्रतिभागी का पूर्ण पता/Complete Address of Participant  - Line 1",
  address2: "प्रतिभागी का पूर्ण पता/Complete Address of Participant  - Line 2",
  state:
    "प्रतिभागी का पूर्ण पता/Complete Address of Participant  - State/Province",
  city: "प्रतिभागी का पूर्ण पता/Complete Address of Participant  - City",
  zipCode:
    "प्रतिभागी का पूर्ण पता/Complete Address of Participant  - Zip/Postal",
  country: "प्रतिभागी का पूर्ण पता/Complete Address of Participant  - Country",
  category:
    "कार्यक्षेत्र एवं विधा आकाशवाणी/ दूरदर्शन का ग्रेड/श्रेणी AIR/Doordarshan Grade/Category:",
  artType: "प्रतियोगिता की विधाएं /Type of Art form ",
  artForm: "प्रतियोगिता की विधाएं /Name of Art form ",
  levelOfPresentation:
    "प्रस्तुति / पुरस्कार(अ.रा./ रा./ प्रा./ स्था.) का विवरण/ स्तर:/Details / Levels of Presentation / Excite (International/National/State/Local):",
  acheivements:
    "अन्य उपलब्धियां/विवरण (जो कलाकार की प्रतिभागिता हेतु आवश्यक हो अधिकतम 200 शब्दों में) Other Achievements / details (in maximum 200 words that are required for the artist's participation):",
  photoUpload: "Photo Upload",
  panCard: "पैन कार्ड/PAN Card",
  bankAccountNumber: "खाता संख्या/Bank Account Number",
  bankBranch: "ब्रांच का नाम /Name of Branch ",
  bankIfscCode: "आई.एफ़.एस.सी /IFSC Code",
};
