import React, { useState } from "react";

import { Link } from "react-router-dom";
/// Scroll
import { connect, useDispatch } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";

/// Image
// import profile from "../../../images/user.jpg";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";
import LogoutPage from "./Logout";
import { logout } from "../../../store/slices/AuthSlice";

const Header = ({ onNote }) => {
  const dispatch = useDispatch();

  function onLogout() {
    logout(dispatch);
  }

  const [searchBut, setSearchBut] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const path = window.location.pathname.split("/");
  const name = path[path.length - 1].split("-");
  const filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  const finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : path.includes("rooms") && filterName.includes("edit")
    ? [...filterName, "room"]
    : path.includes("properties") && filterName.includes("edit")
    ? [...filterName, "property"]
    : path.includes("tenant") && filterName.includes("edit")
    ? [...filterName, "tenant"]
    : path.includes("tenant") && filterName.includes("image")
    ? [...filterName, "upload"]
    : path.includes("properties") && filterName.includes("image")
    ? [...filterName, "upload"]
    : path.includes("rooms") && filterName.includes("image")
    ? [...filterName, "upload"]
    : filterName;

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="header-title"
                style={{ textTransform: "capitalize" }}
              >
                <h1 className="font-w600 mb-0">
                  {finalName.join(" ").length === 0
                    ? "Dashboard"
                    : finalName.join(" ") === "dashboard dark"
                    ? "Dashboard"
                    : finalName.join(" ")}
                </h1>
              </div>
            </div>
            <div className="justify-content-between align-items-end">
              <div className="c-pointer" onClick={onLogout}>
                <svg
                  id="icon-logout"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-danger me-1"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                  <polyline points="16 17 21 12 16 7" />
                  <line x1={21} y1={12} x2={9} y2={12} />
                </svg>
                Logout
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
