import { lazy, Suspense, useEffect, useState } from "react";

/// Components
import Index from "./jsx";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { updateStoreFromLocalStorage } from "store/slices/AuthSlice";
// import { id } from 'date-fns/locale';
import { useCallback } from "react";
// import Home from 'jsx/components/Dashboard/Home';

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const VerifyEmail = lazy(() => import("./jsx/pages/VerifyEmail"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => import("./jsx/pages/Login"));
const ResetPassword = lazy(() => import("./jsx/pages/ResetPassword"));
const VersionInfo = lazy(() => import("./jsx/pages/VersionInfo"));

function App(props) {
  const dispatch = useDispatch();
  const authStore = useSelector((state) => state.auth.auth);
  const [isAuthenticated, setAuthenticated] = useState(false);
  if (!window.appHistory) {
    window.appHistory = props.history;
  }

  useEffect(() => {
    if (authStore?.token?.expiresIn > new Date().getTime()) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, [authStore]);

  useEffect(() => {
    if (sessionStorage.getItem("userDetails")) {
      const authStoreJson = JSON.parse(sessionStorage.getItem("userDetails"));
      setAuthenticated(authStoreJson?.token?.expiresIn > new Date().getTime());
      dispatch(updateStoreFromLocalStorage(authStoreJson));
    }
  }, [dispatch]);

  const Routes = () => {
    return (
      <Switch>
        <Route path="/register" component={SignUp} />
        <Route path="/verify-email" component={VerifyEmail} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/auth/reset-password" component={ResetPassword} />
        <Route path="/version/info" component={VersionInfo} />
        <Route path="*" component={Login} />
      </Switch>
    );
  };

  const ExpectedRoutes = useCallback(() => {
    return isAuthenticated ? <Index /> : <Routes />;
  }, [isAuthenticated]);

  return (
    <div className="vh-100">
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <ExpectedRoutes />
      </Suspense>
    </div>
  );
}

export default withRouter(App);
